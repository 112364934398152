import React from 'react';

export const feedbackFormInfoCopy = {
    'CAD' : {
        attendance: () => (
            <div>
                <h3>Absent</h3>
                <ul>
                    <li>Participant does not show up for CAD/PD/TJ and does not call ahead to inform staff</li>
                    <li>Participant shows up for CAD/PD after the 15-minute cutoff</li>
                    <li>Participant shows up too late for TJ to catch the van to the worksite</li>
                    <li>Participant leaves CAD/PD session or TJ worksite early without valid reason such as illness</li>
                </ul>
                <h3>Excused Absence</h3>
                <ul>
                    <li>Participant has arranged ahead of time to use paid time off</li>
                    <li>Participant has called in BEFORE start time of CAD or TJ with a legitimate reason for absence such as illness or loss of child care</li>
                </ul>
                <h3>Present</h3>
                <ul>
                    <li>Participant arrives on time (within 15-minute grace period for CAD/PD, or before van departs for TJ worksite) and remains until the end of the workday</li>
                </ul>
            </div>
        ),
        q1: () => (
            <div>
                <h3>Does Not Meet (1)</h3>
                <ul>
                    <li>Present after 15 minutes past start time</li>
                </ul>
                <h3>Meets (2)</h3>
                <ul>
                    <li>Present within 15 minutes of start time</li>
                </ul>
                <h3>Exceeds (3)</h3>
                <ul>
                    <li>Present before start time</li>
                </ul>
            </div>
        ),
        q2: () => (
            <div>
                <h3>Does Not Meet (1)</h3>
                <ul>
                    <li>Left the room repeatedly or without permission</li>
                </ul>
                <h3>Meets (2)</h3>
                <ul>
                    <li>Left the room more than once or for an extended time with permission</li>
                </ul>
                <h3>Exceeds (3)</h3>
                <ul>
                    <li>Remained in room throughout the C-A-D session, or left briefly one time with permission</li>
                </ul>
            </div>
        ),
        q3: () => (
            <div>
                <h3>Does Not Meet (1)</h3>
                <ul>
                    <li>Was inattentive, disruptive, disrespectful, or disengaged</li>
                </ul>
                <h3>Meets (2)</h3>
                <ul>
                    <li>Paid attention, spoke occasionally, was not disruptive or disrespectful</li>
                </ul>
                <h3>Exceeds (3)</h3>
                <ul>
                    <li>Spoke respectfully and on-topic, allowed others to speak, took part in activities and exercises</li>
                </ul>
            </div>
        )
    },
    'PD': {
        attendance: () => (
            <div>
                <h3>Absent</h3>
                <ul>
                    <li>Participant does not show up for CAD/PD/TJ and does not call ahead to inform staff</li>
                    <li>Participant shows up for CAD/PD after the 15-minute cutoff</li>
                    <li>Participant shows up too late for TJ to catch the van to the worksite</li>
                    <li>Participant leaves CAD/PD session or TJ worksite early without valid reason such as illness</li>
                </ul>
                <h3>Excused Absence</h3>
                <ul>
                    <li>Participant has arranged ahead of time to use paid time off</li>
                    <li>Participant has called in BEFORE start time of CAD or TJ with a legitimate reason for absence such as illness or loss of child care</li>
                </ul>
                <h3>Present</h3>
                <ul>
                    <li>Participant arrives on time (within 15-minute grace period for CAD/PD, or before van departs for TJ worksite) and remains until the end of the workday</li>
                </ul>
            </div>
        ),
        q1: () => (
            <div>
                <h3>Does Not Meet (1)</h3>
                <ul>
                    <li>Present after 15 minutes past start time</li>
                </ul>
                <h3>Meets (2)</h3>
                <ul>
                    <li>Present within 15 minutes of start time</li>
                </ul>
                <h3>Exceeds (3)</h3>
                <ul>
                    <li>Present before start time</li>
                </ul>
            </div>
        ),
        q2: () => (
            <div>
                <h3>Does Not Meet (1)</h3>
                <ul>
                    <li>Left the room repeatedly or without permission</li>
                </ul>
                <h3>Meets (2)</h3>
                <ul>
                    <li>Left the room more than once or for an extended time with permission</li>
                </ul>
                <h3>Exceeds (3)</h3>
                <ul>
                    <li>Remained in room throughout the C-A-D session, or left briefly one time with permission</li>
                </ul>
            </div>
        ),
        q3: () => (
            <div>
                <h3>Does Not Meet (1)</h3>
                <ul>
                    <li>Was inattentive, disruptive, disrespectful, or disengaged</li>
                </ul>
                <h3>Meets (2)</h3>
                <ul>
                    <li>Paid attention, spoke occasionally, was not disruptive or disrespectful</li>
                </ul>
                <h3>Exceeds (3)</h3>
                <ul>
                    <li>Spoke respectfully and on-topic, allowed others to speak, took part in activities and exercises</li>
                </ul>
            </div>
        )
    },
    'WC' : {
        attendance: () => (
            <div>
                <h3>Absent</h3>
                <ul>
                    <li>Participant does not show up for CAD/PD/TJ and does not call ahead to inform staff</li>
                    <li>Participant shows up for CAD/PD after the 15-minute cutoff</li>
                    <li>Participant shows up too late for TJ to catch the van to the worksite</li>
                    <li>Participant leaves CAD/PD session or TJ worksite early without valid reason such as illness</li>
                </ul>
                <h3>Excused Absence</h3>
                <ul>
                    <li>Participant has arranged ahead of time to use paid time off</li>
                    <li>Participant has called in BEFORE start time of CAD or TJ with a legitimate reason for absence such as illness or loss of child care</li>
                </ul>
                <h3>Present</h3>
                <ul>
                    <li>Participant arrives on time (within 15-minute grace period for CAD/PD, or before van departs for TJ worksite) and remains until the end of the workday</li>
                </ul>
            </div>
        ),
        q1: () => (
            <div>
                <h3>Does Not Meet (1)</h3>
                <ul>
                    <li>Spent more than half of the shift not engaged in work</li>
                </ul>
                <h3>Meets (2)</h3>
                <ul>
                    <li>Had some sporadic periods during the shift without working or showing minimal effort</li>
                </ul>
                <h3>Exceeds (3)</h3>
                <ul>
                    <li>Maintained consistent hard work throughout the shift, helped fellow crew members whenever necessary</li>
                </ul>
            </div>
        ),
        q2: () => (
            <div>
                <h3>Does Not Meet (1)</h3>
                <ul>
                    <li>Required multiple reminders to follow directions, had verbal altercations with crew leader, crew members, or members of the public</li>
                </ul>
                <h3>Meets (2)</h3>
                <ul>
                    <li>Followed directions and cooperated with crew members with only occasional correction or reminders</li>
                </ul>
                <h3>Exceeds (3)</h3>
                <ul>
                    <li>Consistently followed crew leader directions, cooperated with fellow crew members, interacted respectfully with members of the public</li>
                </ul>
            </div>
        ),
        q3: () => (
            <div>
                <h3>Does Not Meet (1)</h3>
                <ul>
                    <li>Exhibited unsafe behavior or compromised group safety through careless behavior or not following safety rules</li>
                </ul>
                <h3>Meets (2)</h3>
                <ul>
                    <li>Exhibited safe behavior requiring occasional reminders about safety rules</li>
                </ul>
                <h3>Exceeds (3)</h3>
                <ul>
                    <li>Exhibited unsafe behavior or compromised group safety through careless behavior or not following safety rules</li>
                </ul>
            </div>
        )
    }
}

export default feedbackFormInfoCopy