/*
*
*   commonStyles
*
*/

// Button Styles

export const primaryButtonStyle = {
    backgroundColor: '#3CB432',
    color: 'white'
}

export const buttonListRowStyle = {
    margin: '16px 0'
}

// Text Styles

export const largeBoldTextStyle = {
    fontFamily: 'DINNextLTPro-Bold', 
    fontSize:'1.8rem'
}

export const mediumBoldTextStyle = {
    fontFamily: 'DINNextLTPro-Bold', 
    fontSize:'1.5rem'
}

export const mediumTextStyle = {
    fontFamily: 'DINNextLTPro', 
    fontSize:'1.5rem'
}

// Utility Styles
export const headerTitleDecoration = {
    width: 56,
    height: 2,
    marginBottom: -8,
    backgroundColor: '#3CB432',
};

export const centeringFrameStyle = {
    marginTop: '40px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%'
}

const scoreStyle = {
    borderRadius: '10px',
    width: '30px',
    height: '20px',
    fontSize: '14px',
    backgroundColor: '#00B38D',
    verticalAlign: 'middle',
    display: 'flex',
    justifyContent: 'center',
}

export const getScoreStyle = score => {
    let color = '#D0021B'; // Red
    if (score >= 2) {
        color = '#FED82E'; // Yellow
    }
    if (score >= 2.5) {
        color = '#00B38D' // Green
    }
    return {
        ...scoreStyle,
        backgroundColor: color,
        color: color === 'yellow' ? 'black' : 'white'
    }
}