import ref from '../db/database';

const sites = ['austin', 'englewood', 'north_lawndale'];

export async function getProgramData(start, end) {
  try {
    const [
      feedback,
      feedback_reminders,
      users,
      participants,
    ] = await Promise.all([
      getFeedback(start, end),
      getFeedbackReminders(start, end),
      getUsers(),
      getParticipants(),
    ]);
    const groups = await getGroups(start, end, users);
    return {
      feedback,
      feedback_reminders,
      users,
      participants,
      groups,
    };
  } catch (error) {
    console.error(error);
  }
}

const getFeedback = async (start, end) => {
  const [austin, englewood, north_lawndale] = await Promise.all(
    sites.map(site => {
      const feedbackRef =
        start && end
          ? ref(`feedback/${site}`)
              .orderByChild('date')
              .startAt(start)
              .endAt(end)
          : ref(`feedback/${site}`);
      return feedbackRef.once('value').then(snap => snap.val());
    })
  );
  return { austin, englewood, north_lawndale };
};

const getFeedbackReminders = async (start, end) => {
  const feedbackReminderRef =
    start && end
      ? ref(`feedback_reminders`)
          .orderByKey()
          .startAt(start)
          .endAt(end)
      : ref(`feedback_reminders`);
  const feedbackReminders = await feedbackReminderRef
    .once('value')
    .then(snap => snap.val());

  return feedbackReminders;
};

const getGroups = async (start, end, users) => {
  const [austin, englewood, north_lawndale] = await Promise.all(
    sites.map(async site => {
      const promiseArray = [];
      Object.values(users).forEach(user => {
        if (user.site === site) {
          const groupRef =
            start && end
              ? ref(`groups/${site}/${user.uid}`)
                  .orderByChild('date')
                  .startAt(start)
                  .endAt(end)
              : ref(`groups/${site}/${user.uid}`);
          promiseArray.push(
            groupRef.once('value').then(snap => {
              return snap.exists()
                ? { [user.uid]: Object.values(snap.val() || {}) }
                : null;
            })
          );
        }
      });
      const userGroups = await Promise.all(promiseArray);
      return userGroups.reduce((obj, group) => {
        if (group) {
          const [userId] = Object.keys(group);
          if (group[userId]) {
            return { ...obj, [userId]: group[userId] };
          }
        }
        return obj;
      }, {});
    })
  );
  return { austin, englewood, north_lawndale };
};

const getUsers = async () => {
  return ref('users')
    .once('value')
    .then(snap => snap.val());
};

const getParticipants = async () => {
  const [austin, englewood, north_lawndale] = await Promise.all(
    sites.map(site =>
      ref(`participants/${site}`)
        .once('value')
        .then(snap => snap.val())
    )
  );
  return { austin, englewood, north_lawndale };
};
