import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Container from 'muicss/lib/react/container';
import Panel from 'muicss/lib/react/panel';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import Divider from 'muicss/lib/react/divider';
import Button from 'muicss/lib/react/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import CreateGroup from '../CreateGroup';
import feedbackIcon from '../../images/iconCheckmark.png';
import {
  getFriendlyDate,
  getFriendlySessionType,
} from '../../utiltites/helpers';
import {
  getTodaysDefaultGroup,
  getAllGroups,
} from '../../selectors/GroupsSelectors';
import Group from '../Group';
import { primaryButtonStyle } from '../../utiltites/commonStyles';

const headerStyle = {
  backgroundColor: '#EDEEF1',
  fontFamily: 'DINNextLTPro-Bold',
  fontSize: '1.6rem',
  padding: '16px 0',
  margin: 0,
};

const subHeaderStyle = {
  fontFamily: 'DINNextLTPro-Light',
  fontSize: '1.2rem',
  padding: '12px 0',
};

const listPanelStyle = {
  padding: 0,
  position: 'relative',
  minHeight: 350,
};

const rowStyle = {
  fontFamily: 'DINNextLTPro-Medium',
  fontSize: '1.2rem',
  padding: '8px 0',
  display: 'flex',
  alignItems: 'center',
};

const centerContentStyle = {
  display: 'flex',
  alignItems: 'center',
};

const feedbackIconStyle = {
  width: '12px',
  height: '12px',
  backgroundImage: `url("${feedbackIcon}")`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  margin: 'auto',
};

const chevronIconStyle = {
  fontSize: '1.4rem',
  color: 'white',
  display: 'flex',
};

const overlay = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  backgroundColor: '#000',
  opacity: 0.1,
  zIndex: 1,
};

const overlayModal = {
  fontFamily: 'DINNextLTPro-Bold',
  position: 'absolute',
  width: '60%',
  backgroundColor: '#FFF',
  height: 'auto',
  top: '210px',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  opacity: 1,
  zIndex: 2,
};

const modalButton = {
  flex: 1,
  width: '100%',
  marginLeft: 0,
};

export class TodaysGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amCreated: false,
      pmCreated: false,
      showOverlay: true,
      createGroup: false,
      groupIndex: 0,
      editGroup: null,
    };

    this.addFeedbackHandler = this.addFeedbackHandler.bind(this);
    this.toggleCopyGroup = this.toggleCopyGroup.bind(this);
    this.resetStateAfterSubmission = this.resetStateAfterSubmission.bind(this);
    this.handleChangeGroup = this.handleChangeGroup.bind(this);
    this.handleEditGroup = this.handleEditGroup.bind(this);
  }

  componentWillMount() {
    const today = moment().format('YYYYMMDD');
    const { todaysGroup } = this.props;
    const overlay = !(today === todaysGroup.date);
    this.setState({
      showOverlay: overlay,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { todaysGroup } = this.props;
    const today = moment().format('YYYYMMDD');
    const nextTodaysGroup = nextProps.todaysGroup;
    let overlay = this.state.showOverlay;
    if (
      todaysGroup.date !== nextTodaysGroup.date &&
      nextTodaysGroup.date !== today
    ) {
      overlay = true;
    }
    this.setState({
      showOverlay: overlay,
    });
  }

  addFeedbackHandler(event, id, group) {
    event.preventDefault();
    this.props.history.push(`/app/groups/${group.group_id}/participant/${id}`, {
      group,
    });
  }

  toggleCopyGroup() {
    const { todaysGroup } = this.props;
    const todaysGroupExists = todaysGroup.date === moment().format('YYYYMMDD');
    this.setState({
      showOverlay: !todaysGroupExists,
      createGroup: !todaysGroupExists,
      editGroup: null,
    });
  }

  resetStateAfterSubmission() {
    this.setState({
      showOverlay: false,
      createGroup: false,
    });
  }

  handleCopyGroup(e, group) {
    const today = moment().format('YYYYMMDD');
    e.preventDefault();
    const participants = { ...group.participants };
    Object.values(group.participants || {}).forEach(participant => {
      participants[participant.participant_id] = {
        first_name: participant.first_name,
        last_name: participant.last_name,
        participant_id: participant.participant_id,
        feedback_id: '',
        comment: '',
      };
    });
    this.setState({
      editGroup: {
        group_id: '',
        feedback_reminder_id: '',
        date: today,
        created_at: today,
        updated_at: today,
        type: group.type,
        participants,
      },
      createGroup: true,
      showOverlay: false,
    });
  }

  handleCreateGroup(e) {
    e.preventDefault();
    this.setState({
      createGroup: true,
      showOverlay: false,
    });
  }

  handleChangeGroup(e, str) {
    e.preventDefault();
    if (str === 'prev') {
      if (this.state.groupIndex < this.props.allGroups.length - 1) {
        this.setState({
          groupIndex: this.state.groupIndex + 1,
        });
      }
    } else {
      if (this.state.groupIndex > 0) {
        this.setState({
          groupIndex: this.state.groupIndex - 1,
        });
      }
    }
  }

  handleEditGroup(group) {
    this.setState({
      createGroup: true,
      showOverlay: false,
      editGroup: group,
    });
  }

  renderParticipants(group) {
    const { showOverlay } = this.state;
    if (!group.participants) {
      return null;
    }
    const renderedParticipants = Object.keys(group.participants).map(id => {
      const participant = group.participants[id];
      const hasFeedback = participant && !!participant.feedback_id;
      return group &&
        group.participants &&
        Object.keys(group.participants).length ? (
        <div key={id} data-test="participant">
          <Row style={rowStyle}>
            <Col md="3" xs="4" className="mui--align-middle">
              {participant.first_name + ' ' + participant.last_name}
            </Col>
            <Col md="3" xs="4" className="mui--align-middle">
              {participant.participant_id}
            </Col>
            {hasFeedback && !showOverlay ? (
              <Col md="3" md-offset="3" xs="4" className="mui--align-middle">
                <div style={feedbackIconStyle} />
              </Col>
            ) : (
              <Col md="3" md-offset="3" xs="4">
                <Button
                  variant="raised"
                  size="small"
                  onClick={event => this.addFeedbackHandler(event, id, group)}
                >
                  {'Add\nFeedback'}
                </Button>
              </Col>
            )}
          </Row>
          <Divider />
        </div>
      ) : null;
    });
    return renderedParticipants;
  }

  renderGroup(groups) {
    const { showOverlay, createGroup, groupIndex } = this.state;
    const group = groups[groupIndex];
    return group && group.date ? (
      <div data-test="todays-group">
        {showOverlay ? (
          <Panel data-test="group-with-overlay" style={listPanelStyle}>
            <div>
              <div style={overlay} />
              <Panel style={overlayModal}>
                <Container>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <h3>{`Create group from a recent session?`}</h3>
                  </div>
                  <Row
                    style={centerContentStyle}
                    className="mui--align-middle mui--text-center"
                  >
                    <Col xs="3">
                      <Button
                        color="primary"
                        size="small"
                        variant="fab"
                        onClick={e => this.handleChangeGroup(e, 'prev')}
                        disabled={
                          groupIndex === this.props.allGroups.length - 1
                        }
                      >
                        <FontAwesomeIcon
                          color="#FFF"
                          icon={faChevronLeft}
                          style={chevronIconStyle}
                        />
                      </Button>
                    </Col>
                    <Col xs="6">
                      <div>
                        <p>{`DATE: ${moment(group.date, 'YYYYMMDD').format(
                          'MM/DD/YYYY'
                        )}`}</p>
                        <p>{`TYPE: ${getFriendlySessionType(group.type)}`}</p>
                      </div>
                    </Col>
                    <Col xs="3">
                      <Button
                        color="primary"
                        size="small"
                        variant="fab"
                        onClick={e => this.handleChangeGroup(e, 'next')}
                        disabled={groupIndex === 0}
                      >
                        <FontAwesomeIcon
                          color="#FFF"
                          icon={faChevronRight}
                          style={chevronIconStyle}
                        />
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Button
                      variant="raised"
                      color="primary"
                      style={modalButton}
                      onClick={e => this.handleCopyGroup(e, group)}
                    >
                      Copy Group
                    </Button>
                    <Button
                      variant="raised"
                      style={{
                        ...primaryButtonStyle,
                        ...modalButton,
                      }}
                      onClick={e => this.handleCreateGroup(e)}
                    >
                      Create New Group
                    </Button>
                  </Row>
                </Container>
              </Panel>
            </div>
            <Row style={headerStyle} className="mui--align-middle">
              <Col md="6" xs="12">
                {getFriendlyDate(group.date)}
              </Col>
              <Col md="6" xs="12">
                {getFriendlySessionType(group.type)}
              </Col>
            </Row>
            <Container style={showOverlay ? { opacity: 0.4 } : {}}>
              <Row style={subHeaderStyle}>
                <Col md="3" xs="4">
                  Name
                </Col>
                <Col md="3" xs="4">
                  ID Number
                </Col>
                <Col md="3" md-offset="3" xs="4">
                  Feedback
                </Col>
              </Row>
              <Divider />
              {this.renderParticipants(group)}
            </Container>
          </Panel>
        ) : (
          <div>
            {createGroup ? (
              <CreateGroup
                data-test="create-group"
                group={this.state.editGroup}
                toggleCopyGroup={this.toggleCopyGroup}
                resetStateAfterSubmission={this.resetStateAfterSubmission}
              />
            ) : (
              <Group
                data-test="group-with-todays-date"
                group={this.props.todaysGroup}
                addFeedbackHandler={this.addFeedbackHandler}
                handleEditGroup={this.handleEditGroup}
              />
            )}
          </div>
        )}
      </div>
    ) : (
      <CreateGroup
        data-test="create-group"
        group={this.state.editGroup}
        toggleCopyGroup={this.toggleCopyGroup}
        resetStateAfterSubmission={this.resetStateAfterSubmission}
      />
    );
  }
  render() {
    const { allGroups } = this.props;
    return (
      <div
        data-test="todays"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        {this.renderGroup(allGroups)}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  allGroups: getAllGroups(state),
  todaysGroup: getTodaysDefaultGroup(state),
  user: state.user,
});

export default withRouter(connect(mapStateToProps)(TodaysGroup));
