import { createLogger } from 'redux-logger';

// log actions in development mode
export default createLogger({
  collapsed: true,

  // only log in development mode
  predicate: () => {
    return process.env.NODE_ENV === 'development' ? true : false;
  },

  // transform immutable state to plain objects
  // stateTransformer: state => {
  //   const newState = state.toJS();
  //   return newState;
  // },

  // actionTransformer: action => {
  //   const jsAction = {};
  //   const keys = Object.keys(action);
  //   keys.forEach(key => {
  //     if (action[key] && action[key].toJS) {
  //       jsAction[key] = action[key].toJS();
  //     } else {
  //       jsAction[key] = action[key];
  //     }
  //   });
  //   return jsAction;
  // }
});
