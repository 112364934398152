import moment from 'moment';
import ref from '../db/database';
import {
  updateParticipant,
  removeParticipant,
  setParticipants,
} from '../actions/participants/ParticipantActions';
import { getUniqueID } from '../utiltites/helpers';

export function listenForParticipants({ site, dispatch }, service = ref) {
  let initialDataLoaded = false;
  return Promise.all([
    service(`participants/${site}`).on('child_added', snapshot => {
      if (initialDataLoaded) {
        const participant = snapshot.val();
        dispatch(updateParticipant(participant));
      }
    }),
    service(`participants/${site}`).on('child_changed', snapshot => {
      if (initialDataLoaded) {
        const participant = snapshot.val();
        dispatch(updateParticipant(participant));
      }
    }),
    service(`participants/${site}`).on('child_removed', snapshot => {
      if (initialDataLoaded) {
        const participant = snapshot.val();
        dispatch(removeParticipant(participant));
      }
    }),
    service(`participants/${site}`)
      .once('value')
      .then(snapshot => {
        const participants = snapshot.exists() ? snapshot.val() : {};
        dispatch(setParticipants(participants));
        initialDataLoaded = true;
      }),
  ]);
}

export function stopListeningToParticipants({ site }, service = ref) {
  service(`participants/${site}`).off();
}

export function createParticipant(
  { first_name, last_name, email, site, stage },
  service = ref
) {
  const today = moment().format('YYYYMMDD');
  return service(`participant_ids`)
    .once('value')
    .then(snapshot => {
      const existingIds = snapshot.val();
      const pretty_id = getUniqueID({
        first_name,
        last_name,
        email,
        existingIds,
      });
      return service().update({
        [`participant_ids/${pretty_id}`]: true,
        [`participants/${site}/${pretty_id}`]: {
          first_name,
          last_name,
          email,
          site,
          stage,
          pretty_id,
          created_at: today,
          updated_at: today,
          disabled: false,
        },
      });
    })
    .catch(error => console.error(error));
}

export function sendParticipantUpdate(
  { first_name, last_name, email, site, stage, pretty_id, disabled },
  service = ref
) {
  const today = moment().format('YYYYMMDD');
  return service(`participants/${site}/${pretty_id}`)
    .update({
      first_name,
      last_name,
      email,
      site,
      stage,
      pretty_id,
      updated_at: today,
      disabled: disabled,
    })
    .catch(error => console.error(error));
}

export const deleteParticipant = async ({ site, pretty_id }, service = ref) => {
  if (site && pretty_id) {
    return service(`participants/${site}/${pretty_id}`).remove();
  }
};
