// import { fromJS } from 'immutable';
import {
  GET_PARTICIPANTS,
  SET_PARTICIPANTS,
  REMOVE_PARTICIPANT,
  UPDATE_PARTICIPANT,
  SET_PARTICIPANTS_LISTENERS,
  CLEAR_PARTICIPANTS
} from '../ActionTypes';

export function requestGroupParticipants() {
  return { type: GET_PARTICIPANTS }
}

export function setParticipantsListeners() {
  return {
    type: SET_PARTICIPANTS_LISTENERS
  }
}

export function setParticipants(participants) {
  return {
    type: SET_PARTICIPANTS,
    payload: participants
  }
}

export function updateParticipant(participant) {
  return {
    type: UPDATE_PARTICIPANT,
    payload: participant
  }
}

export function removeParticipant(participant) {
  return {
    type: REMOVE_PARTICIPANT,
    payload: participant
  }
}

export function clearParticipants() {
  return {
    type: CLEAR_PARTICIPANTS
  }
}