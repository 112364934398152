import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Container from 'muicss/lib/react/container';
import Panel from 'muicss/lib/react/panel';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import Select from 'muicss/lib/react/select';
import Divider from 'muicss/lib/react/divider';
import Button from 'muicss/lib/react/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';

import ParticipantTypeahead from '../ParticipantTypeahead';
import UserTypeahead from '../UserTypeahead';
import { createGroup } from '../../api/Groups.Api';
import { getAllGroups } from '../../selectors/GroupsSelectors';
import { getFriendlySessionType } from '../../utiltites/helpers';
import { toggleRemoveParticipantModal } from '../../actions/appState/AppActions';
import {
  canCreateGroupWC,
  canCreateGroupCAD,
  canCreateGroupPD,
} from '../../utiltites/permissions';
import { setMessage } from '../../actions/messages/MessageActions';

const headerStyle = {
  backgroundColor: '#EDEEF1',
  fontFamily: 'DINNextLTPro-Bold',
  fontSize: '1.6rem',
  padding: '16px 0',
  margin: 0,
};

const chevronIconStyle = {
  fontSize: '1.4rem',
  color: 'white',
  display: 'flex',
};

const subHeaderStyle = {
  fontFamily: 'DINNextLTPro-Light',
  fontSize: '1.2rem',
  padding: '12px 16px',
};

const rowStyle = {
  fontFamily: 'DINNextLTPro-Medium',
  fontSize: '1.2rem',
  padding: '8px 16px',
  display: 'flex',
  alignItems: 'center',
};

const panelStyle = {
  padding: 0,
};

export class CreateGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      participants: [],
      date: '',
      type: '',
      user: {},
      clearInput: false,
    };

    this.onSelect = this.onSelect.bind(this);
    this.toggleCopyGroup = this.toggleCopyGroup.bind(this);
    this.showRemoveParticipantModal = this.showRemoveParticipantModal.bind(
      this
    );
    this.createNewGroup = this.createNewGroup.bind(this);
    this.saveGroup = this.saveGroup.bind(this);
    this.handleUserSelect = this.handleUserSelect.bind(this);
    this.incrementDate = this.incrementDate.bind(this);
    this.decrementDate = this.decrementDate.bind(this);
  }

  componentWillMount() {
    this.initialize(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.group ||
      this.props.group.group_id !== nextProps.group.group_id
    ) {
      this.initialize(nextProps);
    }
  }

  initialize = props => {
    const { role } = props.user;
    const state = {
      date: moment().format('YYYYMMDD'),
      type: this.getDefaultType(role),
      user: {
        ...props.user,
        first_name: props.user.firstName,
        last_name: props.user.lastName,
      },
    };
    if (props.group) {
      const { participants, type, date, staff_id } = props.group;
      state.type = type;
      state.participants = Object.keys(participants).map(
        id => props.participants[id]
      );
      if (staff_id) {
        state.user = props.users.find(u => u.uid === staff_id);
      }
      if (date) {
        state.date = date;
      }
    }
    this.setState(state);
  };

  onSelect(participant) {
    if (
      !this.state.participants.some(p => p.pretty_id === participant.pretty_id)
    ) {
      this.setState({
        participants: [...this.state.participants, participant],
      });
    }
  }

  getDate() {
    return moment(this.state.date, 'YYYYMMDD').format('MM/DD/YYYY');
  }

  getDefaultType(role) {
    return role === 'crew_chief' ? 'WC' : 'CAD';
  }

  handleUserSelect(e) {
    e.preventDefault();
    const user = this.props.users.find(u => u.uid === e.target.value);
    this.setState({ user });
  }

  incrementDate(e) {
    e.preventDefault();
    const today = moment();
    if (today.isAfter(moment(this.state.date, 'YYYYMMDD'), 'day')) {
      const date = moment(this.state.date, 'YYYYMMDD')
        .add(1, 'day')
        .format('YYYYMMDD');
      this.setState({ date });
    }
  }

  decrementDate(e) {
    e.preventDefault();
    const date = moment(this.state.date, 'YYYYMMDD')
      .subtract(1, 'day')
      .format('YYYYMMDD');
    this.setState({ date });
  }

  toggleCopyGroup(e) {
    e.preventDefault();
    this.setState({
      participants: [],
      date: moment().format('YYYYMMDD'),
      type: '',
    });
    if (this.props.adminEdit) {
      this.props.resetStateAfterSubmission();
    } else {
      this.props.toggleCopyGroup(this.props.timeOfDay);
    }
  }

  removeParticipant(participantID) {
    const participantsCopy = this.state.participants.filter(
      p => p.pretty_id !== participantID
    );
    this.setState({
      participants: participantsCopy,
    });
  }

  showRemoveParticipantModal(e, participantID) {
    e.preventDefault();
    const participant = this.props.participants[participantID];
    const onConfirm = () => this.removeParticipant(participantID);
    this.props.dispatch(
      toggleRemoveParticipantModal(true, participant, onConfirm)
    );
  }

  createNewGroup() {
    const { resetStateAfterSubmission, admin } = this.props;
    const { type, participants, user, date } = this.state;
    const group_id = '';
    const newGroup = {
      type,
      group_id,
      participants: participants.reduce((acc, curr) => {
        acc[curr.pretty_id] = {
          comment: '',
          feedback_id: '',
          first_name: curr.first_name,
          last_name: curr.last_name,
          participant_id: curr.pretty_id,
        };
        return acc;
      }, {}),
    };
    createGroup({ user, date, group: newGroup })
      .then(() => {
        resetStateAfterSubmission();
        if (admin) {
          const { role } = this.props.user;
          const state = {
            participants: [],
            clearInput: true,
            date: moment().format('YYYYMMDD'),
            type: this.getDefaultType(role),
            user: {
              ...this.props.user,
              first_name: this.props.user.firstName,
              last_name: this.props.user.lastName,
            },
          };
          if (this.props.group) {
            const { participants, type } = this.props.group;
            state.type = type;
            state.participants = Object.keys(participants).map(
              id => this.props.participants[id]
            );
          }
          this.setState(state, () => this.setState({ clearInput: false }));
        }
        this.props.dispatch(
          setMessage({
            body: 'Group created successfully!',
            messageStyle: 'success',
            timeout: 2000,
          })
        );
      })
      .catch(error => {
        console.log(error);
        this.props.dispatch(
          setMessage({
            body: 'Oops, something went wrong.' + error.message,
            messageStyle: 'alert',
            timeout: 5000,
          })
        );
      });
  }

  saveGroup() {
    const { resetStateAfterSubmission, group, admin } = this.props;
    const { type, participants, user } = this.state;
    const group_id = group.group_id;
    const newGroup = {
      type,
      group_id,
      participants: participants.reduce((acc, curr) => {
        acc[curr.pretty_id] =
          group && group.participants && group.participants[curr.pretty_id]
            ? group.participants[curr.pretty_id]
            : {
                comment: '',
                feedback_id: '',
                first_name: curr.first_name,
                last_name: curr.last_name,
                participant_id: curr.pretty_id,
              };
        return acc;
      }, {}),
    };
    createGroup({ user, date: group.date, group: newGroup })
      .then(() => {
        resetStateAfterSubmission();
        if (admin) {
          const { role } = this.props.user;
          const state = {
            participants: [],
            date: moment().format('YYYYMMDD'),
            type: this.getDefaultType(role),
            user: {
              ...this.props.user,
              first_name: this.props.user.firstName,
              last_name: this.props.user.lastName,
            },
          };
          if (this.props.group) {
            const { participants, type } = this.props.group;
            state.type = type;
            state.participants = Object.keys(participants).map(
              id => this.props.participants[id]
            );
          }
          this.setState(state);
        }
        this.props.dispatch(
          setMessage({
            body: 'Group updated successfully!',
            messageStyle: 'success',
            timeout: 2000,
          })
        );
      })
      .catch(error => {
        console.log(error);
        this.props.dispatch(
          setMessage({
            body: 'Oops, something went wrong.' + error.message,
            messageStyle: 'alert',
            timeout: 5000,
          })
        );
      });
  }

  renderParticipants() {
    const { participants } = this.state;
    const renderedParticipants = participants.map(participant => {
      if (!participant) {
        return null;
      }
      const id = participant.pretty_id;
      return (
        <div key={id} data-test="participant">
          <Row style={rowStyle}>
            <Col md="3" xs="4" className="mui--align-middle">
              {participant.first_name + ' ' + participant.last_name}
            </Col>
            <Col md="3" xs="4" className="mui--align-middle">
              {participant.pretty_id}
            </Col>
            <Col md="3" md-offset="3" xs="4" className="mui--align-middle">
              <Button
                data-test="remove-participant"
                className="mui--pull-right"
                variant="fab"
                size="small"
                color="danger"
                onClick={e => this.showRemoveParticipantModal(e, id)}
                style={{
                  fontSize: '30px',
                }}
              >
                {'-'}
              </Button>
            </Col>
          </Row>
          <Divider />
        </div>
      );
    });
    return renderedParticipants;
  }

  render() {
    const { allGroups, group, admin, adminEdit } = this.props;
    const { user, clearInput, date } = this.state;
    const beingEdited = group && group.group_id;
    const { role } = user;
    return (
      <Panel data-test="group" style={panelStyle}>
        <Row style={headerStyle} className="mui--align-middle">
          {admin ? (
            <Col md="4" xs="12">
              <label>Staff:</label>
              <UserTypeahead
                onSelect={user => this.setState({ user })}
                clearInput={clearInput}
              />
            </Col>
          ) : (
            <Col md="4" xs="12">
              <label>Staff:</label>
              <h3>{`${user.last_name}, ${user.first_name}`}</h3>
            </Col>
          )}
          <Col md="4" xs="12">
            <label>Type:</label>
            <Select
              value={this.state.type}
              name="type"
              onChange={e => this.setState({ type: e.target.value })}
            >
              {canCreateGroupWC(role) && (
                <option data-test="option-wc" value="WC">
                  {getFriendlySessionType('WC')}
                </option>
              )}
              {canCreateGroupCAD(role) && (
                <option data-test="option-cad" value="CAD">
                  {getFriendlySessionType('CAD')}
                </option>
              )}
              {canCreateGroupPD(role) && (
                <option data-test="option-pd" value="PD">
                  {getFriendlySessionType('PD')}
                </option>
              )}
            </Select>
          </Col>
          {admin ? (
            <Col md="4" xs="12">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 0,
                  marginTop: 15,
                }}
              >
                <Button
                  data-test="dec-button"
                  style={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  color="primary"
                  size="small"
                  variant="fab"
                  onClick={this.decrementDate}
                >
                  <FontAwesomeIcon
                    color="#FFF"
                    icon={faChevronLeft}
                    style={chevronIconStyle}
                  />
                </Button>
                <DatePicker
                  selected={moment(date, 'YYYYMMDD')}
                  onChange={d => {
                    this.setState({ date: d.format('YYYYMMDD') });
                  }}
                />
                <Button
                  data-test="inc-button"
                  style={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  color="primary"
                  size="small"
                  variant="fab"
                  onClick={this.incrementDate}
                >
                  <FontAwesomeIcon
                    color="#FFF"
                    icon={faChevronRight}
                    style={chevronIconStyle}
                  />
                </Button>
              </div>
            </Col>
          ) : (
            <Col md="4" xs="12" className="mui--text-center">
              <h3>{this.getDate()}</h3>
            </Col>
          )}
        </Row>
        <Container>
          <Row style={subHeaderStyle}>
            <Col md="3" xs="4">
              Name
            </Col>
            <Col md="3" xs="4">
              ID Number
            </Col>
            <Col md="3" md-offset="3" xs="4" />
          </Row>
          <Divider />

          {this.renderParticipants()}
          <ParticipantTypeahead onSelect={this.onSelect} />
          {!!allGroups.length && !admin && (
            <Button
              data-test="back-button"
              variant="raised"
              color={adminEdit ? 'danger' : 'default'}
              onClick={this.toggleCopyGroup}
            >
              {adminEdit ? 'cancel' : 'back'}
            </Button>
          )}
          <Button
            variant="raised"
            color="primary"
            onClick={beingEdited ? this.saveGroup : this.createNewGroup}
            disabled={this.state.participants.length === 0}
          >
            {beingEdited ? 'save changes' : 'create group'}
          </Button>
        </Container>
      </Panel>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  participants: state.participants,
  allGroups: getAllGroups(state),
  users: state.users,
});

export default connect(mapStateToProps)(CreateGroup);
