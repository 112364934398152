import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Container from 'muicss/lib/react/container';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import Button from 'muicss/lib/react/button';
import PageTitle from '../../components/PageTitle';
import ParticipantList from '../../components/ParticipantList';
import { getAndSortParticipants } from '../../selectors/ParticipantsSelectors';
import { getFriendlySiteName } from '../../utiltites/helpers';
import { toggleCreateParticipantModal } from '../../actions/appState/AppActions';
import { buttonListRowStyle } from '../../utiltites/commonStyles';
import { canCreateParticipant } from '../../utiltites/permissions';

const panelStyle = {
  padding: '0 32px'
}

export class ParticipantsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSelected: true,
      user: null,
    }

    this.handleActiveToggle = this.handleActiveToggle.bind(this);
  }

  handleActiveToggle(on) {
    this.setState({
      activeSelected: on,
    });
  }

  render() {
    const { activeSelected } = this.state;
    const {
      user,
      allParticipants
    } = this.props;
    const siteName = getFriendlySiteName(user.site);
    const titleHeader = activeSelected ? `${siteName} Active Participants` : `${siteName} Inactive Participants`;
    const participantList = activeSelected ? allParticipants.active : allParticipants.inactive;

    return (
      <div>
        <Container style={panelStyle}>
          <Row>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Col md="6" xs="12" style={{ paddingLeft: 0 }}>
              <PageTitle title={'Participants'} />
            </Col>
            {
              canCreateParticipant(user.role) &&
              <Col md="3" md-offset="3" xs="12" style={{ paddingLeft: 0 }}>
                <Button
                  variant="raised"
                  data-test='create-participant-button'
                  onClick={this.props.openCreateParticipantModal}
                >
                  {'create participant'}
                </Button>
              </Col>
            }
            </div>
          </Row>
          <Row className="mui--text-center" style={buttonListRowStyle}>
            <Button
              onClick={() => this.handleActiveToggle(true)}
              color={activeSelected ? 'primary' : null}
              variant='raised'
            >
              {'Active'}
            </Button>
            <Button
              onClick={() => this.handleActiveToggle(false)}
              color={!activeSelected ? 'primary' : null}
              variant='raised'
            >
              {'Inactive'}
            </Button>
          </Row>
          <Row>
            <ParticipantList headerTitle={titleHeader} participants={participantList} />
          </Row>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  allParticipants: getAndSortParticipants(state),
  user: state.user,
})

const mapDispatchToProps = dispatch => ({
  openCreateParticipantModal: () => dispatch(toggleCreateParticipantModal(true))
})

ParticipantsPage.propTypes = {
  user: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantsPage)
