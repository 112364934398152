import moment from 'moment';
import md5 from 'md5';
import {
  doSignInWithEmail,
  doSignOut,
  doCreateUserWithEmailAndPassword,
  doPasswordReset,
  doSendVerificationEmail,
} from '../../db/auth';
import { doGetUserRef, doCreateUser } from '../../db/database';
import { setUser, setSite } from './UserActions';
import { clearState } from '../appState/AppActions';
import { stopListeningToGroups, listenForGroups } from '../../api/Groups.Api';
import {
  stopListeningToParticipants,
  listenForParticipants,
} from '../../api/Group.Participants.Api';
import { setMessage } from '../messages/MessageActions';

// Signs in to Auth and get user data from Firebase
export function loginUser(email, password) {
  return dispatch => {
    return doSignInWithEmail(email, password)
      .then(user => {
        // check if email is verified
        if (user.user && user.user.emailVerified) {
          // grab data from firebase
          return doGetUserRef(user.user.uid)
            .once('value')
            .then(snapshot => {
              const user = snapshot.exists() ? snapshot.val() : null;
              if (user.disabled) {
                dispatch(
                  setMessage({
                    body:
                      'Your account has been deactivated. Please contact an administrator for assistance.',
                    messageStyle: 'alert',
                    timeout: 5000,
                  })
                );
              } else {
                if (user) {
                  dispatch(setUser(user));
                }
              }
            });
        } else {
          // TODO display message to user to check their email for verification link
          return doSendVerificationEmail().then(() => {
            dispatch(
              setMessage({
                body: 'Please check your email for account verification',
                messageStyle: 'success',
                timeout: 5000,
              })
            );
          });
        }
      })
      .catch(error => {
        console.error(error);
        throw error;
      });
  };
}

export function logoutUser() {
  return dispatch => {
    return doSignOut().then(() => {
      dispatch(clearState());
    });
  };
}

// Example of creating new user in Auth and adding data to Firebase
export function createUser(data) {
  return dispatch => {
    const timestamp = moment().format('YYYYMMDDHHmm');
    const md5String = md5(data.email);
    let pretty_id;
    if (md5String.length > 10) {
      pretty_id = md5String.slice(0, 10);
    } else {
      const zero = '0';
      pretty_id = zero.repeat(10 - md5String.length) + md5String;
    }
    return doCreateUserWithEmailAndPassword(data.email, data.password)
      .then(authUser => {
        // add sample user data to firebase
        const userData = {
          created_at: timestamp,
          disabled: false,
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          pretty_id: pretty_id,
          role: data.role,
          site: data.site,
          updated_at: timestamp,
          uid: authUser.user.uid,
        };
        return doCreateUser(authUser.user.uid, userData)
          .then(function () {
            doPasswordReset(userData.email);
          })
          .catch(function (error) {
            throw error;
          });
      })
      .catch(error => {
        throw error;
      });
  };
}

export function passwordReset(email) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      doPasswordReset(email)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

export function changeSite(site) {
  return (dispatch, getState) => {
    const state = getState();
    const currentSite = state.user.site;
    const uid = state.user.uid;
    stopListeningToGroups({ site: currentSite, uid });
    stopListeningToParticipants({ site: currentSite });
    return Promise.all([
      listenForGroups({ site, uid, dispatch }),
      listenForParticipants({ site, dispatch }),
    ])
      .then(() => {
        dispatch(setSite(site));
      })
      .catch(err => console.log(err));
  };
}
