import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Container from 'muicss/lib/react/container';
import Button from 'muicss/lib/react/button';
import Panel from 'muicss/lib/react/panel';
import PageTitle from '../../components/PageTitle';
import { connect } from 'react-redux';
import { setMessage } from '../../actions/messages/MessageActions';
import { isArrayEqual } from '../../utiltites/helpers';
import { StaffForm } from '../../components/StaffForm';
import { updateUser, sendUserPasswordReset } from '../../actions/user/UsersActionCreators';

export class StaffProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staff: null,
    }
  }

  componentWillMount() {
    const { staffID } = this.props.match.params;
    const staff = this.props.users.find( user => {
      return user.uid === staffID;
    });
    if (staff) {
      this.setState({
        staff
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isArrayEqual(this.props.users, nextProps.users)) {
      const { staffID } = nextProps.match.params;
      const staff = nextProps.users.find( user => {
        return user.uid === staffID;
      });
      if (staff) {
        this.setState({
          staff
        })
      }
    }
  }

  handleChange(event) {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  }

  render() {
    const { loading, staff } = this.state;
    const { history, user, updateStaff, resetPassword } = this.props;
    return loading ? <div data-test='loading' >Loading...</div> : (
      <Container data-test='staff-profile'>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        >
          <PageTitle title={'Staff Profile'}/>
          <Button
            data-test="staff-profile-back-btn"
            variant='raised'
            onClick={() => history.goBack()}
          >
            {'back'}
          </Button>
        </div>
        <Panel style={{marginTop: 16}}>
          <StaffForm 
          data-test="staff-profile-form"
          staff={staff} 
          user={user} 
          updateUser={updateStaff}
          resetPassword={resetPassword}/>
        </Panel>
      </Container>
    );
  }
}

StaffProfile.propTypes = {
}

const mapStateToProps = state => ({
  users: state.users,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  setMessage: (message) => dispatch(setMessage(message)),
  updateStaff: (user) => dispatch(updateUser(user)),
  resetPassword: (email) => dispatch(sendUserPasswordReset(email)),
})

StaffProfile.propTypes = {
  setMessage: PropTypes.func.isRequired,
  updateStaff: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffProfile)
