/*
*   keys.js
*   Server keys auto generated
*/

const devConfig = {
  apiKey: "AIzaSyAtPSOf97dGTiUWGF7bIM81MH3lX8jY-2Y",           // Auth / General Use
  authDomain: "heartland-readi-stage.firebaseapp.com",           // Auth with popup/redirect
  databaseURL: "https://heartland-readi-stage.firebaseio.com/",  // Realtime Database
}

const stageConfig = {
  apiKey: "AIzaSyAtPSOf97dGTiUWGF7bIM81MH3lX8jY-2Y",           // Auth / General Use
  authDomain: "heartland-readi-stage.firebaseapp.com",           // Auth with popup/redirect
  databaseURL: "https://heartland-readi-stage.firebaseio.com/",  // Realtime Database
}

const prodConfig = {
  apiKey: "AIzaSyB3sm4zuK6lZdQeH3UNQ67SSfHyz0P9NF0",             // Auth / General Use
  authDomain: "heartland-readi.firebaseapp.com",           // Auth with popup/redirect
  databaseURL: "https://heartland-readi.firebaseio.com/",  // Realtime Database
}

export { devConfig, stageConfig, prodConfig }
