import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import ReactExport from 'react-data-export';
import Col from 'muicss/lib/react/col';
import Button from 'muicss/lib/react/button';
import Container from 'muicss/lib/react/container';
import { getProgramData } from '../../api/ProgramData.Api';
import store from '../../store';
import { setMessage } from '../../actions/messages/MessageActions';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportDataModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      all: false,
      start: moment().format('YYYYMMDD'),
      end: moment().format('YYYYMMDD'),
      users: [],
      participants: [],
      groups: [],
      group_participants: [],
      feedback: [],
      feedback_reminders: [],
    };

    this.formatData = this.formatData.bind(this);
  }
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { start, end, all } = this.state;
    const args = all ? [] : [start, end];
    this.setState({ loading: true });
    getProgramData(...args)
      .then(data => {
        this.formatData(data);
      })
      .catch(err => {
        this.setState({ loading: false });
        store.dispatch(
          setMessage({
            body: 'There was an error fetching program data. Please try again.',
            messageStyle: 'alert',
            timeout: 5000,
          })
        );
      });
  }

  formatData({ feedback, feedback_reminders, groups, users, participants }) {
    const formattedData = {};
    formattedData.users = Object.values(users || {});
    formattedData.participants = Object.keys(participants || {}).reduce(
      (arr, site) => {
        const siteParticipants = Object.values(participants[site] || {});
        return arr.concat(siteParticipants);
      },
      []
    );
    formattedData.group_participants = [];
    formattedData.groups = Object.keys(groups || {}).reduce((arr, site) => {
      let allGroups = [];
      Object.keys(groups[site] || {}).forEach(userId => {
        const usersGroups = groups[site][userId] || {};
        usersGroups.forEach(g => {
          const updatedGroup = {
            ...g,
            site,
            staff_id: userId,
            date: moment(g.date, 'YYYYMMDD').format('M/D/YYYY'),
          };
          Object.values(updatedGroup.participants || {}).forEach(p => {
            formattedData.group_participants.push({
              participant_id: p.participant_id,
              feedback_id: p.feedback_id,
              group_id: updatedGroup.group_id,
              date: updatedGroup.date,
            });
          });
          allGroups.push(updatedGroup);
        });
      });
      return arr.concat(allGroups);
    }, []);
    formattedData.feedback = Object.keys(feedback || {}).reduce((arr, site) => {
      const siteFeedback = Object.values(feedback[site] || {}).map(f => {
        return {
          site,
          attendance_score: f.attendance_score,
          comment: f.comment,
          created_at: f.created_at,
          date: moment(f.date, 'YYYYMMDD').format('M/D/YYYY'),
          feedback_id: f.feedback_id,
          group_id: f.group_id,
          participant_id: f.participant_id,
          type: f.type,
          session_score: f.session_score,
          q1_quest: f.questions ? f.questions.q1.title : '',
          q1_ans: f.questions ? f.questions.q1.value : '',
          q2_quest: f.questions ? f.questions.q2.title : '',
          q2_ans: f.questions ? f.questions.q2.value : '',
          q3_quest: f.questions ? f.questions.q3.title : '',
          q3_ans: f.questions ? f.questions.q3.value : '',
          minutes: +f.hours * 60 + +f.minutes,
        };
      });
      return arr.concat(siteFeedback);
    }, []);
    formattedData.feedback_reminders = Object.keys(
      feedback_reminders || {}
    ).reduce((arr, date) => {
      const daysFeedback = Object.values(feedback_reminders[date] || {}).map(
        reminder => ({
          user_id: reminder.uid,
          group_id: reminder.group_id,
          email: reminder.email,
          completed: reminder.completed,
          date: moment(date, 'YYYYMMDD').format('M/D/YYYY'),
        })
      );
      return arr.concat(daysFeedback);
    }, []);
    this.setState({
      ...formattedData,
      loading: false,
    });
  }

  renderData() {
    const {
      loading,
      users,
      participants,
      groups,
      group_participants,
      feedback,
      feedback_reminders,
    } = this.state;
    return (
      <div
        style={{
          height: 100,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ExcelFile
          filename={`readi_data_${moment().format('YYYYMMDDHHmmss')}`}
          element={
            <button
              disabled={loading}
              className="mui-btn mui-btn--primary mui-btn--raised"
            >
              {loading ? 'loading...' : 'download data'}
            </button>
          }
        >
          <ExcelSheet data={users} name="Users">
            <ExcelColumn label="Last Name" value="last_name" />
            <ExcelColumn label="First Name" value="first_name" />
            <ExcelColumn label="Email" value="email" />
            <ExcelColumn label="Role" value="role" />
            <ExcelColumn label="Site" value="site" />
            <ExcelColumn label="ID" value="uid" />
            <ExcelColumn label="Created" value="created_at" />
            <ExcelColumn label="Updated" value="updated_at" />
            <ExcelColumn
              label="Account Disabled"
              value={col => (col.disabled ? 'y' : 'n')}
            />
          </ExcelSheet>
          <ExcelSheet data={participants} name="Participants">
            <ExcelColumn label="Last Name" value="last_name" />
            <ExcelColumn label="First Name" value="first_name" />
            <ExcelColumn label="Email" value="email" />
            <ExcelColumn label="Site" value="site" />
            <ExcelColumn label="Stage" value="stage" />
            <ExcelColumn label="ID" value="pretty_id" />
            <ExcelColumn label="Created" value="created_at" />
            <ExcelColumn label="Updated" value="updated_at" />
            <ExcelColumn
              label="Account Disabled"
              value={col => (col.disabled ? 'y' : 'n')}
            />
          </ExcelSheet>
          <ExcelSheet data={groups} name="Groups">
            <ExcelColumn label="Date" value="date" />
            <ExcelColumn label="Group ID" value="group_id" />
            <ExcelColumn label="Staff ID" value="staff_id" />
            <ExcelColumn label="Site" value="site" />
            <ExcelColumn label="Type" value="type" />
            <ExcelColumn
              label="Feedback Reminder ID"
              value="feedback_reminder_id"
            />
          </ExcelSheet>
          <ExcelSheet data={group_participants} name="Group Participants">
            <ExcelColumn label="Date" value="date" />
            <ExcelColumn label="Group ID" value="group_id" />
            <ExcelColumn label="Feedback ID" value="feedback_id" />
            <ExcelColumn label="Participant ID" value="participant_id" />
          </ExcelSheet>
          <ExcelSheet data={feedback} name="Feedback">
            <ExcelColumn label="Date" value="date" />
            <ExcelColumn label="Participant ID" value="participant_id" />
            <ExcelColumn label="Group ID" value="group_id" />
            <ExcelColumn label="Type" value="type" />
            <ExcelColumn label="Site" value="site" />
            <ExcelColumn label="Attendance" value="attendance_score" />
            <ExcelColumn label="Session Score" value="session_score" />
            <ExcelColumn label="Comment" value="comment" />
            <ExcelColumn label="Minutes" value="minutes" />
            <ExcelColumn label="Q1 Question" value="q1_quest" />
            <ExcelColumn label="Q1 Answer" value="q1_ans" />
            <ExcelColumn label="Q2 Question" value="q2_quest" />
            <ExcelColumn label="Q2 Answer" value="q2_ans" />
            <ExcelColumn label="Q3 Question" value="q3_quest" />
            <ExcelColumn label="Q3 Answer" value="q3_ans" />
            <ExcelColumn label="Feedback ID" value="feedback_id" />
          </ExcelSheet>
          <ExcelSheet data={feedback_reminders} name="Feedback Reminders">
            <ExcelColumn label="Date" value="date" />
            <ExcelColumn label="Group ID" value="group_id" />
            <ExcelColumn label="Completed" value="completed" />
            <ExcelColumn label="Email" value="email" />
            <ExcelColumn label="Staff ID" value="user_id" />
          </ExcelSheet>
        </ExcelFile>
      </div>
    );
  }

  render() {
    const { start, end, all } = this.state;
    return (
      <Container style={{ height: '500px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="raised"
            color={all ? 'primary' : 'default'}
            onClick={() => {
              this.setState({ all: true }, () => {
                this.fetchData();
              });
            }}
          >
            {'all'}
          </Button>
          <Button
            variant="raised"
            color={!all ? 'primary' : 'default'}
            onClick={() => {
              this.setState({ all: false }, () => {
                this.fetchData();
              });
            }}
          >
            {'custom'}
          </Button>
        </div>
        {!all && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <DatePicker
              selected={moment(start, 'YYYYMMDD')}
              onChange={date => {
                if (
                  date.isBefore(moment(end, 'YYYYMMDD')) &&
                  date.isBefore(moment().endOf('day'))
                ) {
                  this.setState({ start: date.format('YYYYMMDD') }, () => {
                    this.fetchData();
                  });
                } else {
                  this.setState(
                    {
                      start: date.format('YYYYMMDD'),
                      end: date.format('YYYYMMDD'),
                    },
                    () => {
                      this.fetchData();
                    }
                  );
                }
              }}
            />
            <DatePicker
              selected={moment(end, 'YYYYMMDD')}
              onChange={date => {
                if (
                  date.isAfter(moment(start, 'YYYYMMDD')) &&
                  date.isBefore(moment().endOf('day'))
                ) {
                  this.setState({ end: date.format('YYYYMMDD') }, () => {
                    this.fetchData();
                  });
                } else {
                  this.setState(
                    {
                      start: date.format('YYYYMMDD'),
                      end: date.format('YYYYMMDD'),
                    },
                    () => {
                      this.fetchData();
                    }
                  );
                }
              }}
            />
          </div>
        )}
        <Col>{this.renderData()}</Col>
      </Container>
    );
  }
}

export default ExportDataModalContent;
