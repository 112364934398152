import { SET_GROUPS, UPDATE_GROUP, ADD_GROUP, REMOVE_GROUP, CLEAR_STATE } from '../actions/ActionTypes';

const initialState = [];

export default function userReducer(state = initialState, action) {
  switch (action.type) {

    case SET_GROUPS:
      return action.payload;

    case ADD_GROUP:
      return [...state, action.payload];

    case UPDATE_GROUP:
      return state.map(group => {
        if (group.group_id === action.payload.group_id) {
          return action.payload
        }
        return group;
      });

    case REMOVE_GROUP:
      return state.filter(group => {
        return group.group_id !== action.payload.group_id;
      })

    case CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}