import React from 'react'
import { connect } from 'react-redux'
import Container from 'muicss/lib/react/container';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import Button from 'muicss/lib/react/button';
import { toggleRemoveParticipantModal } from '../../actions/appState/AppActions';

const RemoveParticipantModalContent = ({
  participantToRemove,
  removeParticipantOnConfirm,
  removeParticipantModalOpen,
  dispatch
}) => {

  const onConfirm = (event) => {
    event.preventDefault();
    removeParticipantOnConfirm();
    dispatch(toggleRemoveParticipantModal(false));
  }

  const { first_name, last_name } = participantToRemove ? participantToRemove : { first_name: '', last_name: '' };

  return removeParticipantModalOpen ? (
    <Container>
      <Row className="mui--text-center">
        <Col xs="12">
          <h2>{`Are you sure you want to remove ${first_name} ${last_name}?`}</h2>
        </Col>
      </Row>
      <Row className="mui--text-center">
        <Col md='6' xs="12">
          <Button
            variant='raised'
            color='danger'
            onClick={onConfirm}
          >
            {'remove'}
          </Button>
        </Col>
        <Col md='6' xs="12">
          <Button
            variant='raised'
            onClick={() => dispatch(toggleRemoveParticipantModal(false))}
          >
            {'cancel'}
          </Button>
        </Col>
      </Row>
    </Container>
  ) : null;
}

const mapStateToProps = state => ({
  participantToRemove: state.app.participantToRemove,
  removeParticipantOnConfirm: state.app.removeParticipantOnConfirm,
  removeParticipantModalOpen: state.app.removeParticipantModalOpen
})

export default connect(mapStateToProps)(RemoveParticipantModalContent)
