/*
*
*   PageTitle
*
*/
import React from 'react';
import { headerTitleDecoration } from '../../utiltites/commonStyles';

const headerStyle = {
    fontFamily: 'DINNextLTPro-Bold',
    padding: '16px 0',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 8,
  };

const PageTitle = ({title}) => {
    return(
        <div style={headerStyle}>
          <div style={headerTitleDecoration} />
          <h2 style={{fontSize: '1.8rem'}}>{title}</h2>
        </div>
    );
}

export default PageTitle;