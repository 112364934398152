import { createSelector } from 'reselect';

export const getAllActiveUsers = createSelector( 
    state => state.users,
    state => state.user.site,
    (users, site) => sortUsers(users.filter(user => {
        return user.site === site && user.disabled === false;
    }))
)

export const getAllInactiveUsers = createSelector( 
    state => state.users,
    state => state.user.site,
    (users, site) => sortUsers(users.filter(user => {
        return user.site === site && user.disabled === true;
    }))
)

const sortUsers = users => {
    return users.sort( (a,b) => {
        if( a.last_name < b.last_name) {
            return -1;
        }
        if( a.last_name > b.last_name) {
            return 1;
        }
        return 0;
    })
}

