import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modal from '../../components/Modal';
import { ForgotPasswordModalContent } from '../../components/ForgotPasswordModalContent';
import { FeedbackInfoModalContent } from '../../components/FeedbackInfoModalContent';
import {
  toggleForgotPasswordModal,
  toggleRemoveParticipantModal,
  toggleFeedbackInfoModal,
  toggleCreateParticipantModal,
  toggleUpdateParticipantModal,
  toggleChangeSiteModal,
  toggleCreateStaffModal,
  toggleUpdateStaffModal,
  toggleDownloadSummaryModal,
  toggleDataExportModal,
} from '../../actions/appState/AppActions';
import ChangeSiteModalContent from '../../components/ChangeSiteModalContent';
import RemoveParticipantModalContent from '../../components/RemoveParticipantModalContent';
import CreateParticipantModalContent from '../../components/CreateParticipantModalContent';
import CreateStaffModalContent from '../../components/CreateStaffModalContent';
import DownloadSummaryModalContent from '../../components/DownloadSummaryModalContent';
import { createParticipant, sendParticipantUpdate } from '../../api/Group.Participants.Api';
import { sendUserUpdate } from '../../api/Users.Api';
import { createUser } from '../../actions/user/UserActionCreators';
import ExportDataModalContent from '../ExportDataModalContent';

const ModalController = ({
  role,
  forgotPasswordModalOpen,
  closeForgotPasswordModal,
  removeParticipantModalOpen,
  closeRemoveParticipantModal,
  feedbackInfoModal,
  closeFeedbackInfoModal,
  closeCreateParticipantModal,
  createParticipantModalOpen,
  updateParticipantModalOpen,
  closeUpdateParticipantModal,
  participantToEdit,
  closeChangeSiteModal,
  changeSiteModalOpen,
  createStaffModalOpen,
  closeCreateStaffModal,
  createNewUser,
  updateStaffModalOpen,
  closeUpdateStaffModal,
  staffToEdit,
  downloadSummaryModal,
  closeDownloadSummaryModal,
  dataExportModalOpen,
  closeDataExportModal,
}) => {
  return (
    <div>
      <Modal
        show={forgotPasswordModalOpen}
        handleClose={closeForgotPasswordModal}
      >
        <ForgotPasswordModalContent />
      </Modal>
      <Modal
        show={removeParticipantModalOpen}
        handleClose={closeRemoveParticipantModal}
      >
        <RemoveParticipantModalContent />
      </Modal>
      <Modal
        show={createParticipantModalOpen}
        handleClose={closeCreateParticipantModal}
      >
        <CreateParticipantModalContent
          handleParticipant={createParticipant}
          handleClose={closeCreateParticipantModal}
        />
      </Modal>
      <Modal
        show={updateParticipantModalOpen}
        handleClose={closeUpdateParticipantModal}
      >
        <CreateParticipantModalContent
          handleParticipant={sendParticipantUpdate}
          handleClose={closeUpdateParticipantModal}
          participant={participantToEdit}
        />
      </Modal>

      <Modal
        show={createStaffModalOpen}
        handleClose={closeCreateStaffModal}
      >
        <CreateStaffModalContent
          handleStaff={createNewUser}
          handleClose={closeCreateStaffModal}
          role={role}
          buttonLabel={'Create Staff'}
        />
      </Modal>

      <Modal
        show={updateStaffModalOpen}
        handleClose={closeUpdateStaffModal}
      >
        <CreateStaffModalContent
          handleStaff={sendUserUpdate}
          handleClose={closeUpdateStaffModal}
          staff={staffToEdit}
          role={role}
          buttonLabel={'Update User'}
        />
      </Modal>

      <Modal
        show={feedbackInfoModal.open}
        handleClose={closeFeedbackInfoModal}
      >
        <FeedbackInfoModalContent type={feedbackInfoModal.type} questionKey={feedbackInfoModal.questionKey} />
      </Modal>
      <Modal
        show={changeSiteModalOpen}
        handleClose={closeChangeSiteModal}
      >
        <ChangeSiteModalContent />
      </Modal>
      <Modal
        show={downloadSummaryModal.open}
        handleClose={closeDownloadSummaryModal}
      >
        <DownloadSummaryModalContent
          summaryData={downloadSummaryModal}
          close={closeDownloadSummaryModal}
        />
      </Modal>
      <Modal
        show={dataExportModalOpen}
        handleClose={closeDataExportModal}
      >
        <ExportDataModalContent
          show={dataExportModalOpen}
          close={closeDataExportModal}
        />
      </Modal>
    </div>
  )
}

ModalController.propTypes = {
  forgotPasswordModalOpen: PropTypes.bool.isRequired,
  closeForgotPasswordModal: PropTypes.func.isRequired,
  removeParticipantModalOpen: PropTypes.bool.isRequired,
  closeRemoveParticipantModal: PropTypes.func.isRequired,
  createParticipantModalOpen: PropTypes.bool.isRequired,
  closeUpdateParticipantModal: PropTypes.func.isRequired,
  updateParticipantModalOpen: PropTypes.bool.isRequired,
  closeCreateParticipantModal: PropTypes.func.isRequired,
  dataExportModalOpen: PropTypes.bool.isRequired,
  closeDataExportModal: PropTypes.func.isRequired,
  closeFeedbackInfoModal: PropTypes.func.isRequired,
  feedbackInfoModal: PropTypes.object,
}

const mapStateToProps = (state) => ({
  forgotPasswordModalOpen: state.app.forgotPasswordModalOpen,
  removeParticipantModalOpen: state.app.removeParticipantModalOpen,
  feedbackInfoModal: state.app.feedbackInfoModal,
  createParticipantModalOpen: state.app.createParticipantModalOpen,
  updateParticipantModalOpen: state.app.updateParticipantModalOpen,
  participantToEdit: state.app.participantToEdit,
  changeSiteModalOpen: state.app.changeSiteModalOpen,
  createStaffModalOpen: state.app.createStaffModalOpen,
  updateStaffModalOpen: state.app.updateStaffModalOpen,
  downloadSummaryModal: state.app.downloadSummaryModal,
  dataExportModalOpen: state.app.dataExportModalOpen,
  staffToEdit: state.app.staffToEdit,
  role: state.user.role,
})

const mapDispatchToProps = dispatch => ({
  closeForgotPasswordModal: () => dispatch(toggleForgotPasswordModal(false)),
  closeRemoveParticipantModal: () => dispatch(toggleRemoveParticipantModal(false)),
  closeCreateParticipantModal: () => dispatch(toggleCreateParticipantModal(false)),
  closeUpdateParticipantModal: () => dispatch(toggleUpdateParticipantModal(false, null)),
  closeFeedbackInfoModal: () => dispatch(toggleFeedbackInfoModal(false, null)),
  closeChangeSiteModal: () => dispatch(toggleChangeSiteModal(false)),
  closeUpdateStaffModal: () => dispatch(toggleUpdateStaffModal(false)),
  closeCreateStaffModal: () => dispatch(toggleCreateStaffModal(false)),
  closeDownloadSummaryModal: () => dispatch(toggleDownloadSummaryModal(false)),
  closeDataExportModal: () => dispatch(toggleDataExportModal(false)),
  createNewUser: (data) => dispatch(createUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalController)
