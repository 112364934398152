import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Form from "muicss/lib/react/form";
import Input from "muicss/lib/react/input";
import Button from "muicss/lib/react/button";
import Container from "muicss/lib/react/container";
import Row from "muicss/lib/react/row";
import Col from "muicss/lib/react/col";

import { loginUser } from "../../actions/user/UserActionCreators";
import { setMessage } from '../../actions/messages/MessageActions';
import { primaryButtonStyle } from '../../utiltites/commonStyles';

/* eslint-disable react/prefer-stateless-function */
class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  handleUsernameChange(event) {
    event.preventDefault();
    this.setState({ username: event.target.value });
  }

  handlePasswordChange(event) {
    event.preventDefault();
    this.setState({ password: event.target.value });
  }

  handleSubmit(event) {
    const { dispatch } = this.props;
    event.preventDefault();
    dispatch(loginUser(this.state.username, this.state.password))
      .then(() => {
        this.props.history.push('/app')
      })
      .catch((error) => {
        dispatch(setMessage({
          body: 'Your credentials were incorrect. Please try again.',
          messageStyle: 'alert',
          timeout: 5000
        }));
      })
  }

  render() {

    const { onSubmitHandler, onPasswordChangeHandler, onUsernameChangeHandler } = this.props;

    const onHandleUsernameChange = onUsernameChangeHandler ? onUsernameChangeHandler : this.handleUsernameChange;
    const onHandlePasswordChange = onPasswordChangeHandler ? onPasswordChangeHandler : this.handlePasswordChange;
    const onHandleSubmit = onSubmitHandler ? onSubmitHandler : this.handleSubmit;

    return (
      <Form data-test="login-form" onSubmit={onHandleSubmit}>
        <Container>
          <Row>
            <Col xs="12" md="8" md-offset="2">
              <Input
                data-test="login-form-email-input"
                type="email"
                placeholder="email"
                name="username"
                value={this.state.username}
                required
                onChange={onHandleUsernameChange}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="8" md-offset="2">
              <Input
                data-test="login-form-password-input"
                type="password"
                placeholder="password"
                name="password"
                value={this.state.password}
                required
                onChange={onHandlePasswordChange}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Button
                style={primaryButtonStyle}
                data-test="login-form-submit-btn"
                type="submit"
                variant="raised"
                className="mui--bg-color-green-500 mui--color-white"
              >
                Submit
        </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    );
  }
}

LoginForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onSubmitHandler: PropTypes.func,
  onHandleUsernameChange: PropTypes.func,
  onHandlePasswordChange: PropTypes.func,
}

export default connect()(LoginForm);
