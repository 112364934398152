import ref from '../db/database';
import moment from 'moment';
import {
  setUsers,
  updateUser,
  addUser,
  removeUser,
} from '../actions/user/UsersActions';
import { doEmailUpdate } from '../db/auth';
import store from '../store';

export function listenForUsers({ dispatch }, service = ref) {
  let initialDataLoaded = false;
  return Promise.all([
    service(`users`)
      .on('child_added', snapshot => {
        if (initialDataLoaded) {
          const user = snapshot.val();
          dispatch(addUser(user));
        }
      }),
    service(`users`)
      .on('child_changed', snapshot => {
        if (initialDataLoaded) {
          const user = snapshot.val();
          dispatch(updateUser(user));
        }
      }),
    service(`users`)
      .on('child_removed', snapshot => {
        if (initialDataLoaded) {
          const user = snapshot.val();
          dispatch(removeUser(user));
        }
      }),
    service(`users`)
      .once('value')
      .then(snapshot => {
        const users = snapshot.exists() ? Object.values(snapshot.val()) : [];
        dispatch(setUsers(users));
        initialDataLoaded = true;
      })
  ])
    .catch(error => console.error(error))
}

export function stopListeningToUsers(service = ref) {
  service(`users`).off();
}

export function sendUserUpdate({
  first_name,
  last_name,
  email,
  site,
  role,
  uid,
  disabled,
}, service = ref) {
  const today = moment().format('YYYYMMDD');
  return service(`users/${uid}`)
    .update({
      first_name,
      last_name,
      email,
      site,
      role,
      updated_at: today,
      disabled: disabled
    })
}

export function updateCurrentUser({ first_name, last_name, email, uid }, service = ref) {
  const today = moment().format('YYYYMMDD');
  const oldUser = store.getState().user;
  const promiseArray = [
    service(`users/${uid}`)
      .update({
        first_name,
        last_name,
        email,
        updated_at: today,
      })
  ]
  if (oldUser.email !== email) {
    promiseArray.push(doEmailUpdate(email));
  }
  return Promise.all(promiseArray)
    .catch(err => console.log(err))
}