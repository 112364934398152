import ref from '../../db/database';
import { requestSetUsersListener, clearUsers } from "./UsersActions";
import { listenForUsers, stopListeningToUsers, sendUserUpdate } from '../../api/Users.Api';
import { doPasswordReset } from '../../db/auth';
import { setMessage } from '../../actions/messages/MessageActions';

export function fetchUsers(service = ref) {
  return (dispatch, getState) => {
    dispatch(requestSetUsersListener())
    return listenForUsers({ dispatch }, service)
  }
}

export function removeUsers(service = ref) {
  return (dispatch, getState) => {
    dispatch(clearUsers())
    return stopListeningToUsers(service)
  }
}

export function updateUser(user, service = ref) {
  return (dispatch, getState) => {
    return sendUserUpdate(user, service)
  }
}

// Signs in to Auth and get user data from Firebase
export function sendUserPasswordReset(email) {
  return dispatch => {
    return doPasswordReset(email)
      .then(() => {
        dispatch(setMessage({
          body: 'Password reset email has been sent successfully.',
          messageStyle: 'success',
          timeout: 5000
        }));
      })
      .catch(error => {
        console.error(error);
        dispatch(setMessage({
          body: 'Could not successfully send password reset email. Please try again.',
          messageStyle: 'alert',
          timeout: 5000
        }));
      });
  };
}