/*
 *
 *   StaffList
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Container from 'muicss/lib/react/container';
import Panel from 'muicss/lib/react/panel';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import Divider from 'muicss/lib/react/divider';
import Input from 'muicss/lib/react/input';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const panelStyle = {
  padding: 0,
};

const headerStyle = {
  backgroundColor: '#EDEEF1',
  fontFamily: 'DINNextLTPro-Bold',
  fontSize: '1.6rem',
  padding: '16px 0',
  margin: 0,
};

const subHeaderStyle = {
  fontFamily: 'DINNextLTPro-Light',
  fontSize: '1.2rem',
  padding: '12px 0',
};

const userRowStyle = {
  fontFamily: 'DINNextLTPro-Medium',
  fontSize: '1.2rem',
  padding: '8px 0',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
};

export class StaffList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      filteredUsers: [],
      search: '',
    };

    this.onHandleSearchChange = this.onHandleSearchChange.bind(this);
    this.renderUser = this.renderUser.bind(this);
    this.onStaffSelection = this.onStaffSelection.bind(this);
  }

  onHandleSearchChange(e) {
    const { users } = this.props;
    const searchInput = e.target.value;
    if (searchInput === '') {
      this.setState({
        filteredUsers: [],
        search: '',
      });
    } else {
      const userSearch = searchInput.replace(/[^\w\s]+/gi, '');
      const regexp = new RegExp(`^${userSearch}`, 'i');
      const filteredData = users.filter(entry => {
        return (
          regexp.test(entry.last_name) ||
          regexp.test(entry.first_name) ||
          regexp.test(entry.pretty_id)
        );
      });
      this.setState({
        filteredUsers: filteredData,
        search: searchInput,
      });
    }
  }

  onStaffSelection(identifier) {
    this.props.history.push(`/app/staff/${identifier}`);
  }

  renderUser(user) {
    const name = `${user.last_name}, ${user.first_name}`;
    const identifier = user.uid;
    return (
      <div data-test="staff-list-user-row" key={identifier}>
        <Row
          style={userRowStyle}
          onClick={() => this.onStaffSelection(identifier)}
        >
          <Col md="4" xs="8">
            {name}
          </Col>
          <Col md="4" className="mui--hidden-xs">
            {identifier}
          </Col>
          <Col
            md="2"
            md-offset="2"
            xs="2"
            xs-offset="2"
            className="mui--text-right"
          >
            <FontAwesomeIcon color="#000" icon={faChevronRight} />
          </Col>
        </Row>
        <Row>
          <Divider />
        </Row>
      </div>
    );
  }

  render() {
    const { headerTitle, users } = this.props;
    const { filteredUsers, search } = this.state;
    const currentStaffList = search.length > 0 ? filteredUsers : users;

    return (
      <Panel data-test="staff-list" style={panelStyle}>
        <Row style={headerStyle} className="mui--align-left">
          <Col md="6" xs="12" data-test="staff-list-header-title">
            {headerTitle}
          </Col>
          <Col md="6" xs="12">
            <Input
              name="search"
              placeholder="Search"
              data-test="staff-list-search-input"
              onChange={this.onHandleSearchChange}
            />
          </Col>
        </Row>
        <Container>
          <Row style={subHeaderStyle} className="mui--align-left">
            <Col md="4" xs="6">
              Name
            </Col>
            <Col md="4" xs="4" className="mui--hidden-xs">
              ID
            </Col>
            <Col
              md="2"
              md-offset="2"
              xs="4"
              xs-offset="2"
              className="mui--text-right"
            >
              Details
            </Col>
          </Row>
          <Row>
            <Divider />
          </Row>
          {currentStaffList &&
            currentStaffList.map(user => {
              return this.renderUser(user);
            })}
        </Container>
      </Panel>
    );
  }
}

StaffList.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
};

export default withRouter(connect()(StaffList));
