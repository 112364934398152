import React, { Component } from 'react';
import PropTypes from 'prop-types';

const xStyle = {
  position: 'absolute',
  top: '0px',
  right: '0px',
  fontSize: '25px',
  opacity: 0.3,
  width: '35px',
  height: '35px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  outline: 'none'
}

const hoverStyle = {
  opacity: 1,
  cursor: 'pointer'
}

class CloseX extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false
    }

    this.toggleHover = this.toggleHover.bind(this);
  }

  getStyle() {
    return this.state.hovered ? { ...xStyle, ...hoverStyle } : xStyle;
  }

  toggleHover(bool) {
    this.setState({ hovered: bool });
  }

  render() {
    const { disabled, handleClose } = this.props;
    return (
      <div
        role='button'
        tabIndex='0'
        onMouseEnter={() => this.toggleHover(true)}
        onMouseLeave={() => this.toggleHover(false)}
        onClick={handleClose}
        disabled={disabled}
        style={this.getStyle()}
      >
        {'\u2715'}
      </div>
    )
  }
}

CloseX.defaultProps = {
  disabled: false
}

CloseX.propTypes = {
  handleClose: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default CloseX;
