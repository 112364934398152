import React from 'react';
import md5 from 'md5';
import Form from 'muicss/lib/react/form';
import Container from 'muicss/lib/react/container';
import Input from 'muicss/lib/react/input';
import Select from 'muicss/lib/react/select';
import Button from 'muicss/lib/react/button';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import store from '../../store';
import { primaryButtonStyle } from '../../utiltites/commonStyles';
import { setMessage } from '../../actions/messages/MessageActions';
import {
  canSetStaffRoleToSuperAdmin,
  canChangeStaffDeactivated,
} from '../../utiltites/permissions';

export class CreateStaffModalContent extends React.Component {
  constructor(props) {
    super(props);

    const md5String = md5(Date());
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      site: 'austin',
      role: 'coach',
      password: md5String,
      confirm: md5String,
      disabled: false,
    };
    this.submitStaff = this.submitStaff.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { staff } = nextProps;
    if (!!staff) {
      this.setState({
        uid: staff.uid,
        first_name: staff.first_name,
        last_name: staff.last_name,
        email: staff.email,
        site: staff.site,
        role: staff.role,
        disabled: staff.disabled,
      });
    }
  }

  handleChange(event) {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  }

  validateState() {
    const { first_name, last_name, email, role, site } = this.state;
    return (
      !!first_name &&
      !!last_name &&
      /^\S+@\S+\.\S+$/.test(email) &&
      !!role &&
      !!site
    );
  }

  submitStaff(event) {
    event.preventDefault();
    if (this.validateState()) {
      this.props
        .handleStaff({
          ...this.state,
        })
        .then(() => {
          this.setState({
            first_name: '',
            last_name: '',
            email: '',
            site: '',
            role: '',
            errorMsg: '',
          });
          this.props.handleClose();
        })
        .catch(err => {
          store.dispatch(
            setMessage({
              body: 'There was an error creating your staff. Please try again',
              messageStyle: 'alert',
              timeout: 5000,
            })
          );
          console.log(err);
        });
    } else {
      store.dispatch(
        setMessage({
          body: 'All fields are required',
          messageStyle: 'alert',
          timeout: 5000,
        })
      );
    }
  }

  render() {
    const { role, buttonLabel } = this.props;
    return (
      <Form
        data-test="update-user-form"
        style={{ padding: '20px' }}
        onSubmit={e => this.submitStaff(e)}
      >
        <Container>
          <Row>
            <Col md="6">
              <Input
                required
                type="text"
                placeholder="First Name"
                name="first_name"
                value={this.state.first_name}
                onChange={this.handleChange}
              />
            </Col>
            <Col md="6">
              <Input
                required
                type="text"
                placeholder="Last Name"
                name="last_name"
                value={this.state.last_name}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row className="mui--text-left">
            <Col md="4">
              <label>Site:</label>
              <Select
                name="site"
                value={this.state.site}
                onChange={this.handleChange}
              >
                <option value="austin">Austin</option>
                <option value="englewood">Englewood</option>
                <option value="north_lawndale">North Lawndale</option>
              </Select>
            </Col>
            <Col md="4">
              <label>Role:</label>
              <Select
                name="role"
                value={this.state.role}
                onChange={this.handleChange}
              >
                <option value="coach">Coach</option>
                <option value="crew_chief">Crew Chief</option>
                <option value="admin">Site Admin</option>
                {canSetStaffRoleToSuperAdmin(role) ? (
                  <option value="super_admin">Super Admin</option>
                ) : null}
              </Select>
            </Col>
            <Col md="4">
              <label>Deactivated:</label>
              <Select
                name="disabled"
                value={this.state.disabled}
                onChange={this.handleChange}
                disabled={!canChangeStaffDeactivated(role)}
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Select>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Input
                required
                type="email"
                placeholder="Email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row className="mui--text-center">
            <Button
              style={primaryButtonStyle}
              data-test="update-user-form-submit-btn"
              variant="raised"
              type="Submit"
              className="mui--bg-color-green-500 mui--color-white"
            >
              {buttonLabel}
            </Button>
          </Row>
        </Container>
      </Form>
    );
  }
}

export default CreateStaffModalContent;
