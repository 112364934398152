import React from 'react';
import { connect } from 'react-redux';
import Panel from 'muicss/lib/react/panel';
import Button from 'muicss/lib/react/button';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import Container from 'muicss/lib/react/container';
import DropdownItem from 'muicss/lib/react/dropdown-item';
import Dropdown from 'muicss/lib/react/dropdown';
import { changeSite } from '../../actions/user/UserActionCreators';
import { canSeeSiteOverview, canChangeSite } from '../../utiltites/permissions';
import PageTitle from '../../components/PageTitle';
import SplashGraphic from '../../images/splashTrainLarge.png';
import { getFriendlySiteName } from '../../utiltites/helpers';
import SiteOverview from '../../containers/SiteOverview';
import {
  headerTitleDecoration,
  primaryButtonStyle,
} from '../../utiltites/commonStyles';
import { toggleDataExportModal } from '../../actions/appState/AppActions';

const bannerStyle = {
  width: '100%',
  height: '160px',
  backgroundImage: `url("${SplashGraphic}")`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};

const cardGroupStyle = {
  display: 'flex',
  justifyContent: 'center',
  padding: '18px',
};

const panelStyle = {
  padding: '30px',
};

const fullWidthButtonStyle = {
  width: '100%',
};

const headerStyle = {
  fontFamily: 'DINNextLTPro-Bold',
  padding: '16px 0',
  display: 'flex',
  flexDirection: 'column',
  marginTop: 8,
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      site: '',
    };

    this.onChangeSite = this.onChangeSite.bind(this);
    this.openDataExportModal = this.openDataExportModal.bind(this);
  }

  componentWillMount() {
    const { site } = this.props.user;
    this.setState({
      site: site,
    });
  }

  onChangeSite(item) {
    const { changeSite } = this.props;
    this.setState({
      site: item,
    });

    changeSite(item);
  }

  openDataExportModal(e) {
    e.preventDefault();
    this.props.openDataExportModal();
  }

  render() {
    const { history, user } = this.props;
    const { site } = this.state;
    return (
      <div>
        <div style={bannerStyle} className="mui--hidden-xs" />
        <Container>
          {canSeeSiteOverview(user.role) ? (
            canChangeSite(user.role) ? (
              <div style={headerStyle}>
                <div style={headerTitleDecoration} />
                <Row>
                  <Col md="5" sm="12">
                    <h2 style={{ fontSize: '1.8rem' }}>READI Site Overview</h2>
                  </Col>
                  <Col md="4" sm="12">
                    <Dropdown
                      id="site-dropdown"
                      variant="raised"
                      label={getFriendlySiteName(site)}
                      name="site"
                      onSelect={this.onChangeSite}
                      style={{ marginTop: 10 }}
                    >
                      <DropdownItem value="austin" label="Austin">
                        Austin
                      </DropdownItem>
                      <DropdownItem value="englewood" label="Englewood">
                        Englewood
                      </DropdownItem>
                      <DropdownItem
                        value="north_lawndale"
                        label="North Lawndale"
                      >
                        North Lawndale
                      </DropdownItem>
                    </Dropdown>
                  </Col>
                  <Col md="3" sm="12">
                    <Button
                      variant="raised"
                      style={{ ...primaryButtonStyle, marginTop: 16 }}
                      onClick={this.openDataExportModal}
                    >
                      {'export data'}
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <PageTitle
                title={`READI Site Overview: ${getFriendlySiteName(user.site)}`}
              />
            )
          ) : (
            <PageTitle
              title={`Dashboard - ${getFriendlySiteName(user.site)}`}
            />
          )}
          <div>
            {canSeeSiteOverview(user.role) ? (
              <SiteOverview site={user.site} />
            ) : (
              <div style={cardGroupStyle}>
                <Row className="mui--text-center">
                  <Col md="4" xs="12">
                    <Panel style={panelStyle}>
                      <Button
                        style={fullWidthButtonStyle}
                        size="large"
                        onClick={() => history.push(`/app/groups`)}
                      >
                        {"Today's Group"}
                      </Button>
                    </Panel>
                  </Col>
                  <Col md="4" xs="12">
                    <Panel style={panelStyle}>
                      <Button
                        style={fullWidthButtonStyle}
                        size="large"
                        onClick={() =>
                          history.push(`/app/groups`, { previousGroups: true })
                        }
                      >
                        All Groups
                      </Button>
                    </Panel>
                  </Col>
                  <Col md="4" xs="12">
                    <Panel style={panelStyle}>
                      <Button
                        style={fullWidthButtonStyle}
                        size="large"
                        onClick={() => history.push(`/app/participants`)}
                      >
                        Participants
                      </Button>
                    </Panel>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  changeSite: site => dispatch(changeSite(site)),
  openDataExportModal: () => dispatch(toggleDataExportModal(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
