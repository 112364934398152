import {
  SET_USER,
  CLEAR_STATE,
  SET_SITE,
  UPDATE_CURRENT_USER
} from '../actions/ActionTypes';

const initialState = {
  firstName: '',
  lastName: '',
  site: '',
  role: '',
  email: '',
  uid: '',
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {

    case SET_USER:
      return action.payload;

    case SET_SITE:
      return {
        ...state,
        site: action.payload
      };

    case UPDATE_CURRENT_USER:
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email
      }

    case CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}