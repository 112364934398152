// import { fromJS } from "immutable";
import { ADD_USER,
    REMOVE_USER, 
    SET_USERS, 
    UPDATE_USER, 
    SET_USERS_LISTENER, 
    CLEAR_USERS,
  } from "../ActionTypes";
  
  export function requestSetUsersListener() {
    return { type: SET_USERS_LISTENER }
  }
  
  export function setUsers(users) {
    return {
      type: SET_USERS,
      payload: users
    }
  }
  
  export function addUser(user) {
    return {
      type: ADD_USER,
      payload: user
    }
  }
  
  export function updateUser(user) {
    return {
      type: UPDATE_USER,
      payload: user
    }
  }
  
  export function removeUser(user) {
    return {
      type: REMOVE_USER,
      payload: user
    }
  }
  
  export function clearUsers() {
    return {
      type: CLEAR_USERS
    }
  }