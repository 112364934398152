import React, { Component } from 'react'
import {
  Route,
} from 'react-router-dom';

import StaffGroupPage from '../StaffGroupPage';
import FeedbackPageContainer from '../FeedbackPage';
import Dashboard from '../../components/Dashboard';
import { fetchGroups, removeGroups } from '../../actions/groups/GroupsActionCreators';
import { fetchUsers, removeUsers } from '../../actions/user/UsersActionCreators';
import { fetchParticipants, removeParticipants } from '../../actions/participants/ParticipantActionCreators';
import { toggleMenu } from '../../actions/appState/AppActions';
import ParticipantsPage from '../ParticipantsPage';
import ParticipantProfile from '../ParticipantProfile';
import StaffPage from '../StaffPage';
import StaffProfile from '../StaffProfile';
import { connect } from 'react-redux';
import { canSeeStaffPage } from '../../utiltites/permissions';
import StaffGroups from '../StaffGroups';
import MyAccount from '../MyAccount';
import SingleGroupPage from '../../components/SingleGroupPage';

class MainContent extends Component {
  constructor(props) {
    super(props);

    this.onContentClick = this.onContentClick.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(fetchGroups());
    this.props.dispatch(fetchParticipants());
    this.props.dispatch(fetchUsers());
  }


  componentWillReceiveProps(nextProps) {
    if (!this.props.sidebarOpen && nextProps.sidebarOpen) {
      document.body.style.overflow = 'hidden';
    }
    if (this.props.sidebarOpen && !nextProps.sidebarOpen) {
      document.body.style.overflow = 'initial';
    }
  }

  componentWillUnmount() {
    this.props.dispatch(removeGroups())
    this.props.dispatch(removeParticipants());
    this.props.dispatch(removeUsers());
  }

  onContentClick() {
    const { sidebarOpen, dispatch } = this.props;
    if (sidebarOpen) {
      dispatch(toggleMenu());
    }
  }

  render() {
    const { match, user, sidebarOpen } = this.props;
    return (
      <div id='content-wrapper' onClick={sidebarOpen ? this.onContentClick : () => { }}>
        <Route
          exact
          path={match.url}
          component={Dashboard}
        />
        <Route exact path={`${match.url}/groups`} component={StaffGroupPage} />
        <Route exact path={`${match.url}/groups/:groupID`} component={SingleGroupPage} />
        <Route exact path={`${match.url}/groups/:groupID/participant/:participantID`} component={FeedbackPageContainer} />
        <Route exact path={`${match.url}/participants`} component={ParticipantsPage} />
        <Route exact path={`${match.url}/participants/:participantID`} component={ParticipantProfile} />
        <Route exact path={`${match.url}/myaccount`} component={MyAccount} />
        {canSeeStaffPage(user.role) ? (<div>
          <Route exact path={`${match.url}/staff_groups`} component={StaffGroups} />
          <Route exact path={`${match.url}/staff`} component={StaffPage} />
          <Route exact path={`${match.url}/staff/:staffID`} component={StaffProfile} />
        </div>) : null
        }
        {/* <Route default render={() => <div>404</div>} /> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  sidebarOpen: state.app.menuOpen,
})

export default connect(mapStateToProps)(MainContent);
