import { createSelector } from "reselect";
import moment from "moment";

export const getFeedbackData = createSelector(
  state => state.feedback,
  feedback => {
    const { end, selectedDate, forms } = feedback;
    const today = moment().format("YYYYMMDD");
    const startDate = selectedDate || today;
    const endDate = end || today;
    const fourWeeksAgo = moment(today, "YYYYMMDD")
      .subtract(4, "weeks")
      .subtract(1, "day")
      .format("YYYYMMDD");
    const feedbackData = forms.reduce(
      (data, form) => {
        if (form.date === selectedDate) {
          data.day.forms.push(form);
          if (form.type === "WC") {
            data.day.wcAttendanceTotal += 1;
          }
          if (form.attendance_score === 3) {
            data.day.totalSessionsAttended += 1;
            data.day.scoreTotal += form.session_score;
            data.day.cadHours += form.hours ? form.hours : 0;
            data.day.cadHours += form.minutes ? form.minutes / 60 : 0;
            if (form.type === "WC") {
              data.day.wcAttended += 1;
            }
          }
        }
        if (startDate < form.date && form.date <= endDate) {
          data.range.forms.push(form);
          if (form.type === "WC") {
            data.range.wcAttendanceTotal += 1;
          }
          if (form.attendance_score === 3) {
            data.range.totalSessionsAttended += 1;
            data.range.scoreTotal += form.session_score;
            data.range.cadHours += form.hours ? form.hours : 0;
            data.range.cadHours += form.minutes ? form.minutes / 60 : 0;
            if (form.type === "WC") {
              data.range.wcAttended += 1;
            }
          }
        }
        if (fourWeeksAgo < form.date && form.date <= today) {
          data.lastFour.forms.push(form);
          if (form.type === "WC") {
            data.lastFour.wcAttendanceTotal += 1;
          }
          if (form.attendance_score === 3) {
            data.lastFour.totalSessionsAttended += 1;
            data.lastFour.scoreTotal += form.session_score;
            if (form.type === "WC") {
              data.lastFour.wcAttended += 1;
            }
          }
        }
        data.totalCadHours += form.hours ? form.hours : 0;
        data.totalCadHours += form.minutes ? form.minutes / 60 : 0;
        return data;
      },
      {
        day: {
          forms: [],
          wcAttendanceTotal: 0,
          wcAttended: 0,
          totalSessionsAttended: 0,
          scoreTotal: 0,
          cadHours: 0
        },
        range: {
          forms: [],
          wcAttendanceTotal: 0,
          wcAttended: 0,
          totalSessionsAttended: 0,
          scoreTotal: 0,
          cadHours: 0
        },
        lastFour: {
          forms: [],
          wcAttendanceTotal: 0,
          wcAttended: 0,
          totalSessionsAttended: 0,
          scoreTotal: 0
        },
        totalCadHours: 0
      }
    );

    feedbackData.day.attendanceAverage =
      feedbackData.day.wcAttended / feedbackData.day.wcAttendanceTotal;
    if (isNaN(feedbackData.day.attendanceAverage)) {
      feedbackData.day.attendanceAverage = "n/a";
    }
    feedbackData.day.scoreAverage =
      feedbackData.day.scoreTotal / feedbackData.day.totalSessionsAttended;
    if (isNaN(feedbackData.day.scoreAverage)) {
      feedbackData.day.scoreAverage = "n/a";
    }

    feedbackData.range.attendanceAverage =
      feedbackData.range.wcAttended / feedbackData.range.wcAttendanceTotal;
    if (isNaN(feedbackData.range.attendanceAverage)) {
      feedbackData.range.attendanceAverage = "n/a";
    }
    feedbackData.range.scoreAverage =
      feedbackData.range.scoreTotal / feedbackData.range.totalSessionsAttended;
    if (isNaN(feedbackData.range.scoreAverage)) {
      feedbackData.range.scoreAverage = "n/a";
    }

    feedbackData.lastFour.attendanceAverage =
      feedbackData.lastFour.wcAttended /
      feedbackData.lastFour.wcAttendanceTotal;
    if (isNaN(feedbackData.lastFour.attendanceAverage)) {
      feedbackData.lastFour.attendanceAverage = "n/a";
    }
    feedbackData.lastFour.scoreAverage =
      feedbackData.lastFour.scoreTotal /
      feedbackData.lastFour.totalSessionsAttended;
    if (isNaN(feedbackData.lastFour.scoreAverage)) {
      feedbackData.lastFour.scoreAverage = "n/a";
    }

    return feedbackData;
  }
);
