import {
  CLEAR_STATE,
  TOGGLE_CREATE_GROUP,
  TOGGLE_FORGOT_PASSWORD,
  TOGGLE_MENU,
  TOGGLE_FEEDBACK_INFO,
  TOGGLE_REMOVE_PARTICIPANT,
  TOGGLE_CREATE_PARTICIPANT,
  TOGGLE_UPDATE_PARTICIPANT,
  TOGGLE_CHANGE_SITE,
  TOGGLE_CREATE_STAFF,
  TOGGLE_UPDATE_STAFF,
  TOGGLE_DOWNLOAD_SUMMARY,
  TOGGLE_DATA_EXPORT
} from "../actions/ActionTypes";

const initialState = {
  createGroupModalOpen: false,
  forgotPasswordModalOpen: false,
  removeParticipantModalOpen: false,
  createParticipantModalOpen: false,
  updateParticipantModalOpen: false,
  createStaffModalOpen: false,
  updateStaffModalOpen: false,
  changeSiteModalOpen: false,
  dataExportModalOpen: false,
  participantToEdit: null,
  participantToRemove: null,
  staffToEdit: null,
  removeParticipantOnConfirm: null,
  feedbackInfoModal: {
    open: false,
    type: null,
    questionKey: null
  },
  downloadSummaryModal: {
    open: false,
    participant: null,
    feedbackData: null,
    end: null,
    selectedDate: null
  },
  menuOpen: false
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_CREATE_GROUP:
      return { ...state, createGroupModalOpen: action.payload };

    case TOGGLE_CREATE_PARTICIPANT:
      return { ...state, createParticipantModalOpen: action.payload };

    case TOGGLE_CREATE_STAFF:
      return { ...state, createStaffModalOpen: action.payload };

    case TOGGLE_DATA_EXPORT:
      return { ...state, dataExportModalOpen: action.payload };

    case TOGGLE_UPDATE_PARTICIPANT:
      return {
        ...state,
        updateParticipantModalOpen: action.bool,
        participantToEdit: action.participant
      };

    case TOGGLE_FORGOT_PASSWORD:
      return { ...state, forgotPasswordModalOpen: action.payload };

    case TOGGLE_REMOVE_PARTICIPANT:
      return {
        ...state,
        removeParticipantModalOpen: action.payload.bool,
        participantToRemove: action.payload.participant,
        removeParticipantOnConfirm: action.payload.onConfirm
      };
    case TOGGLE_UPDATE_STAFF:
      return {
        ...state,
        updateStaffModalOpen: action.payload.open,
        staffToEdit: action.payload.staff
      };

    case TOGGLE_FEEDBACK_INFO:
      return { ...state, feedbackInfoModal: action.payload };

    case TOGGLE_CHANGE_SITE:
      return { ...state, changeSiteModalOpen: action.payload };

    case TOGGLE_MENU:
      return { ...state, menuOpen: !state.menuOpen };

    case TOGGLE_DOWNLOAD_SUMMARY:
      return {
        ...state,
        downloadSummaryModal: action.payload
      };

    case CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}
