import { SET_STAFF_GROUPS, CLEAR_STAFF_GROUPS } from '../ActionTypes';

export function setStaffGroups({ uid, groups }) {
  return {
    type: SET_STAFF_GROUPS,
    payload: {
      uid,
      groups,
    },
  };
}
export function clearStaffGroups() {
  return {
    type: CLEAR_STAFF_GROUPS,
  };
}
