import {
  SET_FEEDBACK_FORMS,
  SET_SELECTED_DATE,
  SET_END_DATE,
  CLEAR_FEEDBACK_FORMS,
  CLEAR_STATE
} from "../actions/ActionTypes";

const initialState = {
  forms: [],
  end: "",
  selectedDate: ""
};

export default function feedbackDataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FEEDBACK_FORMS:
      return {
        ...state,
        forms: action.payload.forms,
        selectedDate: action.payload.selectedDate,
        end: action.payload.end
      };

    case SET_SELECTED_DATE:
      return { ...state, selectedDate: action.payload };

    case SET_END_DATE:
      return { ...state, end: action.payload };

    case CLEAR_FEEDBACK_FORMS:
      return initialState;

    case CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}
