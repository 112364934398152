import { SET_GROUPS, SET_GROUPS_LISTENER, UPDATE_GROUP, ADD_GROUP, REMOVE_GROUP, CLEAR_GROUPS } from '../ActionTypes';

export function requestSetGroupsListener() {
  return { type: SET_GROUPS_LISTENER }
}

export function setGroups(groups) {
  return {
    type: SET_GROUPS,
    payload: groups
  }
}

export function updateGroup(group) {
  return {
    type: UPDATE_GROUP,
    payload: group
  }
}
export function addGroup(group) {
  return {
    type: ADD_GROUP,
    payload: group
  }
}
export function removeGroup(group) {
  return {
    type: REMOVE_GROUP,
    payload: group
  }
}
export function clearGroups() {
  return {
    type: CLEAR_GROUPS
  }
}