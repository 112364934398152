import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { isEqual } from 'lodash';
import Container from 'muicss/lib/react/container';
import Row from 'muicss/lib/react/row';
import Button from 'muicss/lib/react/button';
import Panel from 'muicss/lib/react/panel';
import Col from 'muicss/lib/react/col';
import Divider from 'muicss/lib/react/divider';
import PageTitle from '../../components/PageTitle';
import { connect } from 'react-redux';
import {
  setSelectedDate,
  clearFeedbackData,
  setEndDate,
} from '../../actions/feedbackData/FeedbackDataActions';
import { setMessage } from '../../actions/messages/MessageActions';
import { getFeedbackData } from '../../selectors/FeedbackDataSelectors';
import ActivityRow from './ActivityRow';
import { canUpdateParticipant } from '../../utiltites/permissions';
import {
  getFriendlyScore,
  getFriendlySessionType,
  getFriendlyPercentage,
} from '../../utiltites/helpers';
import { getFeedbackForms } from '../../actions/participants/ParticipantActionCreators';
import {
  toggleUpdateParticipantModal,
  toggleDownloadSummaryModal,
} from '../../actions/appState/AppActions';

const chevronIconStyle = {
  fontSize: '1.4rem',
  color: 'white',
  display: 'flex',
};

const headerStyle = {
  backgroundColor: '#EDEEF1',
  fontFamily: 'DINNextLTPro-Bold',
  fontSize: '1.6rem',
  padding: '16px 0',
  margin: 0,
};

const dataWindowStyle = {
  borderColor: '#edeef1',
  borderStyle: 'solid',
  borderWidth: '2px',
  backgroundColor: 'white',
  margin: '8px',
};

const subHeaderStyle = {
  fontFamily: 'DINNextLTPro-Light',
  fontSize: '1.2rem',
  padding: '12px 0',
};

const boldTitleStyle = {
  display: 'block',
  fontFamily: 'DINNextLTPro-Bold',
  fontSize: '32px',
  color: '#404040',
};

const lightTitleStyle = {
  display: 'block',
  fontFamily: 'DINNextLTPro-Light',
  fontSize: '20px',
  color: '#404040',
  padding: '20px 0 0',
};

const smallLightSubtitleStyle = {
  display: 'block',
  fontFamily: 'DINNextLTPro-Light',
  fontSize: '16px',
  color: '#404040',
};

const smallBoldSubtitleStyle = {
  display: 'block',
  fontFamily: 'DINNextLTPro',
  fontSize: '28px',
  color: '#404040',
};

const dividerStyle = {
  backgroundColor: '#3CB432',
};

export class ParticipantProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participant: null,
      loading: false,
      selectedRange: 'week',
    };

    this.incrementDate = this.incrementDate.bind(this);
    this.decrementDate = this.decrementDate.bind(this);
  }

  async componentWillMount() {
    try {
      const { participantID } = this.props.match.params;
      const participant = this.props.participants[participantID];
      if (participant) {
        this.setState({
          participant,
          loading: true,
        });
        await this.props.getFeedbackForms({
          participant_id: participantID,
          site: this.props.site,
        });
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      this.setState({ loading: false });
      this.props.setMessage({
        body: 'Something went wrong while fetching participant data',
        messageStyle: 'alert',
        timeout: 5000,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { participantID } = this.props.match.params;

    if (
      !isEqual(
        this.props.participants[participantID],
        nextProps.participants[participantID]
      )
    ) {
      this.setState({
        participant: nextProps.participants[participantID],
      });
    }
  }

  componentWillUnmount() {
    this.props.clearFeedbackData();
  }

  getStartDate = () => {
    return moment(this.props.selectedDate, 'YYYYMMDD').format('MM/DD/YYYY');
  };
  getEndDate = () => {
    return this.props.end
      ? moment(this.props.end, 'YYYYMMDD').format('MM/DD/YYYY')
      : moment().format('MM/DD/YYYY');
  };

  getDate() {
    if (this.state.selectedRange === 'day') {
      return this.getStartDate();
    } else {
      return `${this.getStartDate()} - ${this.getEndDate()}`;
    }
  }

  getLabel() {
    switch (this.state.selectedRange) {
      case 'day':
        return 'Daily';
      case 'week':
        return 'Weekly';
      case 'custom':
        return 'Custom';
      default:
        return '';
    }
  }

  incrementDate(e) {
    e.preventDefault();
    const today = moment();
    if (this.state.selectedRange === 'day') {
      if (today.isAfter(moment(this.props.selectedDate, 'YYYYMMDD'), 'day')) {
        const selectedDate = moment(this.props.selectedDate, 'YYYYMMDD')
          .add(1, 'day')
          .format('YYYYMMDD');
        this.props.setSelectedDate(selectedDate);
      }
    } else {
      const endMoment = this.props.end
        ? moment(this.props.end, 'YYYYMMDD')
        : moment();
      const endDate = endMoment
        .endOf('week')
        .add(1, 'week')
        .format('YYYYMMDD');
      const startDate = endMoment.startOf('week').format('YYYYMMDD');
      if (moment(endDate, 'YYYYMMDD').isSameOrBefore(moment())) {
        this.props.setEndDate(endDate);
        this.props.setSelectedDate(startDate);
      } else {
        this.props.setEndDate(today.format('YYYYMMDD'));
        this.props.setSelectedDate(today.startOf('week').format('YYYYMMDD'));
      }
    }
  }

  decrementDate(e) {
    e.preventDefault();
    if (this.state.selectedRange === 'day') {
      const selectedDate = moment(this.props.selectedDate, 'YYYYMMDD')
        .subtract(1, 'day')
        .format('YYYYMMDD');
      this.props.setSelectedDate(selectedDate);
    } else {
      const startMoment = this.props.selectedDate
        ? moment(this.props.selectedDate, 'YYYYMMDD')
        : moment();
      const startDate = startMoment
        .startOf('week')
        .subtract(1, 'week')
        .format('YYYYMMDD');
      const endDate = startMoment.endOf('week').format('YYYYMMDD');
      this.props.setEndDate(endDate);
      this.props.setSelectedDate(startDate);
    }
  }

  renderActivities(forms) {
    return forms.length ? (
      <div>
        <Row style={subHeaderStyle}>
          <Col md="2" xs="2">
            Date
          </Col>
          <Col md="4" className="mui--hidden-xs">
            Session Type
          </Col>
          <Col xs="2" className="mui--visible-xs-inline">
            Type
          </Col>
          <Col md="3" xs="4">
            Staff
          </Col>
          <Col md="2" xs="2">
            Feedback
          </Col>
        </Row>
        <Divider />
        {forms.map(form => (
          <ActivityRow key={form.feedback_id} feedback={form} />
        ))}
      </div>
    ) : null;
  }

  renderDayActivities() {
    const { forms } = this.props.feedbackData.day;
    return forms.length ? (
      <Container data-test="activities">
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <h2>Activity</h2>
        </Row>
        {this.renderActivities(forms)}
      </Container>
    ) : null;
  }

  renderRangeActivities() {
    const organizedForms = this.props.feedbackData.range.forms.reduce(
      (data, form) => {
        const key = form.type.toLowerCase() + 'Forms';
        data[key].push(form);
        return data;
      },
      { cadForms: [], wcForms: [], pdForms: [] }
    );
    const sortedForms = {
      cadForms: organizedForms.cadForms.sort((a, b) => b.date - a.date),
      wcForms: organizedForms.wcForms.sort((a, b) => b.date - a.date),
      pdForms: organizedForms.pdForms.sort((a, b) => b.date - a.date),
    };
    return (
      <Container>
        {Object.values(sortedForms).some(formArray => formArray.length > 0) && (
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <h2>Activity</h2>
          </Row>
        )}
        {!!sortedForms.pdForms.length && (
          <Row>
            <h2>{getFriendlySessionType('PD')}</h2>
          </Row>
        )}
        {this.renderActivities(sortedForms.pdForms)}
        {!!sortedForms.cadForms.length && (
          <Row>
            <h2>{getFriendlySessionType('CAD')}</h2>
          </Row>
        )}
        {this.renderActivities(sortedForms.cadForms)}
        {!!sortedForms.wcForms.length && (
          <Row>
            <h2>{getFriendlySessionType('WC')}</h2>
          </Row>
        )}
        {this.renderActivities(sortedForms.wcForms)}
      </Container>
    );
  }

  render() {
    const { participant, loading, selectedRange } = this.state;
    const { history, feedbackData, end, user, selectedDate } = this.props;
    const dayOrRange = selectedRange === 'day' ? 'day' : 'range';
    if (loading) {
      return <div data-test="loading">Loading...</div>;
    }
    if (!participant) {
      return <div>Participant not found</div>;
    }

    return (
      <Container data-test="participant-profile">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <PageTitle title={'Participant Profile'} />

          <div>
            {canUpdateParticipant(user.role) ? (
              <Button
                variant="raised"
                onClick={() => this.props.openEditProfile(participant)}
              >
                {'edit profile'}
              </Button>
            ) : null}
            <Button variant="raised" onClick={() => history.goBack()}>
              {'back'}
            </Button>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="raised"
            color={selectedRange === 'day' ? 'primary' : 'default'}
            onClick={() => {
              this.setState({ selectedRange: 'day' });
              this.props.setSelectedDate(moment().format('YYYYMMDD'));
            }}
          >
            {'day'}
          </Button>
          <Button
            variant="raised"
            color={selectedRange === 'week' ? 'primary' : 'default'}
            onClick={() => {
              this.setState({ selectedRange: 'week' });
              this.props.setSelectedDate(
                moment()
                  .startOf('week')
                  .format('YYYYMMDD')
              );
              this.props.setEndDate(moment().format('YYYYMMDD'));
            }}
          >
            {'week'}
          </Button>
          <Button
            variant="raised"
            color={selectedRange === 'custom' ? 'primary' : 'default'}
            onClick={() => {
              this.setState({ selectedRange: 'custom' });
              this.props.setSelectedDate(
                moment()
                  .startOf('week')
                  .format('YYYYMMDD')
              );
              this.props.setEndDate(moment().format('YYYYMMDD'));
            }}
          >
            {'custom'}
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {selectedRange !== 'custom' && (
            <Button
              style={{ margin: '0 12px' }}
              data-test="decrement-date"
              color="primary"
              size="small"
              variant="fab"
              onClick={this.decrementDate}
            >
              <FontAwesomeIcon
                color="#FFF"
                icon={faChevronLeft}
                style={chevronIconStyle}
              />
            </Button>
          )}
          {selectedRange !== 'custom' ? (
            <h2
              style={{
                textAlign: 'center',
                fontFamily: 'DINNextLTPro-Bold',
                fontSize: '1.5rem',
              }}
            >
              {this.getDate()}
            </h2>
          ) : (
            <div>
              <DatePicker
                selected={moment(selectedDate, 'YYYYMMDD')}
                onChange={date => {
                  if (
                    date.isBefore(moment(end, 'YYYYMMDD')) &&
                    date.isBefore(moment().endOf('day'))
                  ) {
                    this.props.setSelectedDate(date.format('YYYYMMDD'));
                  }
                }}
              />
              <DatePicker
                selected={moment(end, 'YYYYMMDD')}
                onChange={date => {
                  if (
                    date.isAfter(moment(selectedDate, 'YYYYMMDD')) &&
                    date.isBefore(moment().endOf('day'))
                  ) {
                    this.props.setEndDate(date.format('YYYYMMDD'));
                  }
                }}
              />
            </div>
          )}
          {selectedRange !== 'custom' && (
            <Button
              style={{ margin: '0 12px' }}
              color="primary"
              size="small"
              variant="fab"
              onClick={this.incrementDate}
            >
              <FontAwesomeIcon
                color="#FFF"
                icon={faChevronRight}
                style={chevronIconStyle}
              />
            </Button>
          )}
        </div>
        <Panel style={{ padding: 0 }}>
          <Row style={headerStyle} className="mui--align-middle">
            <Col
              md="4"
              xs="12"
            >{`${participant.first_name} ${participant.last_name}`}</Col>
            <Col md="4" xs="12">{`${participant.pretty_id}`}</Col>
            <Col
              md="4"
              xs="12"
              className="mui--text-right"
            >{`${participant.stage}`}</Col>
          </Row>
          <Container>
            <Row
              className="mui--align-middle mui--text-center"
              style={{ marginTop: '15px' }}
            >
              <Col md="4" xs="12">
                <Panel style={dataWindowStyle}>
                  <p style={lightTitleStyle}>{this.getLabel()}</p>
                  <p style={boldTitleStyle}>{'WC Attendance'}</p>
                  <Divider style={dividerStyle} />
                  <p style={smallBoldSubtitleStyle}>
                    {!feedbackData[dayOrRange].forms.length
                      ? 'n/a'
                      : getFriendlyPercentage(
                          feedbackData[dayOrRange].attendanceAverage
                        )}
                  </p>
                  <p
                    style={smallLightSubtitleStyle}
                  >{`Last 4 Weeks Attendance Rate: ${getFriendlyPercentage(
                    feedbackData.lastFour.attendanceAverage
                  )}`}</p>
                </Panel>
              </Col>
              <Col md="4" xs="12">
                <Panel style={dataWindowStyle}>
                  <p style={lightTitleStyle}>{this.getLabel()}</p>
                  <p style={boldTitleStyle}>{'Average Score'}</p>
                  <Divider style={dividerStyle} />
                  <p style={smallBoldSubtitleStyle}>
                    {!feedbackData[dayOrRange].forms.length
                      ? 'n/a'
                      : getFriendlyScore(feedbackData[dayOrRange].scoreAverage)}
                  </p>
                  <p
                    style={smallLightSubtitleStyle}
                  >{`Last 4 Weeks Average Score: ${getFriendlyScore(
                    feedbackData.lastFour.scoreAverage
                  )}`}</p>
                </Panel>
              </Col>
              <Col md="4" xs="12">
                <Panel style={dataWindowStyle}>
                  <p style={lightTitleStyle}>{this.getLabel()}</p>
                  <p style={boldTitleStyle}>{'CAD Hours'}</p>
                  <Divider style={dividerStyle} />
                  <p style={smallBoldSubtitleStyle}>
                    {!feedbackData[dayOrRange].forms.length
                      ? 'n/a'
                      : feedbackData[dayOrRange].cadHours}
                  </p>
                  <p
                    style={smallLightSubtitleStyle}
                  >{`Total hours of CAD earned: ${feedbackData.totalCadHours}`}</p>
                </Panel>
              </Col>
            </Row>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '18px 0',
              }}
            >
              <Button
                variant="raised"
                onClick={() =>
                  this.props.openDownloadSummary({
                    participant,
                    feedbackData,
                    end,
                    selectedDate,
                  })
                }
              >
                {'download summary'}
              </Button>
            </Row>

            {selectedRange === 'day'
              ? this.renderDayActivities()
              : this.renderRangeActivities()}
          </Container>
        </Panel>
      </Container>
    );
  }
}

ParticipantProfile.propTypes = {
  participants: PropTypes.objectOf(
    PropTypes.shape({
      created_at: PropTypes.string.isRequired,
      disabled: PropTypes.bool.isRequired,
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      pretty_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      stage: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
    })
  ).isRequired,
  site: PropTypes.string.isRequired,
  selectedDate: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  feedbackData: PropTypes.object.isRequired,
  getFeedbackForms: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  clearFeedbackData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  participants: state.participants,
  site: state.user.site,
  selectedDate: state.feedback.selectedDate,
  end: state.feedback.end,
  feedbackData: getFeedbackData(state),
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  getFeedbackForms: data => dispatch(getFeedbackForms(data)),
  setMessage: message => dispatch(setMessage(message)),
  setSelectedDate: date => dispatch(setSelectedDate(date)),
  setEndDate: date => dispatch(setEndDate(date)),
  clearFeedbackData: date => dispatch(clearFeedbackData(date)),
  openEditProfile: participant =>
    dispatch(toggleUpdateParticipantModal(true, participant)),
  openDownloadSummary: ({ participant, feedbackData, end, selectedDate }) =>
    dispatch(
      toggleDownloadSummaryModal(
        true,
        participant,
        feedbackData,
        end,
        selectedDate
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantProfile);
