import { createSelector } from 'reselect';
import moment from 'moment';

export const getTodaysGroups = createSelector(
  state => state.groups,
  groups => sortGroups(groups.filter(group => {
    return group.date === moment().format('YYYYMMDD');
  }))
)

export const getAllGroups = createSelector(
  state => state.groups,
  groups => sortGroups(groups)
)

export const getTodaysDefaultGroup = createSelector(
  state => state.groups,
  groups => groups.reduce((mostRecentSession, group) => {
    const { date } = group;
    if (!mostRecentSession.date) {
      mostRecentSession = group;
      return mostRecentSession;
    }
    if (moment(date, 'YYYYMMDD').isAfter(moment(mostRecentSession.date, 'YYYYMMDD'))) {
      mostRecentSession = group;
    }
    return mostRecentSession;
  }, { date: null })
)

export const sortAllGroups = createSelector(
  state => state.groups,
  groups => sortGroups(groups)
)

const sortGroups = groups => {
  return groups.sort((a, b) => {
    if (a.date > b.date) {
      return -1;
    }
    if (a.date < b.date) {
      return 1;
    }
    return 0;
  })
}