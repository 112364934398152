import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { devConfig, stageConfig, prodConfig } from './keys';
let config;
switch (process.env.REACT_APP_ENVIRONMENT) {
  case 'production':
    config = prodConfig;
    break;
  case 'stage':
    config = stageConfig;
    break;
  default:
    config = devConfig;
    break;
}

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.database();
const auth = firebase.auth();

export { db, auth };
