import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Container from 'muicss/lib/react/container';
import Panel from 'muicss/lib/react/panel';
import Divider from 'muicss/lib/react/divider';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import Button from 'muicss/lib/react/button';
import {
  listenForFeedback,
  stopListeningToFeedback
} from '../../api/SiteData.Api';
import {
  getFriendlyPercentage,
  getFriendlyScore
} from '../../utiltites/helpers';
import { getFormCompletionRatio } from '../../selectors/SiteDataSelectors';
import {
  listenForStaffGroups,
  stopListeningForStaffGroups
} from '../../api/Groups.Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft
} from '@fortawesome/free-solid-svg-icons';

const chevronIconStyle = {
  fontSize: '1.4rem',
  color: 'white',
  display: 'flex'
};

const dataWindowStyle = {
  borderColor: '#edeef1',
  borderStyle: 'solid',
  borderWidth: '2px',
  backgroundColor: 'white',
  margin: '8px',
  minHeight: '184px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'column'
};

const boldTitleStyle = {
  display: 'block',
  fontFamily: 'DINNextLTPro-Bold',
  fontSize: '32px',
  color: '#404040'
};

const smallBoldSubtitleStyle = {
  display: 'block',
  fontFamily: 'DINNextLTPro',
  fontSize: '1.4rem',
  color: '#404040',
  padding: '0 8px'
};

const dividerStyle = {
  backgroundColor: '#3CB432',
  width: '100%',
  marginBottom: '8px'
};

export class SiteOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRange: 'day',
      selectedDate: '',
      end: ''
    };
  }

  componentWillMount() {
    const { site, siteData, users, dispatch, testFetch } = this.props;
    this.setState({
      selectedDate: moment().format('YYYYMMDD')
    });
    if (testFetch) {
      testFetch();
    } else {
      Promise.all([
        listenForFeedback({ site, siteData, dispatch }),
        listenForStaffGroups({ site, users, dispatch })
      ]);
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (this.props.site !== nextProps.site) {
      const { site, siteData, users, uid, dispatch } = this.props;
      if (nextProps.testFetch) {
        nextProps.testFetch();
      } else {
        stopListeningToFeedback({ site });
        stopListeningForStaffGroups({ site, users, uid });
        Promise.all([
          listenForFeedback({ site: nextProps.site, siteData, dispatch }),
          listenForStaffGroups({ site: nextProps.site, users, dispatch })
        ]);
      }
    }
  }

  componentWillUnmount() {
    const { site, users, uid, testFetch } = this.props;
    if (testFetch) {
      testFetch();
    } else {
      stopListeningToFeedback({ site });
      stopListeningForStaffGroups({ site, users, uid });
    }
  }

  getStartDate = () => {
    return moment(this.state.selectedDate, 'YYYYMMDD').format('MM/DD/YYYY');
  };

  getEndDate = () => {
    return this.state.end
      ? moment(this.state.end, 'YYYYMMDD').format('MM/DD/YYYY')
      : moment().format('MM/DD/YYYY');
  };

  getDate() {
    if (this.state.selectedRange === 'day') {
      return this.getStartDate();
    } else {
      return `${this.getStartDate()} - ${this.getEndDate()}`;
    }
  }

  getLabel() {
    switch (this.state.selectedRange) {
      case 'day':
        return 'Daily';
      case 'week':
        return 'Weekly';
      case 'custom':
        return 'Custom';
      default:
        return '';
    }
  }

  selectDay = () => {
    const selectedDate = moment().format('YYYYMMDD');
    this.setState({
      selectedRange: 'day',
      selectedDate
    });
    this.changeListeners({ selectedDate });
  };

  selectWeek = () => {
    const dayMoment = moment();
    const end = dayMoment.format('YYYYMMDD');
    const selectedDate = dayMoment.startOf('week').format('YYYYMMDD');
    this.setState({
      selectedRange: 'week',
      selectedDate,
      end
    });
    this.changeListeners({ end, selectedDate });
  };

  selectCustom = () => {
    const dayMoment = moment();
    const end = dayMoment.format('YYYYMMDD');
    const selectedDate = dayMoment.startOf('week').format('YYYYMMDD');
    this.setState({
      selectedRange: 'custom',
      selectedDate,
      end
    });
    this.changeListeners({ end, selectedDate });
  };

  changeListeners({ selectedDate, end }) {
    const { site, users, siteData, uid, dispatch, testFetch } = this.props;
    if (testFetch) {
      testFetch();
    } else {
      stopListeningToFeedback({ site });
      stopListeningForStaffGroups({ site, users, uid });
    }
    const feedbackArgs = {
      site,
      siteData,
      dispatch
    };
    const formCompletionArgs = {
      site,
      users,
      dispatch
    };
    if (selectedDate) {
      feedbackArgs.day = selectedDate;
      formCompletionArgs.day = selectedDate;
    }
    if (end) {
      feedbackArgs.end = end;
      formCompletionArgs.end = end;
    }
    if (testFetch) {
      testFetch();
    } else {
      Promise.all([
        listenForFeedback(feedbackArgs),
        listenForStaffGroups(formCompletionArgs)
      ]);
    }
  }

  incrementDate = e => {
    e.preventDefault();
    const { end, selectedDate, selectedRange } = this.state;
    const today = moment();
    if (selectedRange === 'day') {
      if (today.isAfter(moment(selectedDate, 'YYYYMMDD'), 'day')) {
        const newSelectedDate = moment(selectedDate, 'YYYYMMDD')
          .add(1, 'day')
          .format('YYYYMMDD');
        this.setState({ selectedDate: newSelectedDate });
        this.changeListeners({ selectedDate: newSelectedDate });
      }
    } else {
      const endMoment = end ? moment(end, 'YYYYMMDD') : moment();
      const endDate = endMoment
        .endOf('week')
        .add(1, 'week')
        .format('YYYYMMDD');
      const startDate = endMoment.startOf('week').format('YYYYMMDD');
      if (moment(endDate, 'YYYYMMDD').isSameOrBefore(moment())) {
        this.setState({ end: endDate, selectedDate: startDate });
        this.changeListeners({
          selectedDate: startDate,
          end: endDate
        });
      } else {
        this.setState({
          end: today.format('YYYYMMDD'),
          selectedDate: today.startOf('week').format('YYYYMMDD')
        });
        this.changeListeners({
          selectedDate: today.startOf('week').format('YYYYMMDD'),
          end: today.format('YYYYMMDD')
        });
      }
    }
  };

  decrementDate = e => {
    e.preventDefault();
    const { end, selectedDate, selectedRange } = this.state;
    if (selectedRange === 'day') {
      const newSelectedDate = moment(selectedDate, 'YYYYMMDD')
        .subtract(1, 'day')
        .format('YYYYMMDD');
      this.setState({ selectedDate: newSelectedDate });
      this.changeListeners({ selectedDate: newSelectedDate });
    } else {
      const startMoment = selectedDate
        ? moment(selectedDate, 'YYYYMMDD')
        : moment();
      const startDate = startMoment
        .startOf('week')
        .subtract(1, 'week')
        .format('YYYYMMDD');
      const endDate = startMoment.endOf('week').format('YYYYMMDD');
      this.setState({ end: endDate, selectedDate: startDate });
      this.changeListeners({ end: endDate, selectedDate: startDate });
    }
  };

  render() {
    const {
      cadAttendance,
      pdAttendance,
      wcAttendance,
      scoreAverage,
      cadHoursAverage
    } = this.props.siteData;
    const { formCompletionRatio } = this.props;
    const { selectedRange, selectedDate, end } = this.state;

    return (
      <Container>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            variant='raised'
            color={selectedRange === 'day' ? 'primary' : 'default'}
            onClick={this.selectDay}
          >
            {'day'}
          </Button>
          <Button
            data-test='week-button'
            variant='raised'
            color={selectedRange === 'week' ? 'primary' : 'default'}
            onClick={this.selectWeek}
          >
            {'week'}
          </Button>
          <Button
            variant='raised'
            color={selectedRange === 'custom' ? 'primary' : 'default'}
            onClick={this.selectCustom}
          >
            {'custom'}
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {selectedRange !== 'custom' && (
            <Button
              data-test='dec-button'
              style={{ margin: '0 12px', zIndex: 0 }}
              color='primary'
              size='small'
              variant='fab'
              onClick={this.decrementDate}
            >
              <FontAwesomeIcon
                color='#FFF'
                icon={faChevronLeft}
                style={chevronIconStyle}
              />
            </Button>
          )}
          {selectedRange !== 'custom' ? (
            <h2 style={{ textAlign: 'center' }}>{this.getDate()}</h2>
          ) : (
            <div>
              <DatePicker
                selected={moment(selectedDate, 'YYYYMMDD')}
                onChange={date => {
                  if (
                    date.isBefore(moment(end, 'YYYYMMDD')) &&
                    date.isBefore(moment().endOf('day'))
                  ) {
                    this.setState({ selectedDate: date.format('YYYYMMDD') });
                    this.changeListeners({
                      selectedDate: date.format('YYYYMMDD'),
                      end
                    });
                  }
                }}
              />
              <DatePicker
                selected={moment(end, 'YYYYMMDD')}
                onChange={date => {
                  if (
                    date.isAfter(moment(selectedDate, 'YYYYMMDD')) &&
                    date.isBefore(moment().endOf('day'))
                  ) {
                    this.setState({ end: date.format('YYYYMMDD') });
                    this.changeListeners({
                      selectedDate,
                      end: date.format('YYYYMMDD')
                    });
                  }
                }}
              />
            </div>
          )}
          {selectedRange !== 'custom' && (
            <Button
              data-test='inc-button'
              style={{ margin: '0 12px', zIndex: 0 }}
              color='primary'
              size='small'
              variant='fab'
              onClick={this.incrementDate}
            >
              <FontAwesomeIcon
                color='#FFF'
                icon={faChevronRight}
                style={chevronIconStyle}
              />
            </Button>
          )}
        </div>
        <Row className='mui--align-middle mui--text-center'>
          <Col md='3' xs='12'>
            <Panel style={dataWindowStyle}>
              <p style={boldTitleStyle}>{'Attendance'}</p>
              <Divider style={dividerStyle} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'center'
                }}
              >
                <p
                  style={smallBoldSubtitleStyle}
                >{`CAD: ${getFriendlyPercentage(cadAttendance)}`}</p>
                <p style={smallBoldSubtitleStyle}>{`PD: ${getFriendlyPercentage(
                  pdAttendance
                )}`}</p>
                <p style={smallBoldSubtitleStyle}>{`WC: ${getFriendlyPercentage(
                  wcAttendance
                )}`}</p>
              </div>
            </Panel>
          </Col>
          <Col md='3' xs='12'>
            <Panel style={dataWindowStyle}>
              <p style={boldTitleStyle}>{'Score Average'}</p>
              <Divider style={dividerStyle} />
              <p style={smallBoldSubtitleStyle}>
                {getFriendlyScore(scoreAverage)}
              </p>
            </Panel>
          </Col>
          <Col md='3' xs='12'>
            <Panel style={dataWindowStyle}>
              <p style={boldTitleStyle}>{'CAD Hours'}</p>
              <Divider style={dividerStyle} />
              <p style={smallBoldSubtitleStyle}>
                {getFriendlyScore(cadHoursAverage)}
              </p>
            </Panel>
          </Col>
          <Col md='3' xs='12'>
            <Panel style={dataWindowStyle}>
              <p style={boldTitleStyle}>{'Form Completion'}</p>
              <Divider style={dividerStyle} />
              <p style={smallBoldSubtitleStyle}>
                {getFriendlyPercentage(formCompletionRatio)}
              </p>
            </Panel>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  site: state.user.site,
  siteData: state.siteData,
  users: state.users,
  selectedDate: state.siteData.selectedDate,
  end: state.siteData.end,
  formCompletionRatio: getFormCompletionRatio(state),
  uid: state.user.uid
});

export default connect(mapStateToProps)(SiteOverview);
