import React from "react";
import moment from "moment";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet
} from "@react-pdf/renderer";
import {
  getFriendlyScore,
  getFriendlyPercentage,
  getFriendlySessionType,
  toProperCase
} from "../../utiltites/helpers";

// const jsPDF = require('jspdf');

const styles = StyleSheet.create({
  header: {
    display: "flex",
    borderBottom: "1px solid green",
    height: "80px",
    padding: "20px"
  },
  dataBox: {
    height: "180px",
    width: "180px",
    border: "1px solid green"
  }
});

class DownloadWeeklySummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      activityBreakdown: null
    };
  }

  componentWillMount() {
    this.getActivityBreakdown(this.props.feedbackData.range.forms, () => {});

    // const doc = new PDFDocument();
    // const stream = doc.pipe(blobStream());
    // doc.text('Hello world!');
    // doc.end();
    // stream.on('finish', () => {
    //   const url = stream.toBlobURL('application/pdf');
    //   this.setState({ url })
    // })
    // var doc = new jsPDF()

    // doc.text('Hello world!', 10, 10)
    // doc.save('a4.pdf')
  }

  getActivityBreakdown(forms, callback) {
    const comments = [];
    const activityBreakdown = forms.reduce(
      (data, form) => {
        const dataProp = data[form.type];
        dataProp.forms.push(form);
        if (form.attendance_score === 3) {
          dataProp.scoreTotal += form.session_score;
          dataProp.q1Total += form.questions.q1.value;
          dataProp.q2Total += form.questions.q2.value;
          dataProp.q3Total += form.questions.q3.value;
          dataProp.attendance += 1;
          if (form.comment) {
            comments.push(form);
          }
        }
        dataProp.attendanceAverage =
          dataProp.attendance / dataProp.forms.length;
        if (isNaN(dataProp.attendanceAverage)) {
          dataProp.attendanceAverage = 0;
        }
        dataProp.scoreAverage = dataProp.scoreTotal / dataProp.attendance;
        if (isNaN(dataProp.scoreAverage)) {
          dataProp.scoreAverage = 0;
        }
        dataProp.q1Average = dataProp.q1Total / dataProp.attendance;
        if (isNaN(dataProp.q1Average)) {
          dataProp.q1Average = 0;
        }
        dataProp.q2Average = dataProp.q2Total / dataProp.attendance;
        if (isNaN(dataProp.q2Average)) {
          dataProp.q2Average = 0;
        }
        dataProp.q3Average = dataProp.q3Total / dataProp.attendance;
        if (isNaN(dataProp.q3Average)) {
          dataProp.q3Average = 0;
        }
        return data;
      },
      {
        WC: {
          forms: [],
          scoreTotal: 0,
          q1Total: 0,
          q2Total: 0,
          q3Total: 0,
          attendance: 0
        },
        PD: {
          forms: [],
          scoreTotal: 0,
          q1Total: 0,
          q2Total: 0,
          q3Total: 0,
          attendance: 0
        },
        CAD: {
          forms: [],
          scoreTotal: 0,
          q1Total: 0,
          q2Total: 0,
          q3Total: 0,
          attendance: 0
        }
      }
    );
    this.setState(
      {
        activityBreakdown,
        comments
      },
      callback()
    );
  }

  getFileName() {
    const { first_name, last_name } = this.props.participant;
    return `${last_name}_${first_name}_${moment(
      this.props.startDate,
      "MM/DD/YYYY"
    ).format("MMDDYYYY")}-${moment(this.props.endDate, "MM/DD/YYYY").format(
      "MMDDYYYY"
    )}`;
  }

  // handleClick() {
  //   const doc = new jsPDF()

  //   doc.text('Hello world!', 10, 10)
  //   doc.save('a4.pdf')
  // }

  renderActivityBreakdown() {
    const { activityBreakdown } = this.state;
    return activityBreakdown
      ? Object.keys(activityBreakdown).map(type => {
          const data = activityBreakdown[type];
          return (
            <View
              key={type}
              style={{
                flexDirection: "row",
                fontSize: 10,
                padding: 10,
                height: 60
              }}
            >
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  borderRight: "1px solid gray"
                }}
              >
                {type === "PD" ? (
                  <View>
                    <Text>Professional</Text>
                    <Text>Development</Text>
                  </View>
                ) : (
                  <Text>{getFriendlySessionType(type)}</Text>
                )}
              </View>
              <View style={{ flex: 5, flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                  <View
                    style={{
                      flex: 1,
                      borderBottom: "1px solid gray",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Text>Average Score</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Text>
                      {!data.forms.length
                        ? "n/a"
                        : getFriendlyScore(data.scoreAverage)}
                    </Text>
                  </View>
                </View>
                <View style={{ flex: 1 }}>
                  <View
                    style={{
                      flex: 1,
                      borderBottom: "1px solid gray",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Text>{type === "WC" ? "Work Effort" : "On Time"}</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Text>
                      {!data.forms.length
                        ? "n/a"
                        : getFriendlyScore(data.q1Average)}
                    </Text>
                  </View>
                </View>
                <View style={{ flex: 1 }}>
                  <View
                    style={{
                      flex: 1,
                      borderBottom: "1px solid gray",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Text>{type === "WC" ? "Cooperation" : "In Room"}</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Text>
                      {!data.forms.length
                        ? "n/a"
                        : getFriendlyScore(data.q2Average)}
                    </Text>
                  </View>
                </View>
                <View style={{ flex: 1 }}>
                  <View
                    style={{
                      flex: 1,
                      borderBottom: "1px solid gray",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Text>{type === "WC" ? "Safety" : "Participation"}</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Text>
                      {!data.forms.length
                        ? "n/a"
                        : getFriendlyScore(data.q3Average)}
                    </Text>
                  </View>
                </View>
                <View style={{ flex: 1, borderLeft: "1px solid gray" }}>
                  <View
                    style={{
                      flex: 1,
                      borderBottom: "1px solid gray",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Text>Attendance</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Text>
                      {!data.forms.length
                        ? "n/a"
                        : getFriendlyPercentage(data.attendanceAverage)}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          );
        })
      : null;
  }

  renderComments() {
    const { comments } = this.state;
    return comments.length
      ? comments.map((form, i) => {
          const commentStyle = {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontSize: 10,
            minHeight: 25,
            padding: 10,
            border: "1px solid gray",
            borderBottom: 0
          };
          if (i === comments.length - 1) {
            commentStyle.borderBottom = "1px solid gray";
          }
          return (
            <View
              data-test="comment"
              key={form.feedback_id}
              style={commentStyle}
            >
              <Text style={{ flex: 1 }}>
                {moment(form.date, "YYYYMMDD").format("MM/DD/YYYY")}
              </Text>
              <Text style={{ flex: 2 }}>{toProperCase(form.staff_name)}</Text>
              <Text style={{ flex: 4 }}>{form.comment}</Text>
            </View>
          );
        })
      : null;
  }

  renderDocument() {
    let { feedbackData, participant, startDate, endDate } = this.props;
    const { range, lastFour, totalCadHours } = feedbackData;
    return (
      <Document>
        <Page size="A4" wrap>
          <View style={styles.header}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 10
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Text>{`${participant.first_name} ${participant.last_name}`}</Text>
                <View style={{ width: "15px" }} />
                <Text style={{ color: "gray" }}>Review Summary</Text>
              </View>
              <Text
                style={{ color: "gray" }}
              >{`ID# ${participant.pretty_id}`}</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text
                style={{ color: "gray" }}
              >{`${startDate} - ${endDate}`}</Text>
              <View style={{ width: "15px" }} />
              <Text style={{ color: "green" }}>{participant.stage}</Text>
            </View>
          </View>
          {/* <View>
            <Text style={{ textAlign: "center", fontSize: 30, padding: 20 }}>
              {"Activity"}
            </Text>
          </View> */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "20px 0"
            }}
          >
            <View style={styles.dataBox}>
              <View
                style={{
                  flex: 2,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <View style={{ alignItems: "center" }}>
                  <Text style={{ fontSize: 25 }}>{"Attendance"}</Text>
                  <Text style={{ padding: 15 }}>
                    {!range.forms.length
                      ? "n/a"
                      : getFriendlyPercentage(range.attendanceAverage)}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  borderTop: "1px solid gray",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <View style={{ alignItems: "center" }}>
                  <Text style={{ fontSize: 10, padding: 10 }}>
                    {"Last 4 weeks attendance"}
                  </Text>
                  <Text>
                    {!lastFour.forms.length
                      ? "n/a"
                      : getFriendlyPercentage(lastFour.attendanceAverage)}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.dataBox}>
              <View
                style={{
                  flex: 2,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <View style={{ alignItems: "center" }}>
                  <Text style={{ fontSize: 25 }}>{"Average Score"}</Text>
                  <Text style={{ padding: 15 }}>
                    {!range.forms.length
                      ? "n/a"
                      : getFriendlyScore(range.scoreAverage)}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  borderTop: "1px solid gray",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <View style={{ alignItems: "center" }}>
                  <Text style={{ fontSize: 10, padding: 10 }}>
                    {"Last 4 weeks average score"}
                  </Text>
                  <Text>
                    {!lastFour.forms.length
                      ? "n/a"
                      : getFriendlyScore(lastFour.scoreAverage)}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.dataBox}>
              <View
                style={{
                  flex: 2,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <View style={{ alignItems: "center" }}>
                  <Text style={{ fontSize: 25 }}>{"CAD Hours"}</Text>
                  <Text style={{ padding: 15 }}>
                    {!range.forms.length ? "n/a" : range.cadHours}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  borderTop: "1px solid gray",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <View style={{ alignItems: "center" }}>
                  <Text style={{ fontSize: 10, padding: 10 }}>
                    {"Total CAD Hours Earned"}
                  </Text>
                  <Text>{totalCadHours}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ margin: "20px" }}>
            <View
              style={{
                width: "50px",
                borderTop: "2px solid green",
                marginBottom: 15
              }}
            />
            <Text style={{ fontSize: 25 }}>Activity Breakdown</Text>
            {this.renderActivityBreakdown()}
            <View
              style={{
                width: "50px",
                borderTop: "2px solid green",
                marginBottom: 15,
                marginTop: 25
              }}
            />
            <Text style={{ fontSize: 25 }}>Comments</Text>
            {this.renderComments()}
          </View>
        </Page>
      </Document>
    );
  }

  render() {
    return (
      <PDFDownloadLink
        data-test="download-pdf-button"
        className="mui-btn mui-btn--raised"
        document={this.renderDocument()}
        fileName={this.getFileName()}
      >
        {({ loading }) => (loading ? "Loading document..." : "Download")}
      </PDFDownloadLink>
    );
  }
}

export default DownloadWeeklySummary;
