/*
*
*   MyAccountForm
*
*/

import React from 'react'
import PropTypes from 'prop-types'
import Container from 'muicss/lib/react/container';
import Button from 'muicss/lib/react/button';
import Form from 'muicss/lib/react/form';
import Input from 'muicss/lib/react/input';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import { primaryButtonStyle } from '../../utiltites/commonStyles';

export class MyAccountForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      uid: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateState = this.validateState.bind(this);
  }

  componentWillMount() {
    const { user } = this.props;
    if (user) {
      this.setState({
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        uid: user.uid
      })
    }
  }

  handleChange(event) {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.validateState()) {
      this.props.updateUser(this.state)
        .then(() => {
          this.props.updateUserRedux(this.state);
          const message = {
            body: 'Your profile was successfully updated.',
            messageStyle: 'success',
            timeout: 5000
          }
          this.props.setMessage(message);
        })
        .catch(err => {
          console.log(err);
        })
    } else {
      this.props.setMessage({
        body: 'There was an issue with updating your profile. Please try again.',
        messageStyle: 'alert',
        timeout: 5000
      });
    }
  }

  validateState() {
    const {
      first_name,
      last_name,
      email,
    } = this.state;
    return !!first_name && !!last_name && /^\S+@\S+\.\S+$/.test(email);
  }

  render() {
    return (
      <Form data-test="update-user-form" style={{ padding: '20px' }} onSubmit={(e) => this.handleSubmit(e)}>
        <Container>
          <Row>
            <Col md="6">
              <label>First Name:</label>
              <Input
                required
                type="text"
                placeholder="First Name"
                name="first_name"
                value={this.state.first_name}
                onChange={this.handleChange}
              />
            </Col>
            <Col md="6">
              <label>Last Name:</label>
              <Input
                required
                type="text"
                placeholder="Last Name"
                name="last_name"
                value={this.state.last_name}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <label>Email:</label>
              <Input
                required
                type="email"
                placeholder="Email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row className="mui--text-center">
            <Button
              style={primaryButtonStyle}
              data-test="update-user-form-submit-btn"
              variant="raised"
              type="Submit"
              disabled={!this.validateState()}
              className="mui--bg-color-green-500 mui--color-white"
            >
              Update User
                    </Button>
          </Row>
        </Container>
      </Form>
    );
  }
}

MyAccountForm.propTypes = {
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
}

export default MyAccountForm;