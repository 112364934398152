/*
 * helpers.js
 */
import md5 from "md5";
import moment from "moment";
import * as _ from "lodash";

export function getFriendlyRoleName(role) {
  switch (role) {
    case "admin":
      return "Site Admin";
    case "super_admin":
      return "Heartland Admin";
    case "coach":
      return "Coach";
    case "crew_chief":
      return "Crew Chief";
    default:
      return role;
  }
}

export function getFriendlySiteName(site) {
  switch (site) {
    case "austin":
      return "Austin";
    case "north_lawndale":
      return "North Lawndale";
    case "englewood":
      return "Englewood";
    default:
      return site;
  }
}

export function getFriendlySessionType(type) {
  switch (type) {
    case "CAD":
      return "Ctr+Alt+Delete";
    case "PD":
      return "Professional Development";
    case "WC":
      return "Work Crew";
    default:
      return type;
  }
}

export function getFriendlySessionTime(time) {
  switch (time) {
    case "am":
      return "Morning";
    case "pm":
      return "Afternoon";
    default:
      return time;
  }
}

export function getFriendlyDate(date) {
  return moment(date, "YYYYMMDD").format("MM/DD/YY");
}

export function getFriendlyScore(score) {
  const numStr = score.toString();
  if (numStr.length === 1) {
    return numStr + ".0";
  }
  if (numStr.includes(".")) {
    return numStr.slice(0, 3);
  }
  return "n/a";
}

export function getFriendlyPercentage(num) {
  return typeof num === "number" ? Math.round(num * 100) + "%" : num;
}

export function getPrettyID() {
  const md5String = md5(Array.prototype.join.call(arguments, ""));
  let pretty_id = md5String.replace(/[^0-9]/g, "");
  if (pretty_id.length > 10) {
    pretty_id = pretty_id.slice(0, 10);
  } else {
    const zero = "0";
    pretty_id = zero.repeat(10 - pretty_id.length) + pretty_id;
  }
  return pretty_id;
}

export function getUniqueID(
  { first_name, last_name, email, existingIds },
  nonce = 0
) {
  let pretty_id = getPrettyID(first_name, last_name, email, nonce);
  if (existingIds && existingIds.hasOwnProperty(pretty_id)) {
    return getUniqueID(
      {
        first_name,
        last_name,
        email,
        existingIds
      },
      nonce + 1
    );
  }
  return pretty_id;
}

export function toProperCase(str = "") {
  if (!str) return "";
  return str
    .split(" ")
    .map(wrd => {
      return wrd[0] ? wrd[0].toUpperCase() + wrd.slice(1).toLowerCase() : "";
    })
    .join(" ");
}

export function isArrayEqual(x, y) {
  return _(x).xorWith(y, _.isEqual).isEmpty();
}
