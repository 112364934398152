import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Container from 'muicss/lib/react/container';
import Row from 'muicss/lib/react/row';
import Button from 'muicss/lib/react/button';

import PageTitle from '../../components/PageTitle';
import Group from '../Group';
import { getFriendlySessionType } from '../../utiltites/helpers';


class SingleGroupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: null
    }
    this.addFeedbackHandler = this.addFeedbackHandler.bind(this);
    this.getGroupName = this.getGroupName.bind(this);
  }
  componentWillMount() {
    const { match, groups } = this.props;
    const group = groups.find(g => g.group_id === match.params.groupID);
    this.setState({ group });
  }

  getGroupName() {
    const { group } = this.state;
    return group ? ` ${getFriendlySessionType(group.type)} ${moment(group.date, 'YYYYMMDD').format('MM/DD/YYYY')}` : 'Group';
  }

  addFeedbackHandler(e, id, group) {
    e.preventDefault();
    this.props.history.push(`/app/groups/${group.group_id}/participant/${id}`, { group })
  }

  render() {
    const { group } = this.state;
    return (
      <Container >
        <Row style={{ margin: '0 4px' }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          >
            <PageTitle title={this.getGroupName()} />
            <Button
              variant='raised'
              onClick={() => this.props.history.goBack()}
            >
              {'back'}
            </Button>
          </div>
          {
            group &&
            <Group group={group} addFeedbackHandler={this.addFeedbackHandler} />
          }
        </Row>
      </Container >
    )
  }
}

const mapStateToProps = state => ({
  groups: state.groups
})

export default connect(mapStateToProps)(SingleGroupPage);
