import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt, faUser } from '@fortawesome/free-regular-svg-icons';
import Container from 'muicss/lib/react/container';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import Divider from 'muicss/lib/react/divider';
import Button from 'muicss/lib/react/button';
import {
  getFriendlySessionType,
  getFriendlyScore,
} from '../../utiltites/helpers';
import { canDeleteUserGroup } from '../../utiltites/permissions';
import { getScoreStyle } from '../../utiltites/commonStyles';
import { deleteGroup } from '../../api/Groups.Api';
import Modal from '../../components/Modal';

const staffRowStyle = {
  fontFamily: 'DINNextLTPro-Medium',
  fontSize: '1.2rem',
  padding: '8px 0',
  display: 'flex',
  alignItems: 'center',
};

const subHeaderStyle = {
  fontFamily: 'DINNextLTPro-Light',
  fontSize: '1.0rem',
  padding: '8px 0',
};

export default class StaffRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      deleteModalOpen: false,
    };

    this.toggleGroup = this.toggleGroup.bind(this);
    this.renderParticipantSection = this.renderParticipantSection.bind(this);
    this.renderParticipants = this.renderParticipants.bind(this);
    this.handleEditGroup = this.handleEditGroup.bind(this);
  }

  isBeingEdited(id) {
    const { groupToEdit_id } = this.props;
    return id === groupToEdit_id;
  }

  toggleGroup() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  handleEditGroup(e, group) {
    e.preventDefault();
    const editOrStopEditing = this.isBeingEdited(this.props.group.group_id)
      ? null
      : group;
    this.props.editGroup(editOrStopEditing);
  }

  deleteGroup = async () => {
    const { user, group, editGroup } = this.props;
    await deleteGroup({
      groupId: group.group_id,
      staffId: group.staff_id,
      site: user.site,
      date: group.date,
    });
    editGroup(null);
  };

  renderParticipantSection() {
    return this.state.collapsed ? null : (
      <div>
        <Row style={subHeaderStyle}>
          <Col md="4" md-offset="1" xs="5" xs-offset="1">
            Participant Name
          </Col>
          <Col md="3" className="mui--hidden-xs">
            ID
          </Col>
          <Col md="2" xs="3" xs-offset="1">
            Status
          </Col>
        </Row>
        {this.renderParticipants()}
      </div>
    );
  }

  renderParticipants() {
    const participants = this.props.group.participants
      ? Object.values(this.props.group.participants)
      : [];
    return this.state.collapsed
      ? null
      : participants
          .sort((a, b) => (a.last_name > b.last_name ? 1 : -1))
          .map((participant, i) => (
            <div key={participant.participant_id}>
              <Row style={{ ...staffRowStyle, backgroundColor: '#EDEEF1' }}>
                <Col md="1" xs="1">
                  {i + 1}
                </Col>
                <Col
                  md="4"
                  xs="6"
                >{`${participant.last_name}, ${participant.first_name}`}</Col>
                <Col md="4" xs="4" className="mui--hidden-xs">
                  {participant.participant_id}
                </Col>
                <Col md="3" xs="5">
                  {!participant.feedback_id ? (
                    'Not completed'
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '110px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div style={getScoreStyle(participant.session_score)}>
                        {getFriendlyScore(participant.session_score)}
                      </div>
                      {participant.comment ? (
                        <div>
                          <a
                            data-tip
                            data-event="click focus"
                            data-for={`feedback-tooltip-${participant.feedback_id}`}
                            style={{ cursor: 'pointer' }}
                          >
                            <FontAwesomeIcon color="#000" icon={faCommentAlt} />
                          </a>
                          <ReactTooltip
                            id={`feedback-tooltip-${participant.feedback_id}`}
                            place="top"
                            type="dark"
                            effect="solid"
                            globalEventOff="click"
                          >
                            {participant.comment}
                          </ReactTooltip>
                        </div>
                      ) : (
                        <div style={{ display: 'flex' }}>
                          {participant.form_data.attendance !== 3 && (
                            <div>
                              <a
                                data-tip
                                data-event="click focus"
                                data-for={`feedback-tooltip-${participant.feedback_id}`}
                                style={{ cursor: 'pointer' }}
                              >
                                <FontAwesomeIcon
                                  color={
                                    participant.form_data.attendance === 1
                                      ? '#D0021B'
                                      : '#FED82E'
                                  }
                                  icon={faUser}
                                />
                              </a>
                              <ReactTooltip
                                id={`feedback-tooltip-${participant.feedback_id}`}
                                place="top"
                                type="dark"
                                effect="solid"
                                globalEventOff="click"
                              >
                                {participant.form_data.attendance === 1
                                  ? 'Absent'
                                  : 'Excused Absence'}
                              </ReactTooltip>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Divider />
              </Row>
            </div>
          ));
  }

  render() {
    const {
      staff_first_name,
      staff_last_name,
      participants,
      type,
      group_id,
    } = this.props.group;
    const numParticipants = participants
      ? Object.values(participants).length
      : 0;
    const { collapsed, deleteModalOpen } = this.state;
    return (
      <div>
        <Row style={staffRowStyle}>
          <Col md="4" xs="4">{`${staff_last_name}, ${staff_first_name}`}</Col>
          <Col md="4" xs="0" className="mui--hidden-xs">
            {getFriendlySessionType(type)}
          </Col>
          <Col xs="2" className="mui--visible-xs-inline">
            {type}
          </Col>
          <Col md="1" xs="1" className="mui--text-center">
            {numParticipants}
          </Col>
          <Col md="3" xs="3" className="mui--text-center">
            <Row>
              <Col md="12">
                {!this.isBeingEdited(group_id) && (
                  <Button
                    variant="raised"
                    color={collapsed ? 'default' : 'primary'}
                    onClick={this.toggleGroup}
                    size="small"
                  >
                    {collapsed ? 'view' : 'close'}
                  </Button>
                )}
                {this.isBeingEdited(group_id) &&
                  canDeleteUserGroup(this.props.user.role) && (
                    <Button
                      variant="raised"
                      color="danger"
                      onClick={() => this.setState({ deleteModalOpen: true })}
                      size="small"
                    >
                      {'delete'}
                    </Button>
                  )}
                <Button
                  variant="raised"
                  color={this.isBeingEdited(group_id) ? 'primary' : 'default'}
                  onClick={e => this.handleEditGroup(e, this.props.group)}
                  size="small"
                >
                  {this.isBeingEdited(group_id) ? 'cancel' : 'edit'}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Divider />
        </Row>
        {this.renderParticipantSection()}
        <Modal
          show={deleteModalOpen}
          handleClose={() => this.setState({ deleteModalOpen: false })}
        >
          <Container>
            <Row className="mui--text-center">
              <Col xs="12">
                <h2>{`Are you sure you want to delete this group?`}</h2>
              </Col>
            </Row>
            <Row className="mui--text-center">
              <Col md="6" xs="12">
                <Button
                  variant="raised"
                  color="danger"
                  onClick={this.deleteGroup}
                >
                  {'delete'}
                </Button>
              </Col>
              <Col md="6" xs="12">
                <Button
                  variant="raised"
                  onClick={() => this.setState({ deleteModalOpen: false })}
                >
                  {'cancel'}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>
      </div>
    );
  }
}
