/*
*
*   Message
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import Button from 'muicss/lib/react/button';

const alertStyle = {
  backgroundColor: 'rgba(242, 222, 223, 0.9)',
  boxShadow: '0 0 5px 10px rgba(242, 222, 223, 0.9)',
  borderRadius: '5px',
  color: '#966060',
}

const successStyle = {
  backgroundColor: 'rgba(222, 240, 216, 0.9)',
  color: '#658766',
  boxShadow: '0 0 5px 10px rgba(222, 240, 216, 0.9)',
  borderRadius: '5px',
}

const warningStyle = {
  backgroundColor: 'rgba(254, 247, 228, 0.9)',
  color: '#877555',
  boxShadow: '0 0 5px 10px rgba(254, 247, 228, 0.9)',
  borderRadius: '5px',
}

const defaultStyle = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  color: 'black',
  boxShadow: '0 0 5px 10px rgba(255, 255, 255, 0.9)',
  borderRadius: '5px',
}

let fontStyle = {
  fontFamily: 'DINNextLTPro-Medium',
  fontSize: '1.2rem',
  borderRadius: '5px',
  padding: '15px',
}

class Message extends React.Component {
  constructor(props) {
    super(props);

    this.handleDismiss = this.handleDismiss.bind(this);

  }

  componentDidMount() {
    if (this.props.timeout != null) {
      setTimeout(() => {
        this.handleDismiss();
      }, this.props.timeout);
    }
  }

  handleDismiss() {
    const { dismiss } = this.props;
    dismiss();
  }

  render() {
    const { message, messageStyle, dismissable } = this.props;
    let style;

    switch (messageStyle) {
      case 'success':
        style = successStyle;
        fontStyle = { ...fontStyle, backgroundColor: 'rgb(222, 240, 216)' }
        break;
      case 'warning':
        style = warningStyle;
        fontStyle = { ...fontStyle, backgroundColor: 'rgb(254, 247, 228)' }
        break;
      case 'alert':
        style = alertStyle;
        fontStyle = { ...fontStyle, backgroundColor: 'rgb(242, 222, 223)' }
        break;
      default:
        style = defaultStyle;
        fontStyle = { ...fontStyle, backgroundColor: 'rgb(255, 255, 255)' }
    }

    return (
      <div style={style} hidden={!message}>
        <div style={fontStyle}>{message}</div>
        {
          dismissable &&
          <Button onClick={this.handleDismiss} />
        }
      </div>
    )
  }
}

Message.propTypes = {
  message: PropTypes.string,
  style: PropTypes.string,
  timeout: PropTypes.number,
  dismissable: PropTypes.bool,
  dismiss: PropTypes.func,
};

export default Message;