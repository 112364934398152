import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from "./store";
import Layout from "./components/Layout";
import './styles/App.css';

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Layout />
    </PersistGate>
  </Provider>
);
export default App;
