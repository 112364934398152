import React from 'react';
import moment from 'moment';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import ReactTooltip from 'react-tooltip';
import { getFriendlySessionType, toProperCase, getFriendlyScore } from '../../utiltites/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt, faUser } from '@fortawesome/free-regular-svg-icons';
import { getScoreStyle } from '../../utiltites/commonStyles';

const fontStyle = {
  display: 'block',
  fontFamily: 'DINNextLTPro',
  fontSize: '16px',
  color: '#404040',
}

const rowStyle = {
  padding: '8px 0'
}
const ActivityRow = ({
  feedback
}) => {
  const hasComment = feedback && feedback.comment;
  const scoreStyle = getScoreStyle(feedback.session_score);
  return (
    <Row style={rowStyle}>
      <Col md='2' className="mui--hidden-xs">
        <div style={fontStyle}>{moment(feedback.date, 'YYYYMMDD').format('MM/DD/YYYY')}</div>
      </Col>
      <Col xs="2" className="mui--visible-xs-inline">
        <div style={fontStyle}>{moment(feedback.date, 'YYYYMMDD').format('MM/DD')}</div>
      </Col>
      <Col md='4' className="mui--hidden-xs">
        <div style={fontStyle}>{getFriendlySessionType(feedback.type)}</div>
      </Col>
      <Col xs="2" className="mui--visible-xs-inline">
        <div style={fontStyle}>{feedback.type}</div>
      </Col>
      <Col md='3' xs="4">
        <div style={fontStyle}>{toProperCase(feedback.staff_name)}</div>
      </Col>
      <Col md='1' xs="2">
        {
          feedback.attendance_score !== 3
            ? (
              <div>
                <a
                  data-tip
                  data-event="click focus"
                  data-for={`feedback-tooltip-${feedback.feedback_id}`}
                  style={{ cursor: 'pointer', marginLeft: 6 }}
                >
                  <FontAwesomeIcon
                    color={feedback.attendance_score === 1 ? '#D0021B' : '#FED82E'}
                    icon={faUser}
                    style={{ fontSize: 20 }}
                  />
                </a>
                <ReactTooltip
                  id={`feedback-tooltip-${feedback.feedback_id}`}
                  place="top"
                  type="dark"
                  effect="solid"
                  globalEventOff="click"
                >{feedback.attendance_score === 1 ? 'Absent' : 'Excused Absence'}</ReactTooltip>
              </div>

            ) : (

              <div style={scoreStyle}>{getFriendlyScore(feedback.session_score)}</div>
            )
        }
      </Col>
      <Col md='1'>
        {
          hasComment &&
          <div>
            <a
              data-tip
              data-event="click focus"
              data-for={`feedback-tooltip-${feedback.feedback_id}`}
              style={{ cursor: 'pointer' }}
            >
              <FontAwesomeIcon color='#000' icon={faCommentAlt} />
            </a>
            <ReactTooltip
              id={`feedback-tooltip-${feedback.feedback_id}`}
              place="top"
              type="dark"
              effect="solid"
              globalEventOff="click"
            >{feedback.comment}</ReactTooltip>
          </div>

        }
      </Col>
    </Row>
  )
}

export default ActivityRow;
