/**
 *
 * FeedbackSelection Component
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Container from 'muicss/lib/react/container';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';

import FeedbackButton from '../FeedbackButton';
import infoCircleIcon from '../../images/info-circle-solid.svg';
import { mediumBoldTextStyle } from '../../utiltites/commonStyles';

const containerStyle = {
  height: '130px'
}

const flexBoxStyle = {
  display: 'flex',
   justifyContent: 'flex-start',
   alignItems: 'center'
}

const clickableStyle = {
  cursor: 'pointer',
}

const infoIconStyle = {
  WebkitMask: `url(${infoCircleIcon}) no-repeat 50% 50%`,
  mask: `url(${infoCircleIcon}) no-repeat 50% 50%`,
  backgroundColor: '#4F6D7A',
  backgroundSize: 'contain',
  width: '20px',
  height: '20px',
  marginLeft: '16px',
  marginTop: '6px',
}

const FeedbackSelection = ({
  title,
  buttons,
  selectedButton,
  handleClick,
  handleInfoClick,
  stateProp
}) => {
  return buttons.length === 3
    ? (
      <Container data-test='feedback-selection' fluid style={containerStyle}>
        <Row>
          <Col md="6" xs="12" className="mui--align-middle" style={flexBoxStyle}>
              <h3 style={mediumBoldTextStyle}>{title}</h3>
              <a  style={clickableStyle} onClick={handleInfoClick}><div style={infoIconStyle}></div></a>
          </Col>

          {
            buttons.map((button, i) => (
              <Col key={button.title} md="2" xs="4" className="mui--text-center">
                <FeedbackButton
                  data-test='feedback-button'
                  index={i}
                  selected={selectedButton === i}
                  title={button.title}
                  bgColor={button.color}
                  lightBg={button.light}
                  handleClick={handleClick}
                  stateProp={stateProp}
                />
              </Col>
            ))
          }
        </Row>
      </Container>
    ) : null;
}
FeedbackSelection.defaultProps = {
  selectedButton: null
}

FeedbackSelection.propTypes = {
  title: PropTypes.string.isRequired,
  buttons: PropTypes.array,
  selectedButton: PropTypes.number,
  handleClick: PropTypes.func.isRequired,
  handleInfoClick: PropTypes.func.isRequired,
  stateProp: PropTypes.string.isRequired
}
export default FeedbackSelection;