import { combineReducers } from 'redux';
import UserReducer from './UserReducer';
import UsersReducer from './UsersReducer';
import ParticipantsReducer from './ParticipantsReducer';
import GroupsReducer from './GroupsReducer';
import MessageReducer from './MessagesReducer';
import AppReducer from './AppReducer';
import FeedbackDataReducer from './FeedbackDataReducer';
import SiteDataReducer from './SiteDataReducer';

const rootReducer = combineReducers({
  app: AppReducer,
  user: UserReducer,
  users: UsersReducer,
  participants: ParticipantsReducer,
  groups: GroupsReducer,
  message: MessageReducer,
  feedback: FeedbackDataReducer,
  siteData: SiteDataReducer
});

export default rootReducer;

