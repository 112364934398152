import { SET_MESSAGE, DISMISS_MESSAGE } from '../actions/ActionTypes';

const initialState = {
  body: '',
  messageStyle: '',
  timeout: null
};

export default function messageReducer(state = initialState, action) {
  switch (action.type) {

    case SET_MESSAGE:
      return action.payload;

    case DISMISS_MESSAGE:
      return initialState;

    default:
      return state;
  }
}