import ref from '../../db/database';
import { requestSetGroupsListener, clearGroups } from './GroupsActions';
import { listenForGroups, stopListeningToGroups } from '../../api/Groups.Api';

export function fetchGroups(service = ref) {
  return (dispatch, getState) => {
    const { user } = getState();
    const { uid, site } = user;

    dispatch(requestSetGroupsListener())
    return listenForGroups({ uid, site, dispatch }, service)
  }
}

export function removeGroups(service = ref) {
  return (dispatch, getState) => {
    const { user } = getState();
    const { uid, site } = user;

    dispatch(clearGroups())
    return stopListeningToGroups({ uid, site }, service)
  }
}