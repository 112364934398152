export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_USER = 'ADD_USERS';
export const SET_USERS = 'SET_USERS';
export const GET_USERS = 'GET_USERS';
export const REMOVE_USER = 'REMOVE_USER';
export const CLEAR_USERS = 'CLEAR_USERS';
export const SET_USERS_LISTENER = 'SET_USERS_LISTENER';
export const GET_PARTICIPANTS = 'GET_PARTICIPANTS';
export const SET_PARTICIPANTS = 'SET_PARTICIPANTS';
export const SET_GROUPS = 'SET_GROUPS';
export const SET_GROUPS_LISTENER = 'SET_GROUPS_LISTENER';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const CLEAR_STATE = 'CLEAR_STATE';
export const TOGGLE_CREATE_GROUP = 'TOGGLE_CREATE_GROUP';
export const TOGGLE_FORGOT_PASSWORD = 'TOGGLE_FORGOT_PASSWORD';
export const SET_MESSAGE = 'SET_MESSAGE';
export const DISMISS_MESSAGE = 'DISMISS_MESSAGE';
export const SET_PARTICIPANTS_LISTENERS = 'SET_PARTICIPANTS_LISTENERS';
export const UPDATE_PARTICIPANT = 'UPDATE_PARTICIPANT';
export const REMOVE_PARTICIPANT = 'REMOVE_PARTICIPANT';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const ADD_GROUP = 'ADD_GROUP';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const CLEAR_PARTICIPANTS = 'CLEAR_PARTICIPANTS';
export const CLEAR_GROUPS = 'CLEAR_GROUPS';
export const TOGGLE_FEEDBACK_INFO = 'TOGGLE_FEEDBACK_INFO';
export const TOGGLE_REMOVE_PARTICIPANT = 'TOGGLE_REMOVE_PARTICIPANT';
export const TOGGLE_CREATE_PARTICIPANT = 'TOGGLE_CREATE_PARTICIPANT';
export const SET_FEEDBACK_FORMS = 'SET_FEEDBACK_FORMS';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const CLEAR_FEEDBACK_FORMS = 'CLEAR_FEEDBACK_FORMS';
export const TOGGLE_UPDATE_PARTICIPANT = 'TOGGLE_UPDATE_PARTICIPANT';
export const SET_SITE = 'SET_SITE';
export const TOGGLE_CHANGE_SITE = 'TOGGLE_CHANGE_SITE';
export const SET_CAD_ATTENDANCE = 'SET_CAD_ATTENDANCE';
export const SET_PD_ATTENDANCE = 'SET_PD_ATTENDANCE';
export const SET_WC_ATTENDANCE = 'SET_WC_ATTENDANCE';
export const SET_SCORE_AVERAGE = 'SET_SCORE_AVERAGE';
export const SET_CAD_HOURS_AVERAGE = 'SET_CAD_HOURS_AVERAGE';
export const SET_STAFF_GROUPS = 'SET_STAFF_GROUPS';
export const CLEAR_STAFF_GROUPS = 'CLEAR_STAFF_GROUPS';
export const TOGGLE_CREATE_STAFF = 'TOGGLE_CREATE_STAFF';
export const TOGGLE_UPDATE_STAFF = 'TOGGLE_UPDATE_STAFF';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const TOGGLE_DOWNLOAD_SUMMARY = 'TOGGLE_DOWNLOAD_SUMMARY';
export const TOGGLE_DATA_EXPORT = 'TOGGLE_DATA_EXPORT';
