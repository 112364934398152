/*
 *
 *   ParticipantList
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Container from 'muicss/lib/react/container';
import Panel from 'muicss/lib/react/panel';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import Divider from 'muicss/lib/react/divider';
import Input from 'muicss/lib/react/input';
import Button from 'muicss/lib/react/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';

const panelStyle = {
  padding: 0,
};

const chevronIconStyle = {
  fontSize: '1.4rem',
  color: 'white',
  display: 'flex',
};

const headerStyle = {
  backgroundColor: '#EDEEF1',
  margin: 0,
  fontFamily: 'DINNextLTPro-Bold',
  fontSize: '1.6rem',
  padding: '16px 0',
};

const subHeaderStyle = {
  fontFamily: 'DINNextLTPro-Light',
  fontSize: '1.2rem',
  padding: '12px 0',
};

const participantRowStyle = {
  fontFamily: 'DINNextLTPro-Medium',
  fontSize: '1.2rem',
  padding: '8px 0',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
};

export class ParticipantList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      particpants: [],
      filteredParticipants: [],
      search: '',
      page: 1,
      numPerPage: 20,
    };
    this.onHandleSearchChange = this.onHandleSearchChange.bind(this);
    this.renderParticipant = this.renderParticipant.bind(this);
    this.onParticipantSeletion = this.onParticipantSeletion.bind(this);
    this.decrementPage = this.decrementPage.bind(this);
    this.incrementPage = this.incrementPage.bind(this);
  }

  onHandleSearchChange(e) {
    const { participants } = this.props;
    const searchInput = e.target.value;
    if (searchInput === '') {
      this.setState({
        filteredParticipants: [],
        search: '',
      });
    } else {
      const userSearch = searchInput.replace(/[^\w\s]+/gi, '');
      const regexp = new RegExp(`^${userSearch}`, 'i');
      const filteredData = participants.filter(entry => {
        return (
          regexp.test(entry.last_name) ||
          regexp.test(entry.first_name) ||
          regexp.test(entry.pretty_id)
        );
      });
      this.setState({
        filteredParticipants: filteredData,
        search: searchInput,
      });
    }
  }

  onParticipantSeletion(identifier) {
    this.props.history.push(`/app/participants/${identifier}`);
  }

  getTotalPages() {
    const { participants } = this.props;
    const { numPerPage } = this.state;
    return Math.ceil(participants.length / numPerPage);
  }

  getPageText() {
    return `Page ${this.state.page} of ${this.getTotalPages()}`;
  }

  decrementPage() {
    const { page } = this.state;
    if (page > 1) {
      this.setState({ page: page - 1 });
    }
  }

  incrementPage() {
    const { page } = this.state;
    if (page < this.getTotalPages()) {
      this.setState({ page: page + 1 });
    }
  }

  renderList(participants) {
    const { numPerPage, page } = this.state;
    const end = page * numPerPage;
    const start = end - numPerPage;
    if (participants.length <= numPerPage) {
      return participants.map(participant =>
        this.renderParticipant(participant)
      );
    } else {
      return participants
        .slice(start, end)
        .map(participant => this.renderParticipant(participant));
    }
  }

  renderParticipant(participant) {
    const name = `${participant.last_name}, ${participant.first_name}`;
    const identifier = participant.pretty_id;
    return (
      <div data-test="participant-list-participant-row" key={identifier}>
        <Row
          style={participantRowStyle}
          onClick={() => this.onParticipantSeletion(identifier)}
        >
          <Col md="4" xs="8">
            {name}
          </Col>
          <Col md="4" className="mui--hidden-xs">
            {identifier}
          </Col>
          <Col
            md="2"
            md-offset="2"
            xs="2"
            xs-offset="2"
            className="mui--text-right"
          >
            <FontAwesomeIcon color="#000" icon={faChevronRight} />
          </Col>
        </Row>
        <Row>
          <Divider />
        </Row>
      </div>
    );
  }

  render() {
    const { headerTitle, participants } = this.props;
    const { filteredParticipants, search } = this.state;
    const currentParticipantList =
      search.length > 0 ? filteredParticipants : participants;

    return (
      <div>
        <Panel data-test="participant-list" style={panelStyle}>
          <Row style={headerStyle} className="mui--align-left">
            <Col md="6" xs="12" data-test="participant-list-header-title">
              {headerTitle}
            </Col>
            <Col md="6" xs="12">
              <Input
                name="search"
                placeholder="Search"
                data-test="participant-list-search-input"
                onChange={this.onHandleSearchChange}
              />
            </Col>
          </Row>
          <Container>
            <Row style={subHeaderStyle} className="mui--align-left">
              <Col md="4" xs="6">
                Name
              </Col>
              <Col md="4" className="mui--hidden-xs">
                ID
              </Col>
              <Col
                md="2"
                md-offset="2"
                xs="2"
                xs-offset="3"
                className="mui--text-right"
              >
                Details
              </Col>
            </Row>
            <Row>
              <Divider />
            </Row>
            {!!currentParticipantList.length &&
              this.renderList(currentParticipantList)}
          </Container>
        </Panel>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            data-test="dec-button"
            style={{ margin: '0 12px' }}
            color="primary"
            size="small"
            variant="fab"
            onClick={this.decrementPage}
          >
            <FontAwesomeIcon
              color="#FFF"
              icon={faChevronLeft}
              style={chevronIconStyle}
            />
          </Button>
          <h2 style={{ textAlign: 'center', margin: 0 }}>
            {this.getPageText()}
          </h2>
          <Button
            data-test="inc-button"
            style={{ margin: '0 12px' }}
            color="primary"
            size="small"
            variant="fab"
            onClick={this.incrementPage}
          >
            <FontAwesomeIcon
              color="#FFF"
              icon={faChevronRight}
              style={chevronIconStyle}
            />
          </Button>
        </div>
      </div>
    );
  }
}

ParticipantList.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  participants: PropTypes.array.isRequired,
};

export default withRouter(connect()(ParticipantList));
