import {
  SET_CAD_ATTENDANCE,
  SET_PD_ATTENDANCE,
  SET_WC_ATTENDANCE,
  SET_SCORE_AVERAGE,
  SET_CAD_HOURS_AVERAGE,
  SET_STAFF_GROUPS,
  CLEAR_STATE,
  CLEAR_STAFF_GROUPS,
} from '../actions/ActionTypes';

const initialState = {
  cadAttendance: 0,
  pdAttendance: 0,
  wcAttendance: 0,
  scoreAverage: 0,
  cadHoursAverage: 0,
  staffGroups: {},
};

export default function siteDataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CAD_ATTENDANCE:
      return { ...state, cadAttendance: action.payload };

    case SET_PD_ATTENDANCE:
      return { ...state, pdAttendance: action.payload };

    case SET_WC_ATTENDANCE:
      return { ...state, wcAttendance: action.payload };

    case SET_SCORE_AVERAGE:
      return { ...state, scoreAverage: action.payload };

    case SET_CAD_HOURS_AVERAGE:
      return { ...state, cadHoursAverage: action.payload };

    case SET_STAFF_GROUPS:
      return {
        ...state,
        staffGroups: {
          ...state.staffGroups,
          [action.payload.uid]: action.payload.groups,
        },
      };

    case CLEAR_STAFF_GROUPS:
      return {
        ...state,
        staffGroups: {},
      };

    case CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}
