import { 
    ADD_USER,
    UPDATE_USER,
    REMOVE_USER,
    SET_USERS,
    CLEAR_STATE } from '../actions/ActionTypes';
 
 const initialState = [];
 
 export default function usersReducer(state = initialState, action) {
   switch (action.type) {
 
     case ADD_USER:
       return [...state, action.payload];
 
     case UPDATE_USER:
       return state.map(user => {
         if (user.uid === action.payload.uid) {
           return action.payload
         }
         return user;
       });
 
     case REMOVE_USER:
       return state.filter(user => {
         return user.uid !== action.payload.uid;
       });
 
     case SET_USERS:
       return action.payload;
       
     case CLEAR_STATE:
       return initialState;
 
     default:
       return state;
   }
 }