import { SET_PARTICIPANTS, UPDATE_PARTICIPANT, CLEAR_STATE, REMOVE_PARTICIPANT, SET_PARTICIPANTS_LISTENERS } from '../actions/ActionTypes';

const initialState = {
};

export default function participantsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PARTICIPANTS:
      return action.payload;

    case UPDATE_PARTICIPANT:
      return { ...state, [action.payload.pretty_id]: action.payload }

    case REMOVE_PARTICIPANT: {
      const newState = { ...state };
      delete newState[action.payload.pretty_id];
      return newState;
    }

    case SET_PARTICIPANTS_LISTENERS:
      return state;

    case CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}