import React from 'react'
import { withRouter } from 'react-router-dom'
// import Button from 'muicss/lib/react/button';

import Group from '../Group';

const GroupsList = ({ groups, toggleCreateGroupModal, history }) => {

  const addFeedbackHandler = (event, id, group) => {
    event.preventDefault();
    history.push(`/app/groups/${group.group_id}/participant/${id}`, { group })
  }

  return (
    <div data-test="groups-list">
      {
        groups && groups.map(group => (
          <Group
            key={group.group_id}
            group={group}
            addFeedbackHandler={addFeedbackHandler}
          />
        ))
      }
    </div>
  )
}


export default withRouter(GroupsList);
