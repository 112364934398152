/**
 *
 * Sidebar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SidebarButton from '../SidebarButton';
import { canSeeStaffPage } from '../../utiltites/permissions';
import { logoutUser } from '../../actions/user/UserActionCreators';
import { toggleMenu } from '../../actions/appState/AppActions';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      items: this.getDefaultItems(),
    };

    this.handleLogout = this.handleLogout.bind(this);
    this.handleAccount = this.handleAccount.bind(this);
    this.handleItemSelection = this.handleItemSelection.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
  }

  componentWillMount() {
    const newState = {
      items: this.getDefaultItems()
    };
    if (
      this.props.role &&
      canSeeStaffPage(this.props.role)
    ) {
      newState.items.push({ title: 'Staff', path: '/app/staff' });
      newState.items.push({ title: 'Staff Groups', path: '/app/staff_groups' });
    }
    newState.selectedIndex = newState.items.findIndex(item => item.path === this.props.location.pathname);
    if (newState.selectedIndex === -1) {
      newState.selectedIndex = 0;
    }
    this.setState(newState);
  }

  getDefaultItems() {
    return [
      { title: 'Home', path: '/app' },
      { title: 'My Groups', path: '/app/groups' },
      { title: 'Participants', path: '/app/participants' },
    ];
  }

  handleItemSelection(index) {
    const { history, menuOpen, dispatch } = this.props;
    const { path } = this.state.items[index];
    this.setState({
      selectedIndex: index,
    });
    history.push(path);
    if (menuOpen) {
      dispatch(toggleMenu())
    }
  }

  handleLogout() {
    this.props.dispatch(logoutUser());
    if (this.props.menuOpen) {
      this.props.dispatch(toggleMenu())
    }
  }

  handleAccount() {
    this.props.history.push('/app/myaccount');
    if (this.props.menuOpen) {
      this.props.dispatch(toggleMenu())
    }
  }

  checkPath(path) {
    const { pathname } = this.props.location;
    let name = '';
    let nameArray = pathname.split('/');
    nameArray.shift();
    nameArray.forEach((n, i) => {
      if (i !== 2) {
        name += `/${n}`;
      }
    })
    return name === path;
  }

  renderButtons() {
    return (
      <div
        className='mui--no-user-select'
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        {
          this.state.items.map((item, index) => (
            <SidebarButton
              key={item.title}
              handleClick={() => this.handleItemSelection(index)}
              title={item.title}
              selected={this.checkPath(item.path)}
              index={index}
            />
          ))
        }
      </div>
    )
  }

  render() {
    const { menuOpen, location } = this.props;
    const menuStyle = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }
    if (menuOpen) {
      menuStyle.transform = 'translateX(200px)';
    }
    return this.props.userLoggedIn ? (
      <div id="sidedrawer" style={menuStyle}>
        {this.renderButtons()}
        <div
          className='mui--no-user-select'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <SidebarButton
            key="myaccount"
            handleClick={this.handleAccount}
            title="My Account"
            selected={location.pathname === '/app/myaccount'}
          />
          <SidebarButton
            key="logout"
            handleClick={this.handleLogout}
            title="Logout"
            selected={false}
          />
          <div style={{ marginBottom: '30%' }} />
        </div>
      </div>
    ) : null;
  }
}

Sidebar.propTypes = {
  style: PropTypes.object,
  selected: PropTypes.number,
  items: PropTypes.array,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  menuOpen: state.app.menuOpen,
  userLoggedIn: state.user.email !== '',
  role: state.user.role
})

export default withRouter(connect(mapStateToProps)(Sidebar));
