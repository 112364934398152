import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Input from 'muicss/lib/react/input';
import Col from 'muicss/lib/react/col';
import Row from 'muicss/lib/react/row';
import Panel from 'muicss/lib/react/panel';
import { getAllActiveUsers } from '../../selectors/UsersSelectors';

const fontStyle = {
  textTransform: 'none',
  fontFamily: 'DINNextLTPro',
  fontSize: '1.2rem',
};

export class UserTypeahead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: `${props.user.lastName}, ${props.user.firstName}`,
      filteredData: [],
    };
    this.renderFilteredData = this.renderFilteredData.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.input = null;
  }

  componentDidMount() {
    if (this.input) {
      this.input.controlEl.autocomplete = 'off';
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.clearInput && nextProps.clearInput) {
      const { lastName, firstName } = nextProps.user;
      this.setState({
        inputValue: `${lastName}, ${firstName}`,
        filteredData: [],
      });
    }
  }

  onHandleChange(e) {
    const { users } = this.props;
    const inputValue = e.target.value;
    if (inputValue === '') {
      this.setState({
        filteredData: [],
        inputValue: '',
      });
    } else {
      const userSearch = inputValue.replace(/[^\w\s]+/gi, '');
      const regexp = new RegExp(`^${userSearch}`, 'i');
      const filteredData = users.filter(entry => {
        return (
          regexp.test(entry.last_name) ||
          regexp.test(entry.first_name) ||
          regexp.test(entry.email)
        );
      });
      this.setState({
        filteredData,
        inputValue,
      });
    }
  }

  handleSelect(e, user) {
    e.preventDefault();
    this.props.onSelect(user);
    this.setState({
      filteredData: [],
      inputValue: `${user.last_name}, ${user.first_name}`,
    });
  }

  renderFilteredData() {
    return this.state.filteredData.slice(0, 5).map((user, index) => (
      <Row
        key={user.uid}
        style={{
          display: 'flex',
          backgroundColor: index % 2 ? 'white' : '#F0F0F0',
          textAlign: 'left',
          marginLeft: 0,
          marginRight: 0,
          height: 40,
          alignItems: 'center',
          padding: 0,
          cursor: 'pointer',
        }}
      >
        <Col
          md="12"
          style={fontStyle}
          onClick={e => this.handleSelect(e, user)}
        >
          <div>{`${user.last_name}, ${user.first_name}`}</div>
        </Col>
      </Row>
    ));
  }

  render() {
    return (
      <Col md="12">
        <Row>
          <Input
            type="text"
            label="Search for staff"
            floatingLabel
            name="search"
            value={this.state.inputValue}
            onChange={this.onHandleChange}
            ref={el => {
              this.input = el;
            }}
          />
        </Row>
        {!!this.state.filteredData.length && (
          <Row style={{ position: 'relative' }}>
            <Panel
              style={{
                padding: 0,
                position: 'absolute',
                zIndex: 10,
                width: '100%',
              }}
            >
              {this.renderFilteredData()}
            </Panel>
          </Row>
        )}
      </Col>
    );
  }
}

UserTypeahead.propTypes = {
  users: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  users: getAllActiveUsers(state),
  user: state.user,
});

export default connect(mapStateToProps)(UserTypeahead);
