import React from "react";
import moment from "moment";
import DownloadWeeklySummary from "../DownloadWeeklySummary";
import Container from "muicss/lib/react/container";
import Row from "muicss/lib/react/row";

const DownloadSummaryModalContent = ({ summaryData, close }) => {
  const { feedbackData, participant, end, open, selectedDate } = summaryData;

  const getStartDate = () => {
    return moment(selectedDate, "YYYYMMDD").format("MM/DD/YYYY");
  };

  const getEndDate = () => {
    return end
      ? moment(end, "YYYYMMDD").format("MM/DD/YYYY")
      : moment().format("MM/DD/YYYY");
  };

  const getDate = () => {
    return `${getStartDate()} - ${getEndDate()}`;
  };
  return (
    <Container>
      {open && (
        <div>
          <Row className="mui--text-center">
            <h2>{`Download summary for ${participant.first_name} ${participant.last_name}?`}</h2>
            <h2>{getDate()}</h2>
          </Row>
          <Row className="mui--text-center">
            <DownloadWeeklySummary
              feedbackData={feedbackData}
              participant={participant}
              close={close}
              startDate={getStartDate()}
              endDate={getEndDate()}
            />
          </Row>
        </div>
      )}
    </Container>
  );
};

export default DownloadSummaryModalContent;
