import React, { Component } from 'react'
import { connect } from 'react-redux'
import Form from 'muicss/lib/react/form';
import Container from 'muicss/lib/react/container';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import Button from 'muicss/lib/react/button';
import Input from 'muicss/lib/react/input';
import store from '../../store';
import { passwordReset } from '../../actions/user/UserActionCreators';
import { setMessage } from '../../actions/messages/MessageActions';
import { toggleForgotPasswordModal } from '../../actions/appState/AppActions';

const titleStyle = {
  fontFamily: 'DINNextLTPro-Bold',
  fontSize: '1.2rem'
}

const subTitleStyle = {
  fontFamily: 'DINNextLTPro-Medium',
  fontSize: '1.1rem'
}

const buttonStyle = {
  backgroundColor: '#3CB432',
  color: 'white',
  margin: '16px'
}

export class ForgotPasswordModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmit(event) {
    event.preventDefault();
    const { email } = this.state;
    const dispatch = store.dispatch;

    dispatch(passwordReset(email))
      .then(() => {
        dispatch(toggleForgotPasswordModal(false));
        dispatch(setMessage({
          body: 'Password reset email has been sent. Follow the instructions there to finish resetting your password.',
          messageStyle: 'success',
          timeout: 5000
        }));

      })
      .catch((error) => {
        console.log(error);
        dispatch(setMessage({
          body: 'Reset password service is currently not available. Please try again later',
          messageStyle: 'alert',
          timeout: 5000
        }));
      });
  }

  render() {
    return (
      <Form onSubmit={this.onSubmit} style={{ margin: '20px' }}>
        <Container>
          <Row className="mui--text-center">
            <Col xs="12">
              <div >
                <p style={titleStyle}>Forgot your password?</p>
                <p style={subTitleStyle}>Enter your email address to recover your account.</p>
              </div>
            </Col>
          </Row>
          <Row className="mui--text-center">
            <Col xs="12">
              <Input type='email' name="email" required placeholder={'Email address'} onChange={this.handleChange}></Input>
            </Col>
          </Row>
          <Row className="mui--text-center">
            <Col xs="12">
              <Button variant="raised" type="submit" style={buttonStyle}>Submit</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    )
  }
}

export default connect()(ForgotPasswordModalContent)
