/*
*
*   MessageController
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Message from '../../components/Message';
import { dismissMessage } from '../../actions/messages/MessageActions';

const messageWrapperStyle = {
  position: 'fixed',
  zIndex: 10,
  top: '100px',
  width: '90%',
  left: 0,
  right: 0,
  marginLeft: 'auto',
  marginRight: 'auto'
}

export const MessageController = ({ message, dismissMessage }) => {
  return (
    <div data-test="message-controller" style={messageWrapperStyle}>

      {
        message && !!message.body &&
        <Message
          message={message.body}
          messageStyle={message.messageStyle}
          timeout={message.timeout}
          dissmissable={message.dissmissable}
          dismiss={dismissMessage}
        />
      }
    </div>
  );
}

MessageController.propTypes = {
  message: PropTypes.shape({
    body: PropTypes.string.isRequired,
    messageStyle: PropTypes.string,
    timeout: PropTypes.number,

  }),
  dismissMessage: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  message: state.message,
})

const mapDispatchToProps = dispatch => ({
  dismissMessage: () => dispatch(dismissMessage())
})

export default connect(mapStateToProps, mapDispatchToProps)(MessageController)
