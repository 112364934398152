import moment from 'moment';
import ref from '../db/database';
import {
  SET_CAD_ATTENDANCE,
  SET_PD_ATTENDANCE,
  SET_WC_ATTENDANCE,
  SET_SCORE_AVERAGE,
  SET_CAD_HOURS_AVERAGE
} from '../actions/ActionTypes';

export function listenForFeedback(
  { site, siteData, dispatch, end, day = moment().format('YYYYMMDD') },
  service = ref
) {
  const feedbackRef = end
    ? service(`feedback/${site}`)
        .orderByChild('date')
        .startAt(day)
        .endAt(end)
    : service(`feedback/${site}`)
        .orderByChild('date')
        .equalTo(day);
  return feedbackRef.on('value', snapshot => {
    const feedback = snapshot.exists() ? Object.values(snapshot.val()) : [];
    const newSiteData = feedback.reduce(
      (data, form) => {
        data[form.type].forms.push(form);
        if (form.attendance_score === 3) {
          data[form.type].attendance += 1;
          data.scoreTotal += form.session_score;
          data.scoreAverage =
            data.scoreTotal /
            (data.CAD.attendance + data.PD.attendance + data.WC.attendance);
          if (isNaN(data.scoreAverage)) {
            data.scoreAverage = 0;
          }
          let formHours = form.hours ? form.hours : 0;
          formHours += form.minutes ? form.minutes / 60 : 0;
          data.cadHoursTotal += formHours;
          data.cadHoursAverage = data.cadHoursTotal / data.CAD.attendance;
          if (isNaN(data.cadHoursAverage)) {
            data.cadHoursAverage = 0;
          }
        }
        data[form.type].attendanceRate =
          data[form.type].attendance / data[form.type].forms.length;
        if (isNaN(data[form.type].attendanceRate)) {
          data[form.type].attendanceRate = 0;
        }
        return data;
      },
      {
        CAD: { forms: [], attendance: 0, attendanceRate: 0 },
        PD: { forms: [], attendance: 0, attendanceRate: 0 },
        WC: { forms: [], attendance: 0, attendanceRate: 0 },
        scoreTotal: 0,
        scoreAverage: 0,
        cadHoursTotal: 0,
        cadHoursAverage: 0
      }
    );

    if (siteData.cadAttendance !== newSiteData.CAD.attendanceRate) {
      dispatch({
        type: SET_CAD_ATTENDANCE,
        payload: newSiteData.CAD.attendanceRate
      });
    }
    if (siteData.pdAttendance !== newSiteData.PD.attendanceRate) {
      dispatch({
        type: SET_PD_ATTENDANCE,
        payload: newSiteData.PD.attendanceRate
      });
    }
    if (siteData.wcAttendance !== newSiteData.WC.attendanceRate) {
      dispatch({
        type: SET_WC_ATTENDANCE,
        payload: newSiteData.WC.attendanceRate
      });
    }
    if (siteData.scoreAverage !== newSiteData.scoreAverage) {
      dispatch({ type: SET_SCORE_AVERAGE, payload: newSiteData.scoreAverage });
    }
    if (siteData.cadHoursAverage !== newSiteData.cadHoursAverage) {
      dispatch({
        type: SET_CAD_HOURS_AVERAGE,
        payload: newSiteData.cadHoursAverage
      });
    }
  });
}

export function stopListeningToFeedback({ site }, service = ref) {
  service(`feedback/${site}`).off();
}
