// import { fromJS } from "immutable";
import { SET_USER, SET_SITE, UPDATE_CURRENT_USER } from "../ActionTypes";

export function setUser({ first_name, last_name, email, site, role, uid }) {
  return {
    type: SET_USER,
    payload: {
      firstName: first_name,
      lastName: last_name,
      email,
      role,
      site,
      uid,
    }
  };
}

export function setSite(site) {
  return {
    type: SET_SITE,
    payload: site
  }
}

export function updateUserRedux({ first_name, last_name, email }) {
  return {
    type: UPDATE_CURRENT_USER,
    firstName: first_name,
    lastName: last_name,
    email
  }
}