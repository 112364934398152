import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Container from 'muicss/lib/react/container';
import Panel from 'muicss/lib/react/panel';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import Button from 'muicss/lib/react/button';
import Divider from 'muicss/lib/react/divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCommentAlt,
  faUser,
  faEdit,
} from '@fortawesome/free-regular-svg-icons';
import { getScoreStyle } from '../../utiltites/commonStyles';

import {
  getFriendlyDate,
  getFriendlySessionType,
  getFriendlyScore,
} from '../../utiltites/helpers';

const headerStyle = {
  backgroundColor: '#EDEEF1',
  fontFamily: 'DINNextLTPro-Bold',
  fontSize: '1.6rem',
  padding: '20px 0',
  margin: 0,
  boxSizing: 'border-box',
  minHeight: 80,
  color: '#000000',
  textDecoration: 'none',
};

const subHeaderStyle = {
  fontFamily: 'DINNextLTPro-Light',
  fontSize: '1.2rem',
  padding: '12px 0',
};

const listPanelStyle = {
  padding: 0,
};

const rowStyle = {
  fontFamily: 'DINNextLTPro-Medium',
  fontSize: '1.2rem',
  display: 'flex',
  alignItems: 'center',
  minHeight: 60,
};

const Group = ({ group, addFeedbackHandler, handleEditGroup, history }) => {
  const today = moment().format('YYYYMMDD');

  const onEditClick = (e, group) => {
    e.preventDefault();
    handleEditGroup(group);
  };

  const renderParticipants = group => {
    if (!group.participants) {
      return null;
    }
    const arrayOfParticipants = Object.keys(group.participants);
    const renderedParticipants = arrayOfParticipants.map((id, i) => {
      const participant = group.participants[id];
      const scoreStyle = getScoreStyle(participant.session_score);
      const hasFeedback = participant && !!participant.feedback_id;
      const hasComment = participant && participant.comment;
      return group &&
        group.participants &&
        Object.keys(group.participants).length ? (
        <div key={id} data-test="participant">
          <Row style={rowStyle}>
            <Col md="4" xs="8" className="mui--align-middle">
              {participant.first_name + ' ' + participant.last_name}
            </Col>
            <Col md="6" xs="8" className="mui--hidden-xs">
              {participant.participant_id}
            </Col>
            {/* If display Do Feedback or Icons */}
            {hasFeedback ? (
              <Col
                md="2"
                xs="4"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {participant.form_data.attendance !== 3 ? (
                  <Col
                    md="4"
                    style={{
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <a
                      data-tip
                      data-event="click focus"
                      data-for={`feedback-tooltip-${participant.feedback_id}`}
                      style={{ cursor: 'pointer', marginTop: 4 }}
                    >
                      <FontAwesomeIcon
                        color={
                          participant.form_data.attendance === 1
                            ? '#D0021B'
                            : '#FED82E'
                        }
                        icon={faUser}
                        style={{ fontSize: 20 }}
                      />
                    </a>
                    <ReactTooltip
                      id={`feedback-tooltip-${participant.feedback_id}`}
                      place="top"
                      type="dark"
                      effect="solid"
                      globalEventOff="click"
                    >
                      {participant.form_data.attendance === 1
                        ? 'Absent'
                        : 'Excused Absence'}
                    </ReactTooltip>
                  </Col>
                ) : (
                  <Col
                    md="4"
                    style={{
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <div style={scoreStyle}>
                      {getFriendlyScore(participant.session_score)}
                    </div>
                  </Col>
                )}
                <Col
                  md="4"
                  style={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  {hasComment && (
                    <div style={{ marginTop: 6, marginLeft: 5 }}>
                      <a
                        data-tip
                        data-event="click focus"
                        data-for={`feedback-tooltip-${participant.feedback_id}`}
                        style={{ cursor: 'pointer' }}
                      >
                        <FontAwesomeIcon color="#000" icon={faCommentAlt} />
                      </a>
                      <ReactTooltip
                        id={`feedback-tooltip-${participant.feedback_id}`}
                        place="top"
                        type="dark"
                        effect="solid"
                        globalEventOff="click"
                      >
                        {participant.comment}
                      </ReactTooltip>
                    </div>
                  )}
                </Col>
                <Col
                  md="4"
                  style={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <Link
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: 2,
                    }}
                    to={{
                      pathname: `/app/groups/${group.group_id}/participant/${participant.participant_id}`,
                      state: {
                        group,
                        form_data: participant.form_data,
                      },
                    }}
                  >
                    <FontAwesomeIcon color="#000" icon={faEdit} />
                  </Link>
                </Col>
              </Col>
            ) : (
              <Col md="2" xs="4">
                <Button
                  variant="raised"
                  size="small"
                  onClick={event => addFeedbackHandler(event, id, group)}
                >
                  {'Feedback'}
                </Button>
              </Col>
            )}
          </Row>
          {i !== arrayOfParticipants.length - 1 && <Divider />}
        </div>
      ) : null;
    });
    return renderedParticipants;
  };

  return group ? (
    <Panel data-test="group" style={listPanelStyle}>
      {group.date === today && handleEditGroup != null ? (
        <Row style={headerStyle}>
          <Col md="4" xs="8">
            {getFriendlyDate(group.date)}
          </Col>
          <Col md="6" xs="8" className="mui--align-middle">
            {getFriendlySessionType(group.type)}
          </Col>
          <Col md="2" xs="4">
            <Button
              variant="raised"
              style={{ margin: 0 }}
              onClick={e => onEditClick(e, group)}
            >
              {'edit'}
            </Button>
          </Col>
        </Row>
      ) : (
        <Link
          style={{ textDecoration: 'none' }}
          to={`/app/groups/${group.group_id}`}
        >
          <Row style={headerStyle}>
            <Col md="4" xs="8">
              {getFriendlyDate(group.date)}
            </Col>
            <Col md="6" xs="8" className="mui--align-middle">
              {getFriendlySessionType(group.type)}
            </Col>
          </Row>
        </Link>
      )}
      <Container>
        <Row style={subHeaderStyle}>
          <Col md="4" xs="8">
            Name
          </Col>
          <Col md="6" xs="8" className="mui--hidden-xs">
            ID Number
          </Col>
          <Col md="2" xs="4">
            Feedback
          </Col>
        </Row>
        <Divider />
        {/* Participant Rows */}

        {renderParticipants(group)}
      </Container>
    </Panel>
  ) : null;
};

export default Group;
