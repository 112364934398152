import React from 'react'
import { connect } from 'react-redux'
import Container from 'muicss/lib/react/container';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import Option from 'muicss/lib/react/option';
import Select from 'muicss/lib/react/select';
import { toggleChangeSiteModal } from '../../actions/appState/AppActions';
import { changeSite } from '../../actions/user/UserActionCreators';

export const ChangeSiteModalContent = ({ user, changeSite, closeModal }) => {

  const handleChange = (e) => {
    e.preventDefault();
    changeSite(e.target.value);
    closeModal();
  }

  return (
    <Container>
      <Col md='12'>
        <Row style={{ padding: 20 }}>
          <Select label='Change site:' name="site" value={user.site} onChange={handleChange}>
            <Option value="austin" label='Austin' />
            <Option value="englewood" label='Englewood' />
            <Option value="north_lawndale" label='North Lawndale' />
          </Select>
        </Row>
      </Col>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  changeSite: (site) => dispatch(changeSite(site)),
  closeModal: () => dispatch(toggleChangeSiteModal(false))
})


export default connect(mapStateToProps, mapDispatchToProps)(ChangeSiteModalContent)
