import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Container from 'muicss/lib/react/container';
import Panel from 'muicss/lib/react/panel';
import Divider from 'muicss/lib/react/divider';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import Button from 'muicss/lib/react/button';
import PageTitle from '../../components/PageTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { getFriendlySiteName } from '../../utiltites/helpers';
import {
  listenForStaffGroups,
  stopListeningForStaffGroups,
} from '../../api/Groups.Api';
import StaffRow from './StaffRow';
import CreateGroup from '../../components/CreateGroup';

const chevronIconStyle = {
  fontSize: '1.4rem',
  color: 'white',
  display: 'flex',
};

const panelStyle = {
  padding: 0,
};

const headerStyle = {
  backgroundColor: '#EDEEF1',
  fontFamily: 'DINNextLTPro-Bold',
  fontSize: '1.6rem',
  padding: '16px 0',
  margin: 0,
};

const subHeaderStyle = {
  fontFamily: 'DINNextLTPro-Light',
  fontSize: '1.2rem',
  padding: '12px 0',
};

export class StaffGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: '',
      end: '',
      groupToEdit: null,
      loading: false,
    };

    this.incrementDate = this.incrementDate.bind(this);
    this.decrementDate = this.decrementDate.bind(this);
    this.editGroup = this.editGroup.bind(this);
    this.timeout = null;
  }

  componentWillMount() {
    const { site, users, dispatch, testFetch } = this.props;
    const selectedDate = moment().format('YYYYMMDD');
    this.setState({
      selectedDate,
    });
    if (testFetch) {
      testFetch();
    } else {
      listenForStaffGroups({ site, users, day: selectedDate, dispatch });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.site !== nextProps.site) {
      const { site, users, uid, dispatch } = this.props;
      const { selectedDate } = this.state;
      if (nextProps.testFetch) {
        nextProps.testFetch();
      } else {
        stopListeningForStaffGroups({ site, users, uid });
        listenForStaffGroups({
          site: nextProps.site,
          users,
          day: selectedDate,
          dispatch,
        });
      }
    }
  }

  componentWillUnmount() {
    const { site, users, uid } = this.props;
    stopListeningForStaffGroups({ site, users, uid });
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  getDate() {
    return moment(this.state.selectedDate, 'YYYYMMDD').format('MM/DD/YYYY');
  }

  getLabel() {
    return this.state.todaySelected ? 'Daily' : 'Weekly';
  }

  editGroup(group) {
    this.setState({ groupToEdit: group });
  }

  async changeListeners(selectedDate) {
    const { users, site, uid, dispatch, testFetch } = this.props;
    if (testFetch) {
      testFetch();
    } else {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.setState({ loading: false });
      }
      this.setState({ loading: true });
      this.timeout = setTimeout(() => {
        this.setState({ loading: false });
      }, 1500);
      stopListeningForStaffGroups({ site, users, uid });
      listenForStaffGroups({ site, users, day: selectedDate, dispatch });
    }
  }

  incrementDate(e) {
    e.preventDefault();
    const today = moment();
    if (today.isAfter(moment(this.state.selectedDate, 'YYYYMMDD'), 'day')) {
      const selectedDate = moment(this.state.selectedDate, 'YYYYMMDD')
        .add(1, 'day')
        .format('YYYYMMDD');
      this.setState({ selectedDate });
      this.changeListeners(selectedDate);
    }
  }

  decrementDate(e) {
    e.preventDefault();
    const selectedDate = moment(this.state.selectedDate, 'YYYYMMDD')
      .subtract(1, 'day')
      .format('YYYYMMDD');
    this.setState({ selectedDate });
    this.changeListeners(selectedDate);
  }

  renderGroups() {
    const { staffGroups, users, user } = this.props;
    const { groupToEdit, loading } = this.state;
    const groupToEdit_id = groupToEdit ? groupToEdit.group_id : null;
    const allGroups = Object.keys(staffGroups).reduce((groups, uid) => {
      const userGroups = staffGroups[uid];
      if (userGroups.length) {
        const foundUser = users.find(u => u.uid === uid);
        const updatedGroups = userGroups.map(group => ({
          staff_first_name: foundUser.first_name,
          staff_last_name: foundUser.last_name,
          staff_id: foundUser.uid,
          type: group.type,
          group_id: group.group_id,
          participants: group.participants,
          date: group.date,
        }));
        return groups.concat(updatedGroups);
      }
      return groups;
    }, []);
    allGroups.sort((a, b) => (a.staff_last_name > b.staff_last_name ? 1 : -1));

    return allGroups.length ? (
      allGroups.map(group => {
        return (
          <StaffRow
            data-test="group-row"
            key={group.group_id}
            group={group}
            groupToEdit_id={groupToEdit_id}
            editGroup={this.editGroup}
            user={user}
          />
        );
      })
    ) : (
      <h2 data-test="no-groups">
        {loading ? 'Loading...' : 'No groups have been created for this date'}
      </h2>
    );
  }

  render() {
    const { site, user } = this.props;
    const { groupToEdit, selectedDate } = this.state;
    return (
      <Container>
        <Row style={{ marginLeft: 2 }}>
          <Col md="9" xs="12">
            <PageTitle title={'Staff Groups'} />
          </Col>
        </Row>
        <Row>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              data-test="dec-button"
              style={{ margin: '0 12px' }}
              color="primary"
              size="small"
              variant="fab"
              onClick={this.decrementDate}
            >
              <FontAwesomeIcon
                color="#FFF"
                icon={faChevronLeft}
                style={chevronIconStyle}
              />
            </Button>
            <DatePicker
              selected={moment(selectedDate, 'YYYYMMDD')}
              onChange={date => {
                this.setState({ selectedDate: date.format('YYYYMMDD') });
                this.changeListeners(date.format('YYYYMMDD'));
              }}
            />
            <Button
              data-test="inc-button"
              style={{ margin: '0 12px' }}
              color="primary"
              size="small"
              variant="fab"
              onClick={this.incrementDate}
            >
              <FontAwesomeIcon
                color="#FFF"
                icon={faChevronRight}
                style={chevronIconStyle}
              />
            </Button>
          </div>
        </Row>
        <Panel data-test="staff-list" style={panelStyle}>
          <Row style={headerStyle} className="mui--align-left">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <div>{`Staff Groups: ${getFriendlySiteName(site)}`}</div>
              <div>{this.getDate()}</div>
            </div>
          </Row>
          <Container>
            <Row style={subHeaderStyle} className="mui--align-left">
              <Col md="4" xs="4">
                Staff Name
              </Col>
              <Col md="4" xs="1">
                Type
              </Col>
              <Col md="1" xs="6">
                Participants
              </Col>
            </Row>
            <Row>
              <Divider />
            </Row>
            {this.renderGroups()}
          </Container>
        </Panel>
        <Row style={{ marginLeft: 2 }}>
          <Col md="9" xs="12">
            <PageTitle title={'Edit Group'} />
          </Col>
        </Row>
        {groupToEdit ? (
          <Container>
            <Row>
              <Col>
                <CreateGroup
                  user={user}
                  group={groupToEdit}
                  resetStateAfterSubmission={() =>
                    this.setState({ groupToEdit: null })
                  }
                  adminEdit
                />
              </Col>
            </Row>
          </Container>
        ) : (
          <Panel data-test="group" style={{ padding: 0 }}>
            <Container style={{ padding: 0 }}>
              <Row
                style={{
                  backgroundColor: '#EDEEF1',
                  fontFamily: 'DINNextLTPro-Bold',
                  fontSize: '1.6rem',
                  padding: '16px 0',
                  margin: 0,
                }}
                className="mui--align-middle"
              >
                <Col md="12">
                  <h3>Please select a group to edit</h3>
                </Col>
              </Row>
            </Container>
          </Panel>
        )}
        <Row style={{ marginLeft: 2 }}>
          <Col md="9" xs="12">
            <PageTitle title={'Create Group'} />
          </Col>
        </Row>
        <Container>
          <Row>
            <Col>
              <CreateGroup
                user={user}
                resetStateAfterSubmission={() => {}}
                admin
              />
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  site: state.user.site,
  users: state.users,
  staffGroups: state.siteData.staffGroups,
  uid: state.user.uid,
  user: state.user,
});

export default connect(mapStateToProps)(StaffGroups);
