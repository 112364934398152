import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Input from 'muicss/lib/react/input';
import Col from 'muicss/lib/react/col';
import Row from 'muicss/lib/react/row';
import Panel from 'muicss/lib/react/panel';
import Button from 'muicss/lib/react/button';
import { getAllActiveParticipants } from '../../selectors/ParticipantsSelectors';

const fontStyle = {
  textTransform: 'none',
  fontFamily: 'DINNextLTPro',
  fontSize: '1.2rem',
};

export class ParticipantTypeahead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      filteredData: [],
    };

    this.renderFilteredData = this.renderFilteredData.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.input = null;
  }

  componentDidMount() {
    if (this.input) {
      this.input.controlEl.autocomplete = 'off';
    }
  }

  onHandleChange(e) {
    const { participants } = this.props;
    const inputValue = e.target.value;
    if (inputValue === '') {
      this.setState({
        filteredData: [],
        inputValue: '',
      });
    } else {
      const userSearch = inputValue.replace(/[^\w\s]+/gi, '');
      const regexp = new RegExp(`^${userSearch}`, 'i');
      const filteredData = participants.filter(entry => {
        return (
          regexp.test(entry.last_name) ||
          regexp.test(entry.first_name) ||
          regexp.test(entry.email)
        );
      });
      this.setState({
        filteredData,
        inputValue,
      });
    }
  }

  handleSelect(e, participant) {
    e.preventDefault();
    this.props.onSelect(participant);
    this.setState({
      filteredData: [],
      inputValue: '',
    });
  }

  renderFilteredData() {
    return this.state.filteredData.slice(0, 5).map((participant, index) => (
      <Row
        key={participant.pretty_id}
        style={{
          display: 'flex',
          backgroundColor: index % 2 ? 'white' : '#F0F0F0',
          textAlign: 'left',
          marginLeft: 0,
          marginRight: 0,
          height: 'auto',
          alignItems: 'center',
          padding: 0,
        }}
      >
        <Col xs="9">
          <Col md="6" style={fontStyle}>
            <div>{`${participant.last_name}, ${participant.first_name}`}</div>
          </Col>
          <Col md="6" style={fontStyle}>
            {participant.email}
          </Col>
        </Col>
        <Col
          xs="3"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            data-test="remove-participant"
            className="mui--pull-right"
            variant="fab"
            size="small"
            color="primary"
            onClick={e => this.handleSelect(e, participant)}
            style={{ fontSize: '1.4rem' }}
          >
            {'+'}
          </Button>
        </Col>
      </Row>
    ));
  }

  render() {
    return (
      <Col md="12">
        <Row>
          <Input
            ref={el => {
              this.input = el;
            }}
            type="text"
            label="Search for participant"
            floatingLabel
            name="search"
            value={this.state.inputValue}
            onChange={this.onHandleChange}
          />
        </Row>
        {!!this.state.filteredData.length && (
          <Row style={{ position: 'relative' }}>
            <Panel
              style={{
                padding: 0,
                position: 'absolute',
                width: '100%',
                zIndex: 10,
              }}
            >
              {this.renderFilteredData()}
            </Panel>
          </Row>
        )}
      </Col>
    );
  }
}

ParticipantTypeahead.propTypes = {
  participants: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  participants: getAllActiveParticipants(state),
});

export default connect(mapStateToProps)(ParticipantTypeahead);
