import moment from 'moment';
import ref from '../../db/database';
import {
  setParticipantsListeners,
  clearParticipants,
} from './ParticipantActions';
import {
  listenForParticipants,
  stopListeningToParticipants,
} from '../../api/Group.Participants.Api';
import { fetchFeedback } from '../../api/FeedbackData.Api';
import { setFeedbackData } from '../feedbackData/FeedbackDataActions';

export function fetchParticipants(service = ref) {
  return (dispatch, getState) => {
    const { user } = getState();
    const { site } = user;
    dispatch(setParticipantsListeners());
    listenForParticipants({ site, dispatch }, service);
  };
}

export function removeParticipants(service = ref) {
  return (dispatch, getState) => {
    const { user } = getState();
    const { site } = user;
    dispatch(clearParticipants());
    stopListeningToParticipants({ site }, service);
  };
}

export function getFeedbackForms({ participant_id, site }, service = ref) {
  return dispatch => {
    return fetchFeedback({ participant_id, site }, service)
      .then(result => {
        return dispatch(setFeedbackData(result));
      })
      .catch(error => console.error(error));
  };
}

export async function submitFeedback(
  {
    comment,
    hours,
    minutes,
    attendance,
    participant_id,
    session_score,
    group,
    user,
    questions,
  },
  service = ref
) {
  try {
    const { date, group_id, type } = group;
    const { site, uid, firstName, lastName } = user;
    const [feedbackExists, feedbackId] = await service(
      `groups/${site}/${uid}/${group.group_id}/participants/${participant_id}/feedback_id`
    )
      .once('value')
      .then(snap => [Boolean(snap.val()), snap.val()]);
    const feedbackRef = feedbackExists
      ? service(`feedback/${site}/${feedbackId}`)
      : service(`feedback/${site}`).push();
    const feedbackKey = feedbackExists ? feedbackId : feedbackRef.key;

    const feedback = {
      attendance_score: attendance,
      comment,
      hours,
      minutes,
      updated_at: date,
      feedback_id: feedbackKey,
      group_id,
      participant_id,
      session_score,
      staff_name: `${firstName} ${lastName}`,
      staff_id: uid,
      site,
      type,
      questions,
    };
    if (!feedbackExists) {
      feedback.date = date;
      feedback.created_at = date;
    }
    const feedbackComplete = Object.values(group.participants).reduce(
      (result, participant) => {
        if (
          !participant.feedback_id &&
          +participant.participant_id !== +participant_id
        ) {
          return false;
        }
        return result;
      },
      true
    );
    return Promise.all([
      service(`feedback/${site}/${feedbackKey}`).update(feedback),
      service(
        `groups/${site}/${uid}/${group.group_id}/participants/${participant_id}`
      ).update({
        comment,
        feedback_id: feedbackKey,
        session_score,
        form_data: {
          attendance,
          comment,
          q1: questions.q1.value,
          q2: questions.q2.value,
          q3: questions.q3.value,
          hours,
          minutes,
        },
      }),
      service(
        `feedback_reminders/${group.date}/${group.feedback_reminder_id}`
      ).update({ completed: feedbackComplete }),
    ]);
  } catch (error) {
    console.error(error);
  }
}
