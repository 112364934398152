import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Container from 'muicss/lib/react/container';
import Row from 'muicss/lib/react/row';
import Button from 'muicss/lib/react/button';
import { getAllActiveUsers, getAllInactiveUsers } from '../../selectors/UsersSelectors';
import { toggleCreateStaffModal } from '../../actions/appState/AppActions';
import StaffList from '../../components/StaffList';
import PageTitle from '../../components/PageTitle';
import { getFriendlySiteName } from '../../utiltites/helpers';

const panelStyle = {
  padding: '0 32px'
}

export class StaffPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSelected: true,
      user: null,
    }

    this.handleActiveToggle = this.handleActiveToggle.bind(this);
  }

  handleActiveToggle(on) {
    this.setState({
      activeSelected: on,
    });
  }

  render() {
    const { activeSelected } = this.state;
    const { activeUsers, inactiveUsers, user } = this.props;
    const siteName = getFriendlySiteName(user.site);
    const titleHeader = activeSelected ? `${siteName} Active Staff` : `${siteName} Inactive Staff`;
    const staffList = activeSelected ? activeUsers : inactiveUsers;
    return (
      <div>
        <Container style={panelStyle}>
        <Row>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <PageTitle title={'Staff'}/>
            <Button
              variant="raised"
              data-test='create-staff-button'
              onClick={this.props.openCreateStaffModal}
            >
              {'create staff'}
            </Button>
          </div>
        </Row>
        <Row className="mui--text-center" style={{padding: '12px'}}>
            <Button
              onClick={() => this.handleActiveToggle(true)}
              color={activeSelected ? 'primary' : null}
              variant='raised'
            >
              {'Active'}
            </Button>
            <Button
              onClick={() => this.handleActiveToggle(false)}
              color={!activeSelected ? 'primary' : null}
              variant='raised'
            >
              {'Inactive'}
            </Button>
        </Row>
        <Row>
            <StaffList  headerTitle={titleHeader} users={staffList}/>
        </Row>
      </Container>
    </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  activeUsers: getAllActiveUsers(state),
  inactiveUsers: getAllInactiveUsers(state),
})

const mapDispatchToProps = (dispatch) => ({
  openCreateStaffModal: () => dispatch(toggleCreateStaffModal(true))
});

StaffPage.propTypes = {
  user: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffPage)
