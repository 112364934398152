import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'muicss/lib/react/panel';
import LoginForm from '../../components/LoginForm';
import store from '../../store';
import { toggleForgotPasswordModal } from '../../actions/appState/AppActions';
import { centeringFrameStyle, largeBoldTextStyle } from '../../utiltites/commonStyles';

const backgrdropStyle = {
  backgroundColor: 'white'
}

const forgotPasswordStyle = {
  fontFamily: 'DINNextLTPro',
  cursor: 'pointer',
}

class LoginPage extends React.Component {

  constructor(props) {
    super(props);

    this.onSignUp = this.onSignUp.bind(this);
    this.onForgotPassword = this.onForgotPassword.bind(this);
  }

  onForgotPassword() {
    store.dispatch(toggleForgotPasswordModal(true));
  }

  onSignUp(event) {
    const { history } = this.props;
    event.preventDefault();
    history.push('/signup');
  }

  render() {
    const { history, onForgotPasswordClick } = this.props;
    const onForgotPasswordClickHandler = onForgotPasswordClick ? onForgotPasswordClick : this.onForgotPassword;

    return (
      <div data-test="login-page" style={backgrdropStyle}>
        <div style={centeringFrameStyle}>
          <Panel className="mui--text-center">
            <div>
              <div>
                <h3 style={largeBoldTextStyle}>READI CHICAGO LOGIN</h3>
                <LoginForm history={history} />
                <a data-test="login-page-forgot-password-link"
                style={forgotPasswordStyle} onClick={onForgotPasswordClickHandler}>I Forgot My Password</a>
              </div>
            </div>
          </Panel>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default LoginPage;
