import { createSelector } from 'reselect';

export const getAllActiveParticipants = createSelector(
  state => Object.values(state.participants),
  participants => sortParticipants(participants.filter(participant => {
    return participant.disabled === false;
  }))
)

export const getAllInactiveParticipants = createSelector(
  state => Object.values(state.participants),
  participants => sortParticipants(participants.filter(participant => {
    return participant.disabled === true;
  }))
)

export const getAndSortParticipants = createSelector(
  state => state.participants,
  participantObj => {
    const participants = Object.values(participantObj).reduce((data, participant) => {
      if (participant.disabled) {
        data.inactive.push(participant);
      } else {
        data.active.push(participant)
      }
      return data;
    }, { active: [], inactive: [] })
    return {
      active: sortParticipants(participants.active),
      inactive: sortParticipants(participants.inactive)
    }
  }
)

const sortParticipants = participants => {
  return participants.sort((a, b) => {
    if (a.last_name < b.last_name) {
      return -1;
    }
    if (a.last_name > b.last_name) {
      return 1;
    }
    return 0;
  })
}

