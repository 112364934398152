/**
 *
 * Feedback Page
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Panel from 'muicss/lib/react/panel';
import Button from 'muicss/lib/react/button';
import Container from 'muicss/lib/react/container';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import Divider from 'muicss/lib/react/divider';
import Textarea from 'muicss/lib/react/textarea';
import Select from 'muicss/lib/react/select';
import store from '../../store';
import PageTitle from '../../components/PageTitle';
import FeedbackSelection from '../../components/FeedbackSelection';
import { submitFeedback } from '../../actions/participants/ParticipantActionCreators';
import { getFriendlySessionType } from '../../utiltites/helpers';
import { toggleFeedbackInfoModal } from '../../actions/appState/AppActions';
import {
  primaryButtonStyle,
  mediumBoldTextStyle,
} from '../../utiltites/commonStyles';

const containerStyle = {
  height: '130px',
};

const requiredStyle = {
  fontStyle: 'italic',
  color: 'red',
};

const innerPanelStyle = {
  padding: 0,
};

const nextButtonStyle = {
  float: 'right',
};

const headerStyle = {
  backgroundColor: '#EDEEF1',
  fontFamily: 'DINNextLTPro-Bold',
  fontSize: '1.6rem',
  padding: '16px 0',
  margin: 0,
};

const summaryCenteringStyle = {
  margin: 'auto',
  fontFamily: 'DINNextLTPro-Medium',
  fontSize: '1.3rem',
};

const feedbackScoreColStyle = {
  textAlign: 'center',
};

export class FeedbackPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attendance: null,
      q1: null,
      q2: null,
      q3: null,
      hours: '0',
      minutes: '0',
      comment: '',
      page1: true,
      isCAD: false,
      loading: false,
    };

    // Type, value, hours, mins

    this.buttons = [
      { title: 'Does Not Meet', color: '#D0021B', light: false },
      { title: 'Meets', color: '#FED82E', light: true },
      { title: 'Exceeds', color: '#00B38D', light: false },
    ];

    this.attendanceButtons = [
      { title: 'Absent', color: 'gray', light: false },
      { title: 'Excused Absence', color: 'gray', light: true },
      { title: 'Present', color: 'gray', light: false },
    ];

    this.formQuestions = this.getFormQuestions(
      this.props.history.location.state.group.type
    );

    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.validateFirstPage = this.validateFirstPage.bind(this);
    this.calculateScore = this.calculateScore.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOpenFeedbackInfo = this.handleOpenFeedbackInfo.bind(this);
  }

  componentWillMount() {
    const { group, form_data } = this.props.history.location.state;
    const { type } = group;
    const newState = {
      isCAD: type === 'CAD',
    };
    if (form_data) {
      const { comment, hours, minutes, attendance, q1, q2, q3 } = form_data;

      newState.comment = comment;
      newState.hours = hours;
      newState.minutes = minutes;
      newState.attendance = attendance - 1;
      newState.q1 = q1 - 1;
      newState.q2 = q2 - 1;
      newState.q3 = q3 - 1;
    }
    this.setState(newState);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getFormQuestions(type) {
    switch (type) {
      case 'WC':
        return {
          q1: {
            title: 'Work Effort',
            key: 'work_effort',
          },
          q2: {
            title: 'Cooperation',
            key: 'cooperation',
          },
          q3: {
            title: 'Safety',
            key: 'safety',
          },
        };
      default:
        return {
          q1: {
            title: 'On Time',
            key: 'on_time',
          },
          q2: {
            title: 'In Room',
            key: 'in_room',
          },
          q3: {
            title: 'Participation',
            key: 'participation',
          },
        };
    }
  }

  handleTimeChange(event) {
    event.preventDefault();
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  handleButtonClick(button, value) {
    const newState = {
      [button]: value,
    };
    if (button === 'attendance' && value < 2) {
      newState.q1 = null;
      newState.q2 = null;
      newState.q3 = null;
      newState.hours = '';
      newState.minutes = '';
    }
    this.setState(newState);
  }

  handleCommentChange(event) {
    event.preventDefault();
    this.setState({
      comment: event.target.value,
    });
  }

  handleOpenFeedbackInfo(type, questionKey) {
    store.dispatch(toggleFeedbackInfoModal(true, type, questionKey));
  }

  checkAttendance() {
    if (this.state.attendance === null) {
      return true;
    }
    if (this.state.attendance < 2) {
      return false;
    }
    return true;
  }

  isCADFormValidated(state) {
    return (
      state.isCAD &&
      state.attendance != null &&
      state.q1 != null &&
      state.q2 != null &&
      state.q3 != null &&
      (+state.minutes > 0 || +state.hours > 0)
    );
  }
  isDefaultFormValidated(state) {
    return (
      state.attendance != null &&
      state.q1 != null &&
      state.q2 != null &&
      state.q3 != null
    );
  }
  anyQuestionScoreIsZero(state) {
    return state.q1 === 0 || state.q2 === 0 || state.q3 === 0;
  }

  validateFirstPage() {
    if (!this.checkAttendance()) {
      return true;
    }
    if (this.state.isCAD) {
      return this.isCADFormValidated(this.state);
    }
    if (this.isDefaultFormValidated(this.state)) {
      return true;
    }
    return false;
  }

  commentRequired() {
    if (this.anyQuestionScoreIsZero(this.state)) {
      return true;
    }
    return false;
  }

  validateSecondPage() {
    if ((this.commentRequired() && !this.state.comment) || this.state.loading) {
      return false;
    }
    return true;
  }

  handleNext(event) {
    event.preventDefault();
    if (this.state.attendance > 1 && this.validateFirstPage()) {
      this.setState({ page1: false });
      window.scrollTo(0, 0);
    } else {
      this.handleSubmit();
    }
  }

  calculateScore() {
    const { q1, q2, q3, attendance } = this.state;
    if (attendance === null || attendance < 2) {
      return 0;
    }
    const sum = q1 + q2 + q3 + 3;
    const average = sum / 3;
    return Math.round(10 * average) / 10;
  }

  handleSubmit() {
    const { group } = this.props.history.location.state;
    const { participantID } = this.props.match.params;
    this.setState({ loading: true });
    submitFeedback({
      comment: this.state.comment,
      hours: Number(this.state.hours),
      minutes: Number(this.state.minutes),
      attendance: this.state.attendance + 1,
      participant_id: participantID,
      session_score: this.calculateScore(),
      group: group,
      user: this.props.user,
      questions: {
        q1: {
          key: this.formQuestions.q1.key,
          title: this.formQuestions.q1.title,
          value: this.state.q1 + 1,
        },
        q2: {
          key: this.formQuestions.q2.key,
          title: this.formQuestions.q2.title,
          value: this.state.q2 + 1,
        },
        q3: {
          key: this.formQuestions.q3.key,
          title: this.formQuestions.q3.title,
          value: this.state.q3 + 1,
        },
      },
    }).then(() => {
      this.setState({ loading: false }, () => {
        this.props.history.push('/app/groups');
      });
    });
  }

  render() {
    const { attendance, q1, q2, q3, page1, hours, minutes, isCAD } = this.state;
    const participant = this.props.participants[
      this.props.match.params.participantID
    ];
    const { group } = this.props.history.location.state;
    const { history } = this.props;

    return participant ? (
      <Container data-test="feedback-form">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <PageTitle title={'Feedback Form'} />
          <Button
            data-test="myaccount-back-btn"
            variant="raised"
            onClick={() => history.goBack()}
          >
            {'back'}
          </Button>
        </div>
        <Panel style={innerPanelStyle}>
          <Row style={headerStyle}>
            <Col />
            <Col md="6" xs="6">
              {getFriendlySessionType(group.type)}
            </Col>
            <Col
              md="6"
              xs="6"
            >{`${participant.first_name} ${participant.last_name}`}</Col>
          </Row>
          {page1 ? (
            <Container>
              <Row>
                <FeedbackSelection
                  data-test="question"
                  title={'Attendance'}
                  buttons={this.attendanceButtons}
                  selectedButton={attendance}
                  handleClick={this.handleButtonClick}
                  handleInfoClick={() =>
                    this.handleOpenFeedbackInfo(group.type, 'attendance')
                  }
                  stateProp={'attendance'}
                />
              </Row>
              <Row>
                <Divider />
              </Row>

              {this.checkAttendance() && (
                <div>
                  <Row>
                    <FeedbackSelection
                      data-test="question"
                      title={this.formQuestions.q1.title}
                      buttons={this.buttons}
                      selectedButton={q1}
                      handleClick={this.handleButtonClick}
                      handleInfoClick={() =>
                        this.handleOpenFeedbackInfo(group.type, 'q1')
                      }
                      stateProp={'q1'}
                    />
                  </Row>
                  <Row>
                    <Divider />
                  </Row>
                  <Row>
                    <FeedbackSelection
                      data-test="question"
                      title={this.formQuestions.q2.title}
                      buttons={this.buttons}
                      selectedButton={q2}
                      handleClick={this.handleButtonClick}
                      handleInfoClick={() =>
                        this.handleOpenFeedbackInfo(group.type, 'q2')
                      }
                      stateProp={'q2'}
                    />
                  </Row>
                  {isCAD && (
                    <Container fluid style={containerStyle}>
                      <Row>
                        <h3 style={mediumBoldTextStyle}>{'Time In Room'}</h3>
                        <Col md="3" md-offset="6" xs="6">
                          <Select
                            data-test="question"
                            name="hours"
                            value={this.state.hours}
                            onChange={this.handleTimeChange}
                          >
                            <option value="0">0 Hours</option>
                            <option value="1">1 Hour</option>
                            <option value="2">2 Hours</option>
                            <option value="3">3 Hours</option>
                          </Select>
                        </Col>
                        <Col md="3" xs="6">
                          <Select
                            data-test="question"
                            name="minutes"
                            value={this.state.minutes}
                            onChange={this.handleTimeChange}
                          >
                            <option value="0">0 Minutes</option>
                            <option value="15">15 Minutes</option>
                            <option value="30">30 Minutes</option>
                            <option value="45">45 Minutes</option>
                          </Select>
                        </Col>
                      </Row>
                    </Container>
                  )}
                  <Row>
                    <Divider />
                  </Row>
                  <Row>
                    <FeedbackSelection
                      data-test="question"
                      title={this.formQuestions.q3.title}
                      buttons={this.buttons}
                      selectedButton={q3}
                      handleClick={this.handleButtonClick}
                      handleInfoClick={() =>
                        this.handleOpenFeedbackInfo(group.type, 'q3')
                      }
                      stateProp={'q3'}
                    />
                  </Row>
                  <Row>
                    <Divider />
                  </Row>
                </div>
              )}
              <Button
                style={nextButtonStyle}
                variant="raised"
                size="large"
                disabled={!this.validateFirstPage()}
                onClick={this.handleNext}
              >
                {this.checkAttendance() ? 'NEXT PAGE' : 'SUBMIT'}
              </Button>
            </Container>
          ) : (
            <div>
              <Container style={summaryCenteringStyle}>
                <Row>
                  <Col md="6" xs="12" style={feedbackScoreColStyle}>
                    {'Feedback Score'}
                    <h3 style={{ fontSize: '60px' }}>
                      {this.calculateScore()}
                    </h3>
                  </Col>
                  <Col md="6" xs="12">
                    <Row>
                      <Col md="6" xs="6">
                        {this.formQuestions.q1.title}
                      </Col>
                      <Col md="6" xs="6">
                        {q1 + 1}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" xs="6">
                        {this.formQuestions.q2.title}
                      </Col>
                      <Col md="6" xs="6">
                        {q2 + 1}
                      </Col>
                    </Row>
                    {isCAD && (
                      <Row>
                        <Col md="6" xs="6">
                          {'CBT'}
                        </Col>
                        <Col md="6" xs="6">{`${hours} ${
                          hours === '1' ? 'hr' : 'hrs'
                        } ${minutes} minutes`}</Col>
                      </Row>
                    )}
                    <Row>
                      <Col md="6" xs="6">
                        {this.formQuestions.q3.title}
                      </Col>
                      <Col md="6" xs="6">
                        {q3 + 1}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Col md="12" xs="12">
                  <Row>
                    <h3>
                      {
                        "Do you have any comments you would like to leave about today's session?"
                      }
                    </h3>
                    {this.commentRequired() && (
                      <h3 style={requiredStyle}>
                        {'Comment is required due to not meeting expectations'}
                      </h3>
                    )}
                  </Row>
                  <Row>
                    <Textarea
                      type="text-area"
                      name="comment"
                      value={this.state.comment}
                      onChange={this.handleCommentChange}
                    />
                  </Row>
                  <Row>
                    <h3 style={{ fontStyle: 'italic' }}>
                      {'All comments are viewable by the participant'}
                    </h3>
                  </Row>
                  <Row style={{ textAlign: 'center' }}>
                    <Col md="6">
                      <Button
                        size="large"
                        style={{ ...primaryButtonStyle, width: '100%' }}
                        variant="raised"
                        disabled={!this.validateSecondPage()}
                        onClick={this.handleSubmit}
                      >
                        {'SUBMIT'}
                      </Button>
                    </Col>
                    <Col md="6">
                      <Button
                        style={{ width: '100%' }}
                        size="large"
                        onClick={() => this.setState({ page1: true })}
                        variant="raised"
                      >
                        {'Previous Page'}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Container>
            </div>
          )}
        </Panel>
      </Container>
    ) : (
      <Col md="12">
        <h3 style={{ fontStyle: 'italic' }}>{'Participant not found'}</h3>
        <Button
          data-test="go-back-button"
          variant="raised"
          onClick={() => history.goBack()}
        >
          {'GO BACK'}
        </Button>
      </Col>
    );
  }
}

const mapStateToProps = state => {
  return {
    participants: state.participants,
    user: state.user,
  };
};

export default connect(mapStateToProps)(FeedbackPage);
