/**
 *
 * SidebarButton
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'muicss/lib/react/button';

const SelectedLabel = styled.label`
  color: white;
  font-family: 'DINNextLTPro-Bold';
  border-bottom: 2px solid #3cb432;
`;

const UnSelectedLabel = styled.label`
  color: white;
  font-family: 'DINNextLTPro-Light';
`;

const buttonStyle = {
  backgroundColor: 'transparent',
  border: 'none',
  marginLeft: '15px'
}

/* eslint-disable react/prefer-stateless-function */
const SidebarButton = ({ selected, handleClick, title }) => {
  const DynamicLabel =
    selected === true ? SelectedLabel : UnSelectedLabel;

  return (
    <Button variant="flat" style={buttonStyle} onClick={handleClick}>
      <DynamicLabel style={{cursor: 'pointer'}}>{title}</DynamicLabel>
    </Button>
  );
}

SidebarButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default SidebarButton;
