import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Form from "muicss/lib/react/form";
import Container from "muicss/lib/react/container";
import Row from "muicss/lib/react/row";
import Col from "muicss/lib/react/col";
import Input from "muicss/lib/react/input";
import Select from "muicss/lib/react/select";
import Button from "muicss/lib/react/button";
import store from "../../store";
import { toProperCase } from "../../utiltites/helpers";
import { setMessage } from "../../actions/messages/MessageActions";
import {
  canChangeParticipantDeactivated,
  canDeleteParticipant,
} from "../../utiltites/permissions";
import { deleteParticipant } from "../../api/Group.Participants.Api";

export class CreateParticipantModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      stage: "Stage 1",
      site: "austin",
      pretty_id: "",
      disabled: false,
      errorMsg: "",
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitParticipant = this.submitParticipant.bind(this);
  }

  componentWillMount() {
    const newState = {};
    newState.site = this.props.user.site;

    if (this.props.participant) {
      const {
        first_name,
        last_name,
        email,
        stage,
        pretty_id,
        disabled,
      } = this.props.participant;
      newState.first_name = first_name;
      newState.last_name = last_name;
      newState.email = email;
      newState.stage = stage;
      newState.pretty_id = pretty_id;
      newState.disabled = disabled;
    }
    this.setState(newState);
  }

  handleChange(event) {
    const { value, name } = event.target;
    if (name === "disabled") {
      this.setState({
        disabled: value === "true" ? true : false,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  submitParticipant(e) {
    e.preventDefault();
    if (this.validateState()) {
      this.setState({ loading: true });
      this.props
        .handleParticipant({
          ...this.state,
          first_name: toProperCase(this.state.first_name),
          last_name: toProperCase(this.state.last_name),
        })
        .then(() => {
          this.setState({
            first_name: "",
            last_name: "",
            email: "",
            stage: "Stage 1",
            site: "austin",
            disabled: false,
            errorMsg: "",
            loading: false,
          });
          this.props.handleClose();
        })
        .catch(err => {
          this.setState({ loading: false });
          store.dispatch(
            setMessage({
              body:
                "There was an error creating your participant. Please try again",
              messageStyle: "alert",
              timeout: 5000,
            })
          );
          console.log(err);
        });
    } else {
      store.dispatch(
        setMessage({
          body: "All fields are required",
          messageStyle: "alert",
          timeout: 5000,
        })
      );
    }
  }

  deleteParticipant = async e => {
    try {
      e.preventDefault();
      const { site, pretty_id } = this.props.participant;
      await deleteParticipant({ site, pretty_id });
      this.props.handleClose();
      this.props.history.replace("/app/participants");
    } catch (error) {
      store.dispatch(
        setMessage({
          body:
            "There was an error deleting your participant. Please try again",
          messageStyle: "alert",
          timeout: 5000,
        })
      );
      console.error(error);
    }
  };

  validateState() {
    const { first_name, last_name, email } = this.state;
    return (
      (!!first_name && !!last_name && /^\S+@\S+\.\S+$/.test(email)) ||
      (!!first_name && !!last_name && !email)
    );
  }

  render() {
    const { user } = this.props;
    return (
      <Form
        data-test="participant-form"
        onSubmit={this.handleSubmit}
        style={{ margin: "20px" }}
      >
        <Container>
          <Row className="mui--text-left">
            <Col md="6" xs="12">
              <Input
                required
                floatingLabel
                type="text"
                label="First Name"
                name="first_name"
                value={this.state.first_name}
                onChange={this.handleChange}
              />
            </Col>
            <Col md="6" xs="12">
              <Input
                required
                floatingLabel
                type="text"
                label="Last Name"
                name="last_name"
                value={this.state.last_name}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row className="mui--text-left">
            <Col md="12">
              <Input
                floatingLabel
                type="email"
                label="Email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row className="mui--text-left">
            <Col md="4" xs="12">
              <Select
                name="site"
                disabled
                onChange={this.handleChange}
                value={this.state.site}
                label="Site"
              >
                <option value="austin">Austin</option>
                <option value="englewood">Englewood</option>
                <option value="north_lawndale">North Lawndale</option>
              </Select>
            </Col>
            <Col md="4" xs="12">
              <Select
                name="stage"
                onChange={this.handleChange}
                value={this.state.stage}
                label="Stage"
              >
                <option value="Stage 1">Stage 1</option>
                <option value="Stage 2">Stage 2</option>
                <option value="Stage 3">Stage 3</option>
              </Select>
            </Col>
            <Col md="4" xs="12">
              <Select
                disabled={!canChangeParticipantDeactivated(user.role)}
                name="disabled"
                onChange={this.handleChange}
                value={this.state.disabled}
                label="Deactivated"
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Select>
            </Col>
          </Row>
          <Row className="mui--text-left">
            <Col md="12">
              <Button
                variant="raised"
                color="primary"
                disabled={this.state.loading}
                onClick={this.submitParticipant}
              >
                {this.props.participant
                  ? "update participant"
                  : "create participant"}
              </Button>
              {this.props.participant && canDeleteParticipant(user.role) && (
                <Button
                  variant="raised"
                  color="danger"
                  onClick={this.deleteParticipant}
                >
                  delete participant
                </Button>
              )}
            </Col>
          </Row>
          {!!this.state.errorMsg && (
            <Row className="mui--text-left">
              <Col md="12">
                <h2 style={{ color: "red" }}>{this.state.errorMsg}</h2>
              </Col>
            </Row>
          )}
        </Container>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps)(CreateParticipantModalContent)
);
