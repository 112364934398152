import { auth } from './config';

// Sign In
export function doSignInWithEmail(email, password) {
  return auth.signInWithEmailAndPassword(email, password);
}

// Sign Out
export function doSignOut() {
  return auth.signOut();
}

// Create New User
export function doCreateUserWithEmailAndPassword(email, password) {
  return auth.createUserWithEmailAndPassword(email, password);
}

// Password Reset
export function doPasswordReset(email) {
  return auth.sendPasswordResetEmail(email);
}

// Password Change
export function doPasswordUpdate(password) {
  return auth.currentUser.updatePassword(password);
}

export function doEmailUpdate(email) {
  return auth.currentUser.updateEmail(email);
}

// Send verification email
export function doSendVerificationEmail() {
  return auth.currentUser.sendEmailVerification();
}

// Get current user
export function getSignInMethods(email) {
  return auth.fetchSignInMethodsForEmail(email);
}
