import { createSelector } from 'reselect';

export const getFormCompletionRatio = createSelector(
  state => state.siteData.staffGroups,
  usersObj => {
    const completionData = Object.values(usersObj).reduce((data, groups) => {
      groups.forEach(group => {
        const participants = group.participants ? Object.values(group.participants) : [];
        participants.forEach(participant => {
          data.total += 1;
          if (participant.feedback_id) {
            data.completed += 1;
          }
        })
      })
      return data;
    }, { completed: 0, total: 0 })
    let ratio = completionData.completed / completionData.total;
    if (isNaN(ratio)) {
      ratio = 0;
    }
    return ratio;
  }
)
