import ref from '../db/database';

export function fetchFeedback({ participant_id, site }, service = ref) {
  return service(`feedback/${site}/`)
    .orderByChild('participant_id')
    .equalTo(participant_id)
    .once('value')
    .then(snapshot => {
      return snapshot.exists() ? Object.values(snapshot.val()) : [];
    })
    .catch(error => console.error(error))
}