import { db } from './config';

// void return if error!
export default function ref(refPath) {
  try {
    return db.ref(refPath);
  } catch (e) {
    console.warn(e.stack);
  }
}

// Get user data from Firebase (different from Authentication)
export function doGetUserRef(uid) {
  return db.ref(`users/${uid}`);
}

// Set user data in Firebase (different from Authentication)
export function doCreateUser(uid, data) {
  return db.ref(`users/${uid}`).set(data);
}

// Update user data in Firebase (different from Authentication)
export function doUpdateUser(uid, data) {
  return db.ref(`users/${uid}`).update(data);
}
