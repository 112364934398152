import {
  CLEAR_STATE,
  TOGGLE_MENU,
  TOGGLE_CREATE_GROUP,
  TOGGLE_FORGOT_PASSWORD,
  TOGGLE_FEEDBACK_INFO,
  TOGGLE_REMOVE_PARTICIPANT,
  TOGGLE_CREATE_PARTICIPANT,
  TOGGLE_UPDATE_PARTICIPANT,
  TOGGLE_CREATE_STAFF,
  TOGGLE_CHANGE_SITE,
  TOGGLE_UPDATE_STAFF,
  TOGGLE_DOWNLOAD_SUMMARY,
  TOGGLE_DATA_EXPORT
} from "../ActionTypes";

export function clearState() {
  return {
    type: CLEAR_STATE
  };
}

export function toggleCreateGroupModal(bool) {
  return {
    type: TOGGLE_CREATE_GROUP,
    payload: bool
  };
}

export function toggleForgotPasswordModal(bool) {
  return {
    type: TOGGLE_FORGOT_PASSWORD,
    payload: bool
  };
}

export function toggleCreateParticipantModal(bool) {
  return {
    type: TOGGLE_CREATE_PARTICIPANT,
    payload: bool
  };
}

export function toggleCreateStaffModal(bool) {
  return {
    type: TOGGLE_CREATE_STAFF,
    payload: bool
  };
}

export function toggleChangeSiteModal(bool) {
  return {
    type: TOGGLE_CHANGE_SITE,
    payload: bool
  };
}

export function toggleDataExportModal(bool) {
  return {
    type: TOGGLE_DATA_EXPORT,
    payload: bool
  };
}

export function toggleDownloadSummaryModal(
  bool,
  participant,
  feedbackData,
  end,
  selectedDate
) {
  return {
    type: TOGGLE_DOWNLOAD_SUMMARY,
    payload: {
      open: bool,
      participant,
      feedbackData,
      end,
      selectedDate
    }
  };
}
export function toggleUpdateParticipantModal(bool, participant) {
  return {
    type: TOGGLE_UPDATE_PARTICIPANT,
    bool,
    participant
  };
}

export function toggleRemoveParticipantModal(
  bool,
  participant = null,
  onConfirm = null
) {
  return {
    type: TOGGLE_REMOVE_PARTICIPANT,
    payload: {
      bool,
      participant,
      onConfirm
    }
  };
}

export function toggleFeedbackInfoModal(open, type, questionKey) {
  return {
    type: TOGGLE_FEEDBACK_INFO,
    payload: {
      open: open,
      type: type,
      questionKey: questionKey
    }
  };
}

export function toggleUpdateStaffModal(bool, staff) {
  return {
    type: TOGGLE_UPDATE_STAFF,
    payload: {
      open: bool,
      staff
    }
  };
}

export function toggleMenu() {
  return {
    type: TOGGLE_MENU
  };
}
