import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Appbar, Row } from "muicss/react";
import Logo from "../../images/logoSmall.png";
import { logoutUser } from "../../actions/user/UserActionCreators";
import { toggleMenu } from "../../actions/appState/AppActions";

const headerStyle = {
  backgroundColor: "#394648",
  width: "100%",
  padding: "10px",
  boxSizing: "border-box",
  zIndex: 10,
  position: "fixed",
};

const greetingStyle = {
  fontFamily: "DINNextLTPro-Light",
  color: "white",
  fontSize: "1em",
};

const logoStyle = {
  height: "auto",
  width: "100%",
};

const imageContainerStyle = {
  width: "130px",
  height: "100%",
  marginTop: 10,
  marginLeft: 20,
};

const hamburgerMenuStyle = {
  fontSize: "30px",
  paddingLeft: 0,
  color: "#FFF",
  border: "none",
  backgroundColor: "transparent",
  outline: "none",
};

const logoutLinkStyle = {
  padding: "8px",
  cursor: "pointer",
};

const Header = ({ user, dispatch }) => {
  const logout = () => {
    dispatch(logoutUser());
  };

  const createGreeting = () =>
    user.email ? `Welcome ${user.firstName} ${user.lastName}` : "";
  return (
    <Appbar style={headerStyle}>
      <Row>
        <Col md="12">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {user && !!user.email && (
                <Button
                  variant="flat"
                  onClick={() => dispatch(toggleMenu())}
                  style={hamburgerMenuStyle}
                  className="mui--visible-xs-inline-block mui--visible-sm-inline-block "
                >
                  ☰
                </Button>
              )}
              <Link to="/">
                <div style={imageContainerStyle}>
                  <img
                    style={logoStyle}
                    src={Logo}
                    alt="Heartland Readi Program"
                  />
                </div>
              </Link>
              <span
                style={{ marginLeft: 20 }}
              >{`v${process.env.REACT_APP_VERSION}`}</span>
            </div>
            <span
              className="mui--text-right mui--align-middle mui--hidden-xs"
              style={greetingStyle}
            >
              {createGreeting()}
              {user && !!user.email && (
                <a onClick={logout} style={logoutLinkStyle}>
                  Not you?
                </a>
              )}
            </span>
          </div>
        </Col>
      </Row>
    </Appbar>
  );
};

Header.defaultProps = {
  greeting: "",
};

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    site: PropTypes.string,
    role: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Header);
