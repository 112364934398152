/*
*
*   StaffForm
*
*/

import React from 'react'
import PropTypes from 'prop-types'
import Container from 'muicss/lib/react/container';
import Button from 'muicss/lib/react/button';
import Form from 'muicss/lib/react/form';
import Select from 'muicss/lib/react/select';
import Input from 'muicss/lib/react/input';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import { canSetStaffRoleToSuperAdmin, canChangeStaffDeactivated, canChangeStaffRole, canChangeStaffSite } from '../../utiltites/permissions';
import { primaryButtonStyle } from '../../utiltites/commonStyles';
import { setMessage } from '../../actions/messages/MessageActions';
import store from '../../store';

export class StaffForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            uid: '',
            first_name: '',
            last_name: '',
            email: '',
            site: '',
            role: '',
            disabled: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validateState = this.validateState.bind(this);
        this.handlePasswordResetPressed = this.handlePasswordResetPressed.bind(this);
        this.resetPasswordEnabled = this.resetPasswordEnabled.bind(this);
    }

    componentWillMount() {
        const { staff } = this.props;
        if (staff) {
            this.setState({
                uid: staff.uid,
                first_name: staff.first_name,
                last_name: staff.last_name,
                email: staff.email,
                site: staff.site,
                role: staff.role,
                disabled: staff.disabled,
            })
        }
    }

    handleChange(event) {
        const { value, name } = event.target;
        
        if(name === 'disabled') {
            this.setState({
                disabled: value === 'true' ? true : false,
            })
        } else {
            this.setState({
                [name]: value,
            });
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.validateState()) {
            this.props.updateUser(this.state).then(() => {
                store.dispatch(setMessage({
                    body: 'The staff profile was successfully updated.',
                    messageStyle: 'success',
                    timeout: 5000
                }));
            })
                .catch(err => {
                    console.log(err);
                })
        } else {
            store.dispatch(setMessage({
                body: 'There was an issue with updating this staff profile. Please try again.',
                messageStyle: 'alert',
                timeout: 5000
            }));
        }
    }

    handlePasswordResetPressed() {
        const { resetPassword } = this.props;
        const { email } = this.state;
        resetPassword(email);
    }

    resetPasswordEnabled() {
        const { staff } = this.props;
        const { email } = this.state;
        return staff.email === email;
    }

    validateState() {
        const {
            first_name,
            last_name,
            email,
            role,
            site,
        } = this.state;
        return !!first_name && !!last_name && /^\S+@\S+\.\S+$/.test(email) && !!role && !!site;
    }

    render() {
        const { user } = this.props;
        return (
          <Form data-test="update-user-form" style={{ padding: '20px' }} onSubmit={(e) => this.handleSubmit(e)}>
              <Container>
                  <Row>
                      <Col md="6">
                          <label>First Name:</label>
                          <Input
                              required
                              type="text"
                              placeholder="First Name"
                              name="first_name"
                              value={this.state.first_name}
                              onChange={this.handleChange}
                            />
                        </Col>
                      <Col md="6">
                          <label>Last Name:</label>
                          <Input
                              required
                              type="text"
                              placeholder="Last Name"
                              name="last_name"
                              value={this.state.last_name}
                              onChange={this.handleChange}
                            />
                        </Col>
                    </Row>
                  <Row className="mui--text-left">
                      <Col md="4">
                          <label>Site:</label>
                          <Select
                              name="site"
                              value={this.state.site}
                              onChange={this.handleChange}
                              disabled={!canChangeStaffSite(user.role)}
                            >
                              <option value="austin">Austin</option>
                              <option value="englewood">Englewood</option>
                              <option value="north_lawndale">North Lawndale</option>
                            </Select>
                        </Col>
                      <Col md="4">
                          <label>Role:</label>
                          <Select
                              name="role"
                              value={this.state.role}
                              onChange={this.handleChange}
                              disabled={!canChangeStaffRole(user.role)}
                            >
                              <option value="coach">Coach</option>
                              <option value="crew_chief">Crew Chief</option>
                              <option value="admin">Site Admin</option>
                              {
                                    canSetStaffRoleToSuperAdmin(user.role) ? (
                                      <option value="super_admin">Super Admin</option>
                                    ) : null
                                }
                            </Select>
                        </Col>
                      <Col md="4">
                          <label>Deactivated:</label>
                          <Select
                              name="disabled"
                              value={this.state.disabled}
                              onChange={this.handleChange}
                              disabled={!canChangeStaffDeactivated(user.role)}
                            >
                              <option value={true}>Yes</option>
                              <option value={false}>No</option>
                            </Select>
                        </Col>
                    </Row>
                  <Row>
                      <Col md="12">
                          <label>Email:</label>
                          <Input
                              required
                              type="email"
                              placeholder="Email"
                              name="email"
                              value={this.state.email}
                              onChange={this.handleChange}
                              disabled
                            />
                        </Col>
                    </Row>
                  <Row className="mui--text-center">
                    <Col md="6" xs="12">
                        <Button
                            style={{...primaryButtonStyle, width: '100%'}}
                            data-test="update-user-form-submit-btn"
                            variant="raised"
                            type="Submit"
                            disabled={!this.validateState()}
                            className="mui--bg-color-green-500 mui--color-white"
                            >
                                Update User
                        </Button>
                    </Col>
                    <Col md="6" xs="12">
                        <Button
                            style={{width: '100%'}}
                            data-test="update-user-form-reset-password-btn"
                            variant="raised"
                            type="button"
                            onClick={this.handlePasswordResetPressed}
                            >
                                Reset Password
                        </Button>
                    </Col>
                    </Row>
                </Container>
            </Form>
        );
    }
}

StaffForm.propTypes = {
    staff: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    updateUser: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
}

export default StaffForm;