import React from 'react'
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { connect } from 'react-redux';
import WindowSizeListener from 'react-window-size-listener';
import PrivateRoute from '../../containers/PrivateRoute';
import Header from '../Header';
import LoginPage from '../../containers/LoginPage';
import MainContent from '../../containers/MainContent';
import Sidebar from '../Sidebar';
import { toggleMenu } from '../../actions/appState/AppActions';
import ModalController from '../../containers/ModalController';
import MessageController from '../../containers/MessageController';

const Layout = ({ user, menuOpen, dispatch }) => (
  <Router>
    <div>
      <WindowSizeListener onResize={windowSize => {
        if (menuOpen && windowSize.windowWidth >= 768) {
          dispatch(toggleMenu());
        }
      }}
      />
      <Header />
      {
        user && user.email &&
        <Sidebar />
      }
      <ModalController />
      <MessageController />
      <div className="mui--appbar-height" />
      <Switch>
        <Route
          exact
          path="/"
          render={props => {
            return user && user.email
              ? <Redirect to='/app' {...props} />
              : <Redirect to='/login' {...props} />
          }}
        />
        <Route exact path="/login" component={LoginPage} />
        <PrivateRoute path='/app' component={MainContent} />
      </Switch>
    </div>
  </Router>
)

Layout.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    site: PropTypes.string,
    role: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  menuOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    user: state.user,
    menuOpen: state.app.menuOpen,
  }
}

export default connect(mapStateToProps)(Layout);