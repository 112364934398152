/*
*
*   FeedbackInfoModalContent
*
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types';

import feedbackFormInfoCopy from '../../utiltites/contentCopy';

const panelStyle = {
    padding: '32px'
}

export class FeedbackInfoModalContent extends Component {

  getCopy(type, questionKey){
      if (feedbackFormInfoCopy[type] === undefined || feedbackFormInfoCopy[type][questionKey] === undefined) {
          return null;
      }
      return feedbackFormInfoCopy[type][questionKey];
  }

  render() {
    const { type, questionKey } = this.props;
    let children;
    if (type && questionKey) {
        children = this.getCopy(type, questionKey)
    }
    return (
        <div data-test="feedback-info-modal-content" style={panelStyle}>
            { children ? children() : null }
        </div>
    )
  }
}

FeedbackInfoModalContent.propTypes = {
    type: PropTypes.string,
    questionKey: PropTypes.string,
}

export default FeedbackInfoModalContent
