import moment from "moment";

import {
  SET_FEEDBACK_FORMS,
  SET_SELECTED_DATE,
  CLEAR_FEEDBACK_FORMS,
  SET_END_DATE
} from "../ActionTypes";

export function setFeedbackData(forms) {
  const selectedDate = moment()
    .startOf("week")
    .format("YYYYMMDD");
  const end = moment().format("YYYYMMDD");
  return {
    type: SET_FEEDBACK_FORMS,
    payload: { forms, selectedDate, end }
  };
}

export function clearFeedbackData() {
  return {
    type: CLEAR_FEEDBACK_FORMS
  };
}

export function setSelectedDate(date) {
  return {
    type: SET_SELECTED_DATE,
    payload: date
  };
}

export function setEndDate(date) {
  return {
    type: SET_END_DATE,
    payload: date
  };
}
