/*
 *
 *   permissions.js
 *
 */

// Administrative

export function canChangeSite(role) {
  return role === 'super_admin';
}

// Dashboard

export function canSeeSiteOverview(role) {
  return role === 'admin' || role === 'super_admin';
}

// Staff

export function canSeeStaffPage(role) {
  return role === 'admin' || role === 'super_admin';
}

export function canChangeStaffRole(role) {
  return role === 'admin' || role === 'super_admin';
}

export function canChangeStaffSite(role) {
  return role === 'super_admin';
}

export function canChangeStaffDeactivated(role) {
  return role === 'super_admin';
}

export function canSetStaffRoleToSuperAdmin(role) {
  return role === 'super_admin';
}

// Participants

export function canCreateParticipant(role) {
  return role === 'super_admin';
}

export function canUpdateParticipant(role) {
  return role === 'super_admin' || role === 'admin';
}

export function canDeleteParticipant(role) {
  return role === 'super_admin';
}

export function canChangeParticipantDeactivated(role) {
  return role === 'super_admin';
}

// Groups

export function canCreateGroupCAD(role) {
  return role === 'super_admin' || role === 'admin' || role === 'coach';
}

export function canCreateGroupPD(role) {
  return role === 'super_admin' || role === 'admin' || role === 'coach';
}

export function canCreateGroupWC(role) {
  return role === 'super_admin' || role === 'admin' || role === 'crew_chief';
}

export function canCreateUserGroup(role) {
  return role === 'admin' || role === 'super_admin';
}

export function canDeleteUserGroup(role) {
  return role === 'super_admin' || role === 'admin';
}
