import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Container from 'muicss/lib/react/container';
import Button from 'muicss/lib/react/button';
import Panel from 'muicss/lib/react/panel';
import PageTitle from '../../components/PageTitle';
import { MyAccountForm } from './MyAccountForm';
import { updateCurrentUser } from '../../api/Users.Api'
import { setMessage } from '../../actions/messages/MessageActions';
import { updateUserRedux } from '../../actions/user/UserActions';

export const MyAccount = ({ user, history, setMessage, updateUserRedux }) => {
  return (
    <Container data-test='myaccount'>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      >
        <PageTitle title={'My Account'}/>
        <Button
          data-test="myaccount-back-btn"
          variant='raised'
          onClick={() => history.goBack()}
        >
          {'back'}
        </Button>
      </div>
      <Panel style={{ marginTop: 16 }}>
        <MyAccountForm
          data-test="myaccount-form"
          user={user}
          updateUser={updateCurrentUser}
          setMessage={setMessage}
          updateUserRedux={updateUserRedux}
        />
      </Panel>
    </Container>
  )
}

MyAccount.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setMessage: PropTypes.func.isRequired,
  updateUserRedux: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  setMessage: (message) => dispatch(setMessage(message)),
  updateUserRedux: (user) => dispatch(updateUserRedux(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount)
