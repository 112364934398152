import { SET_MESSAGE, DISMISS_MESSAGE } from '../ActionTypes';

export function setMessage(messagePayload) {
  return { 
      type: SET_MESSAGE,
      payload: messagePayload,
 }
}

export function dismissMessage() {
  return { type: DISMISS_MESSAGE }
}