import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'muicss/lib/react/panel';
import Container from 'muicss/lib/react/container';

import './styles.css';
import CloseX from '../Buttons/CloseX';

const Modal = ({ show, children, handleClose }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <Panel className={showHideClassName}>
      <Container>
        <section className="modal-main">
          <CloseX
            handleClose={handleClose}
          />
          {show && children}
        </section>
      </Container>
    </Panel>
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default Modal;

