import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from 'muicss/lib/react/container';
import Row from 'muicss/lib/react/row';
import Button from 'muicss/lib/react/button';
import GroupsList from '../../components/GroupsList'
import PageTitle from '../../components/PageTitle';
import { toggleCreateGroupModal } from '../../actions/appState/AppActions';
import { sortAllGroups } from '../../selectors/GroupsSelectors';
import TodaysGroup from '../../components/TodaysGroup';
import { buttonListRowStyle } from '../../utiltites/commonStyles';

class StaffGroupPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      todaySelected: true
    }
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {
    if (this.props.location.state && this.props.location.state.previousGroups) {
      this.setState({
        todaySelected: false
      })
    }
  }

  handleClick(bool) {
    this.setState({
      todaySelected: bool
    })
  }

  render() {
    const { todaySelected } = this.state;
    const { allGroups } = this.props;
    return (
      <div>
        <Container >
          <Row style={{ margin: '0 4px' }}>
            <PageTitle title={'My Groups'}/>
          </Row>
          <Row style={buttonListRowStyle} className="mui--text-center">
            <Button
              onClick={() => this.handleClick(true)}
              color={todaySelected ? 'primary' : null}
              variant='raised'
            >
              {`Today's Group`}
            </Button>
            <Button
              onClick={() => this.handleClick(false)}
              color={!todaySelected ? 'primary' : null}
              variant='raised'
            >
              {'All Groups'}
            </Button>
          </Row>
          {
            todaySelected
              ? (
                <TodaysGroup />
              ) : (
                <GroupsList
                  groups={allGroups}
                  toggleCreateGroupModal={this.props.toggleCreateGroupModal}
                />
              )
          }

        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  allGroups: sortAllGroups(state),
});

const mapDispatchToProps = dispatch => ({
  toggleCreateGroupModal: (bool) => dispatch(toggleCreateGroupModal(bool))
});


export default connect(mapStateToProps, mapDispatchToProps)(StaffGroupPage)