import moment from 'moment';
import ref from '../db/database';
import store from '../store';

import {
  addGroup,
  updateGroup,
  removeGroup,
  setGroups,
} from '../actions/groups/GroupsActions';
import {
  setStaffGroups,
  clearStaffGroups,
} from '../actions/siteData/SiteDataActions';

export function listenForGroups({ site, uid, dispatch }, service = ref) {
  let initialDataLoaded = false;
  return Promise.all([
    service(`groups/${site}/${uid}`).on('child_added', snapshot => {
      if (initialDataLoaded) {
        const group = snapshot.val();
        dispatch(addGroup(group));
      }
    }),
    service(`groups/${site}/${uid}`).on('child_changed', snapshot => {
      if (initialDataLoaded) {
        const group = snapshot.val();
        dispatch(updateGroup(group));
      }
    }),
    service(`groups/${site}/${uid}`).on('child_removed', snapshot => {
      if (initialDataLoaded) {
        const group = snapshot.val();
        dispatch(removeGroup(group));
      }
    }),
    service(`groups/${site}/${uid}`)
      .once('value')
      .then(snapshot => {
        const groups = snapshot.exists() ? Object.values(snapshot.val()) : [];
        dispatch(setGroups(groups));
        initialDataLoaded = true;
      }),
  ]).catch(error => console.error(error));
}

export function stopListeningToGroups({ site, uid }, service = ref) {
  service(`groups/${site}/${uid}`).off();
}

export function createGroup({ group, user, date }, service = ref) {
  const today = date ? date : moment().format('YYYYMMDD');
  const groupRef = group.group_id
    ? service(`groups/${user.site}/${user.uid}/${group.group_id}`)
    : service(`groups/${user.site}/${user.uid}`).push();
  const groupKey = groupRef.key ? groupRef.key : group.group_id;
  const feedbackReminderRef = service(
    `feedback_reminders/${today}/${groupKey}`
  );
  const completed = Object.values(group.participants).every(
    p => p.feedback_id !== ''
  );
  return Promise.all([
    groupRef.set({
      created_at: today,
      date: today,
      updated_at: today,
      participants: group.participants,
      group_id: groupKey,
      feedback_reminder_id: groupKey,
      type: group.type,
    }),
    feedbackReminderRef.set({
      email: user.email,
      uid: user.uid,
      group_id: groupKey,
      group_type: group.type,
      date: today,
      key: groupKey,
      completed,
    }),
  ]).catch(error => console.log(error));
}

export function deleteGroup({ groupId, staffId, site, date }, service = ref) {
  return Promise.all([
    service(`groups/${site}/${staffId}/${groupId}`).remove(),
    service(`feedback_reminders/${date}/${groupId}`).remove(),
  ]).catch(error => console.log(error));
}

export function listenForStaffGroups(
  { site, users, day = moment().format('YYYYMMDD'), end, dispatch },
  service = ref
) {
  dispatch(clearStaffGroups());
  return Promise.all(
    users.map(user => {
      const feedbackRef = end
        ? service(`groups/${site}/${user.uid}`)
            .orderByChild('date')
            .startAt(day)
            .endAt(end)
        : service(`groups/${site}/${user.uid}`)
            .orderByChild('date')
            .equalTo(day);
      return feedbackRef.on('value', snapshot => {
        const groups = snapshot.exists() ? Object.values(snapshot.val()) : [];
        dispatch(setStaffGroups({ uid: user.uid, groups }));
      });
    })
  ).catch(err => console.log(err));
}

export function stopListeningForStaffGroups(
  { site, users, uid },
  service = ref
) {
  users.forEach(user => {
    service(`groups/${site}/${user.uid}`).off();
  });
  return listenForGroups({ site, uid, dispatch: store.dispatch });
}
