/**
 *
 * FeedbackButton
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Button from 'muicss/lib/react/button';

const FeedbackButton = ({
  lightBg,
  bgColor,
  title,
  selected,
  handleClick,
  stateProp,
  index
}) => {

  const handleButtonClick = (event) => {
    event.preventDefault();
    handleClick(stateProp, index)
  }



  const roundButtonStyle = {
    height: '100px',
    width: '100px',
    backgroundColor: selected ? bgColor : 'white',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: bgColor,
    textAlign: 'center',
    whiteSpace: 'normal',
    lineHeight: '1.2rem',
  }

  const textStyle = {
    color: lightBg ? 'black' : (selected ? 'white' : 'black'),
  }

  return (
    <Button
      data-test='feedback-button'
      variant="fab"
      style={roundButtonStyle}
      onClick={handleButtonClick}
      className="mui--text-center mui--align-middle"
    >
      <div style={textStyle} >{title}</div>
    </Button>
  )
}

FeedbackButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  stateProp: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  bgColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  lightBg: PropTypes.bool.isRequired
}

export default FeedbackButton;